import classNames from 'classnames'
import storeConnect from 'Checkout/Redux/Connect'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'

const CampgroundData = ({ campgroundAddress, campgroundEmail, campgroundLogo, campgroundName, campgroundPhone,
                          className, logoHeight, logoWigth }) => {
  const containerClasses = classNames('d-flex m-0 w-100', className)

  return (
    <div className={containerClasses}>
      {campgroundLogo && (
        <div className="d-none d-md-block mt-1">
          <img src={campgroundLogo} height={logoHeight} width={logoWigth} alt="Campground Logo" />
        </div>
      )}

      <div className="align-self-center mx-md-3 overflow-hidden">
        <h5 className="text-truncate" title={campgroundName}>{campgroundName}</h5>
        <p className="text-gray mb-2">{campgroundAddress}</p>
        <div className="d-block d-lg-flex mt-1">
          {campgroundPhone && (
            <p className="campground-phone mb-0 me-md-3 me-lg-4 mb-2">
              <FontAwesomeIcon icon="phone" className="me-1" />
              {campgroundPhone}
            </p>
          )}
          {campgroundEmail && (
            <p className="mb-0">
              <FontAwesomeIcon icon="envelope" className="me-1" />
              {campgroundEmail}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

CampgroundData.defaultProps = {
  logoHeight: '50',
  logoWigth: '50',
}

const mapStateToProps = state => ({
  campgroundAddress: state.campground.fullAddress,
  campgroundEmail: state.campground.email,
  campgroundLogo: state.campground.logoUrl,
  campgroundName: state.campground.name,
  campgroundPhone: state.campground.phone,
})

export default storeConnect({ mapStateToProps })(CampgroundData)
