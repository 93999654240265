import { useState } from 'react'
import { Col, Row } from 'reactstrap'
import cnames from 'classnames'
import { formatDate } from 'Utils/Dates'
import useRouter from 'Checkout/Hooks/useRouter'
import useSiteLock from 'Checkout/Hooks/useSiteLock'
import useSiteTypeIcon from 'Checkout/Hooks/useSiteTypeIcon'
import storeConnect from 'Checkout/Redux/Connect'
import { searchFilterUpdate, searchUpdateWithSessionStorage } from 'Checkout/Redux/Actions/Search'
import { setProductClicked } from 'Checkout/Redux/Actions/TrackSegment'
import { validateRigSize } from 'Checkout/Utils/rvHelpers'
import Amenities from 'Checkout/Components/Shared/Amenities/Amenities'
import Anchor from 'Shared/Anchor/Anchor'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import Image from 'Shared/Image/Image'
import RateSelectButton from 'Checkout/Components/Search/RateSelectButton/RateSelectButton'
import RateDescription from 'Checkout/Components/Search/RateDescription/RateDescription'
import defaultPicture from 'Checkout/Images/photo-coming-soon-list.png'
import 'Shared/Skeleton/Skeleton.scss'
import './Rate.scss'

const Rate = ({ accommodation, canChooseSite, canUseSiteLock, checkIn, checkOut, dayUse, rigSizeModal,
                rate, rigLength, rigWidth, setProductClicked, setRate, setSiteLockModal, setRigSizeModal,
                sitesMapUrl, siteType, siteTypeName }) => {
  const router = useRouter()
  const { selectSiteLock } = useSiteLock({ goSites: true })
  const [isSeeMoreActive, setIsSeeMoreActive] = useState(false)
  const { icon } = siteType ? useSiteTypeIcon(siteType) : {}
  const { amenities, canAddVehicle, description, maxLength, maxWidth, minNightsRequired, name, nightPrice, id,
          pictureUrl, rigRequired, totalNights, totalPrice } = rate || {}

  const classNameWrapper = cnames('rate d-block text-decoration-none mb-3')
  const seeMoreButtonIcon = isSeeMoreActive ? 'sort-up' : 'sort-down'
  const seeMoreButtonIconClasses = cnames('ms-2', { 'mb-1-negative': isSeeMoreActive, 'mb-1': !isSeeMoreActive })

  const checkSiteLock = () => {
    if (canUseSiteLock && sitesMapUrl) return selectSiteLock(true, id)

    if (canUseSiteLock) return setSiteLockModal(true)
    goNext()
  }

  const goNext = () => {
    const optionalSearch = dayUse ? {} : { check_out: formatDate(checkOut) }
    const search = { accommodation, check_in: formatDate(checkIn), site_type: siteType, rate_id: rate.id || '',
                     rig_length: rigLength || '', rig_width: rigWidth || '', ...optionalSearch }
    router.next({ search, canChooseSite, canUseSiteLock })
  }

  const handleSetRate = () => {
    setRate({ id, name, maxLength, maxWidth, nightPrice, pictureUrl, rigRequired,
              canAddVehicle, minNightsRequired, totalNights, totalPrice }, 'selected-rate')
  }

  const onClickSeeMore = () => setIsSeeMoreActive(!isSeeMoreActive)

  const selectRate = () => {
    handleSetRate()
    setProductClicked()
    if (dayUse) return goNext()

    validateRateSelected()
  }

  const validateRateSelected = () => {
    const isRigSizeValid = validateRigSize({ rigLength, rigWidth, maxLength, maxWidth })
    if (rate.rigRequired && !isRigSizeValid) return setRigSizeModal(!rigSizeModal)
    checkSiteLock()
  }

  return (
    <div className={classNameWrapper}>
      <Row className="g-0 rounded p-3">
        <Col className="rate__image-container" sm="4">
          <Image alt={`Rate: ${name}.`} defaultImage={defaultPicture}
                 className="h-100 mb-2 p-0 rate-img rounded w-100" srcToLoad={pictureUrl} />
        </Col>

        <Col sm="8" className="px-sm-2 px-md-3">
          <div className="rate-details d-flex flex-column h-100">
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              <div>
                <div className="pb-2 text-muted text-smaller-1 d-flex align-items-center">
                  <img alt="" className="me-2" height="25" src={icon} />
                  <span>{siteTypeName}</span>
                </div>

                <h5 className="rate-name fw-bold mb-1 text-bigger-1">{name}</h5>

                {maxLength > 0 && maxWidth > 0 && (
                  <p className="mb-2 text-muted col-12">
                    <span>Rigs up to</span>
                    <span className="fw-bold ms-1">{maxLength} ft. length or {maxWidth} ft. width</span>
                  </p>
                )}
              </div>

              <RateSelectButton nightPrice={nightPrice} onClick={selectRate} />
            </div>

            <div className="rate--info-container py-3 d-flex flex-lg-row flex-column justify-space-between">
              {!!amenities?.length && <Amenities amenities={amenities} />}
            </div>

            {description && (
              <div className="d-flex align-items-center mt-auto justify-content-center">
                <Anchor className="rate__see-more" onClick={onClickSeeMore}>
                  See More
                  <FontAwesomeIcon className={seeMoreButtonIconClasses} icon={seeMoreButtonIcon} size="lg" />
                </Anchor>
              </div>
            )}
          </div>
        </Col>

        <RateDescription description={description} isSeeMoreActive={isSeeMoreActive}
                         minNightsRequired={minNightsRequired} />
      </Row>
    </div>
  )
}

const mapStateToProps = state => ({
  accommodation: state.search.accommodation,
  canChooseSite: state.campground.canChooseSite,
  canUseSiteLock: state.campground.canUseSiteLock,
  checkIn: state.search.checkIn,
  checkOut: state.search.checkOut,
  dayUse: state.search.dayUse,
  rigSizeModal: state.search.rigSizeModal,
  rigLength: state.search.rigLength,
  rigWidth: state.search.rigWidth,
  sitesMapUrl: state.campground.sitesMapUrl,
  // TODO: use the site type associated with the rate.
  siteType: state.search.siteType?.type,
  siteTypeName: state.search.siteType?.name,
})

const mapDispatchToProps = dispatch => ({
  setProductClicked: () => dispatch(setProductClicked),
  setRate: (rate, sessionKey) => dispatch(searchUpdateWithSessionStorage({ rate }, sessionKey)),
  setRigSizeModal: rigSizeModal => dispatch(searchFilterUpdate({ rigSizeModal })),
  setSiteLockModal: siteLockModal => dispatch(searchFilterUpdate({ siteLockModal })),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(Rate)
