import storeConnect from 'Checkout/Redux/Connect'
import useWindowDimensions from 'Shared/Hooks/useWindowDimensions'
import EditReservationButton from 'Checkout/Components/Shared/EditReservationButton/EditReservationButton'
import ReviewYourOrder from 'Checkout/Components/Shared/ReviewYourOrder'
import ViewButtons from 'Checkout/Components/Shared/ViewButtons/ViewButtons'
import './ReservationDetails.scss'

const ReservationDetails = ({ editBtnSmall, pictureUrl, showCompleteDetails, showSite, imageAlt }) => {
  const { isDesktopView } = useWindowDimensions()

  return (
    <div className="reservation-details position-relative h-100">
      <img className="image-gallery d-none d-lg-block w-100" src={pictureUrl} alt={imageAlt} />
      <div className="sticky-top">
        <div>
          {isDesktopView && <ViewButtons />}

          <div className="details px-2 px-md-4 mb-5">
            <div className="pb-3 mb-5">
              <ReviewYourOrder {...{ isDesktopView, showCompleteDetails, showSite }} />
            </div>
          </div>
        </div>

        {showCompleteDetails && (
          <EditReservationButton className="px-lg-4 align-items-center" editBtnClass="d-lg-none"
                                 editBtnSmall={editBtnSmall} />
        )}
      </div>
    </div>
  )
}

ReservationDetails.defaultProps = {
  editBtnSmall: false,
  showCompleteDetails: false,
  showSite: true,
  imageAlt: '',
}

const mapStateToProps = state => ({
  pictureUrl: state.campground.pictureUrl,
  imageAlt: state.campground.pictureAlt,
})


export default storeConnect({ mapStateToProps })(ReservationDetails)
