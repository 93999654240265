import { gql } from 'Utils/apolloClient'

const marketingPreferenceMutation = gql`
  mutation MarketingPreferenceSave($email: String!, $operation: String!, $slug: ID!) {
    marketingPreferenceSave(input: { campgroundId: $slug, email: $email, operation: $operation }) {
      marketingPreference {
        id
      }
    }
  }`

export default marketingPreferenceMutation
