import { gql } from 'Utils/apolloClient'

const sitesQuery = gql`
  query SitesData($slug: String!, $from: ISO8601Date!, $to: ISO8601Date!, $rateId: ID!,
                  $filters: ValidationsFiltersAttributes) {
    campground(slug: $slug, from: $from, to: $to, filters: $filters) {
      id
      sitesAvailableDuring(rateId: $rateId) {
        description
        id
        name
        amenities
        maxLength
        maxWidth
        maxPeople
        pictureSizes {
          listUrl
          mapUrl
        }
        picturesUrl
      }
    }
  }`

export default sitesQuery
