import { useState, useEffect } from 'react'
import cnames from 'classnames'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import { Input, InputGroup } from 'reactstrap'
import { slugFor } from 'Utils/Strings'
import './ScaleInput.scss'

const ScaleInput = ({ classes, enabled, inputId, inputName, isEmpty, invalid, needsLowerCase, max, min,
                      setCountsFor, value, width }) => {
  const [counter, setCounter] = useState(0)
  const inputNameValue = needsLowerCase ? slugFor(inputName) : inputName
  const isIncreaseDisabled = counter === max
  const isDecreaseDisabled = counter === min

  useEffect(() => {
    setCounter(value)
  }, [value])

  const classesDefault = {
    button: cnames('btn btn-blue-medium h-100 rounded-0', classes.button),
    input: cnames('floating-label-height text-center', classes.input),
  }

  const counterOrEmpty = () => ((isEmpty && counter === 0) ? '' : counter)

  const increase = () => {
    if (counter < max && enabled) {
      const count = parseInt(counter) + 1
      if (setCountsFor) setCountsFor(inputId, count)
      setCounter(count)
    }
  }

  const decrease = () => {
    if (counter > min) {
      const count = parseInt(counter) - 1
      if (setCountsFor) setCountsFor(inputId, count)
      setCounter(count)
    }
  }

  const inputChangeHandler = e => {
    const value = parseInt(e.target.value)
    const re = /^\d+$/
    if (value === '' || (re.test(value) && value <= max)) {
      setCountsFor(inputId, value)
      setCounter(value)
    }
  }

  return (
    <InputGroup className="control-scale scale-input" style={{ width }}>
      <div className="d-flex">
        <button className={`${classesDefault.button} minus`} disabled={isDecreaseDisabled}
                name={`${inputNameValue}-decrease`} onClick={decrease} type="button">
          <FontAwesomeIcon icon="minus" />
        </button>
      </div>

      <Input className={classesDefault.input} id={inputId} invalid={invalid} name={inputNameValue}
             onChange={inputChangeHandler} type="text" value={counterOrEmpty()} />

      <div className="d-flex">
        <button className={`${classesDefault.button} plus`} disabled={isIncreaseDisabled}
                name={`${inputNameValue}-increase`} onClick={increase} type="button">
          <FontAwesomeIcon icon="plus" />
        </button>
      </div>
    </InputGroup>
  )
}

ScaleInput.defaultProps = {
  classes: {
    button: '',
    input: '',
  },
  inputNameValue: false,
  max: 20,
  min: 0,
  needsLowerCase: false,
  value: 0,
}

export default ScaleInput
