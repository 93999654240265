import { CarouselItem } from 'reactstrap'
import Card from 'Checkout/Components/Payment/Card/Card'
import CarouselContainer from 'Checkout/Components/Shared/CarouselContainer/CarouselContainer'

const CardSlider = ({ cards }) => {
  const slides = cards.map(card => (
    <CarouselItem key={card.id}>
      <Card card={card} />
    </CarouselItem>
  ))

  return (
    <CarouselContainer cardData={slides} />
  )
}

export default CardSlider
