import storeConnect from 'Checkout/Redux/Connect'
import { routesHandler as routes } from 'Checkout/Routes/routesHandler'
import useRouter from 'Checkout/Hooks/useRouter'
import ProgressNav from 'Shared/ProgressNav/ProgressNav'

const WrapperProgressNav = ({ isRequest, progressNavClass }) => {
  const router = useRouter()
  const currentRoute = router.history.to.key

  const goToRoute = route => router.go(routes[route].path)

  const steps = [
    { index: 1, name: routes.search.name, routes: [routes.search.key, routes.sites.key, routes.home.key] },
    { index: 2, name: routes.checkout.name, routes: [routes.checkout.key] },
    { index: 3, name: routes.payment.name, routes: [routes.payment.key] },
    { index: 4, name: isRequest ? 'Sent' : routes.confirmation.name, routes: [routes.confirmation.key],
      icon: 'check' },
  ]

  return (
    <ProgressNav currentRoute={currentRoute} goToRoute={goToRoute} hideStepNumber
                 progressNavClass={progressNavClass} steps={steps} />
  )
}

const mapStateToProps = state => ({
  isRequest: state.campground.isRequest,
})

export default storeConnect({ mapStateToProps })(WrapperProgressNav)
