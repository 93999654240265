import { Alert } from 'reactstrap'
import cnames from 'classnames'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'

const NoAvailabilityAlert = ({ alertMessage, className }) => {
  const alertClasses = cnames('d-flex align-items-center', className)

  return (
    <Alert color="danger" className={alertClasses}>
      <FontAwesomeIcon icon="circle-exclamation" />
      <p className="text-start mb-0 ps-3">
        {alertMessage}
      </p>
    </Alert>
  )
}

NoAvailabilityAlert.defaultProps = {
  alertMessage: 'No availability. Please try a new search to availability.',
  className: 'mt-4',
}

export default NoAvailabilityAlert
