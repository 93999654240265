import { gql } from 'Utils/apolloClient'
import currentUserDataFragment from 'Checkout/Queries/CurrentUserDataFragment'

const checkoutQuery = gql`
  query CheckoutData($slug: String!, $userToken: String, $filters: SiteFiltersAttributes) {
    options {
      externalLinks {
        forgotPassword
        helpCenter
        tripInsuranceDisclosures
        roverpassHome
        rvEzyUrl
        termsOfService
        travelProtection
        userAccount
      }
      fbAppId
      siteTypes
      tripInsuranceSupportPhone
    }

    campground(slug: $slug) {
      advancedMonths
      advertisement
      campgroundCancellationPolicy
      campgroundCancellationPolicyNote
      cancellationPolicyConfig {
        fullDays
        halfDays
        id
        name
      }
      canChooseSite
      canUseAccommodationDaily
      canUseAccommodationNightly
      canUseSiteLock
      checkInProcedure
      cityName
      closeSeasonDates
      country {
        code
      }
      email
      fullAddress
      isInventory
      isAutoAssign
      isBookable
      isRequest
      logoUrl
      name
      nonRefundableDiscount
      phone
      pictureAlt
      pictureUrl
      pictureUrls
      roverpassCancellationPolicy
      rulesList
      siteLockFee {
        display
      }
      sitesMapUrl
      mapUrl
      slug
      stateName
      systemType
      utilityFields {
        amenitiesCode
        gateCode
        wifiNetwork
        wifiPassword
      }
      whatToKnow
      websiteColorPrimary
      websiteColorPrimaryAccent
      websiteColorSecondary
    }

    currentUser(token: $userToken) {
      ...currentUserData
    }
  }

  ${currentUserDataFragment}
  `

export default checkoutQuery
