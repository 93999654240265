import { useSelector } from 'react-redux'

const ReservationHomeButton = () => {
 const roverpassHome = useSelector(state => state.options?.externalLinks?.roverpassHome)

 return (
  <div className="d-print-none">
    <a className="btn btn-outline-dark-blue w-100" href={roverpassHome}>Take Me Home</a>
  </div>
 )
}

export default ReservationHomeButton
