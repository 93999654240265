import { gql } from 'Utils/apolloClient'

const bannedGuestMutation = gql`
  mutation bannedGuest($email: String!, $from: ISO8601Date!, $slug: String!, $to: ISO8601Date!,
                       $toNotify: Boolean!) {
  bannedGuestDetection(input: { email: $email, from: $from, slug: $slug, to: $to, toNotify: $toNotify }) {
    email
    isBanned
    nearbyCampgrounds {
      averageReviewRating
      cityName
      id
      links {
        marketplaceUrl
      }
      milesDistanceToCampground(slug: $slug)
      name
      stateName
      picturesCount
      pictureUrl
      priceDailyLow {
        display
      }
      reviewsCount
    }
  }
}`

export default bannedGuestMutation
