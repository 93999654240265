import cnames from 'classnames'
import useRouter from 'Checkout/Hooks/useRouter'
import useSiteTypeIcon from 'Checkout/Hooks/useSiteTypeIcon'
import storeConnect from 'Checkout/Redux/Connect'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { setPageView, setProductsSearched } from 'Checkout/Redux/Actions/TrackSegment'
import { formatDate } from 'Utils/Dates'
import 'Shared/Skeleton/Skeleton.scss'
import './SiteType.scss'

const SiteType = ({ accommodation, checkIn, checkOut, loading, loadingRates, setPageView, setProductsSearched,
                    setSiteType, siteType, siteTypeName, siteTypeSelected, totalSites, useInSearch }) => {
  const router = useRouter()
  const { icon } = useSiteTypeIcon(siteType)
  const isSiteTypeSelected = siteType === siteTypeSelected?.type
  const containerClasses = cnames('site-type p-1 mb-3 d-flex align-items-center justify-content-center',
                                  'text-decoration-none',
                                  { active: isSiteTypeSelected, 'skeleton-loading': loading })

  const selectSiteType = e => {
    e.preventDefault()

    if (!loading && !loadingRates && !isSiteTypeSelected) {
      setSiteType({ type: siteType, name: siteTypeName })

      if (!useInSearch) {
        setPageView()
        setProductsSearched()
        goNext()
      }
    }
  }

  const goNext = () => {
    const search = { accommodation, check_in: formatDate(checkIn), check_out: formatDate(checkOut),
                     site_type: siteType }
    router.next({ search })
  }

  return (
    <button type="button" className={containerClasses} onClick={selectSiteType}>
      <div className="d-flex me-2 mx-md-2 mx-lg-3">
        <img src={icon} height="35" alt="" />
      </div>

      <div className="flex-grow-1 text-start">
        <div className="fw-bold"><span>{siteTypeName}</span></div>
        {totalSites && <small>{totalSites} Available</small>}
      </div>
    </button>
  )
}

SiteType.defaultProps = {
  totalSites: null,
}

const mapDispatchToProps = dispatch => ({
  setSiteType: siteType => dispatch(searchFilterUpdate({ siteType })),
  setPageView: () => dispatch(setPageView('Checkout: Type Selection')),
  setProductsSearched: () => dispatch(setProductsSearched),
})

const mapStateToProps = state => ({
  accommodation: state.search.accommodation,
  checkIn: state.search.checkIn,
  checkOut: state.search.checkOut,
  siteTypeSelected: state.search.siteType,
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(SiteType)
