import PropTypes from 'prop-types'
import { Row } from 'reactstrap'
import Product from '../Product/Product'

const ProductsList = ({ data, setFieldValue }) => (
  <Row className="products-list">
    {data.map(product => (
      <Product key={product.id} product={product} setFieldValue={setFieldValue} />
    ))}
  </Row>
)

ProductsList.defaultProps = {
  data: [],
}

ProductsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
}

export default ProductsList
