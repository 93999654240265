import { useSelector } from 'react-redux'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'

const RateSelectButton = ({ nightPrice, onClick }) => {
  const dayUse = useSelector(state => state.search.dayUse)

  return (
    <div className="rate-select-button ms-lg-auto">
      <button className="btn btn-selected-green d-flex align-items-center mb-3 mb-md-2 w-100"
              onClick={onClick} type="button">
        <div className="d-flex align-items-end flex-column-reverse ms-auto text-end h-100">
          <h5 className="mb-0 fw-bold">Select</h5>

          <div className="text-nowrap">
            <strong className="me-1">{nightPrice.displayWithCode}</strong>

            {!dayUse && <span>/ night</span>}

            <FontAwesomeIcon icon="chevron-right" className="btn-reservation--icon ms-2" />
          </div>
        </div>
      </button>
    </div>
  )
}

export default RateSelectButton
