import { useEffect } from 'react'
import PropTypes from 'prop-types'
import storeConnect from 'Checkout/Redux/Connect'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'
import { totalGuests } from 'Utils/guestHelpers'
import { detailsSetData } from 'Checkout/Redux/Actions/Details'
import FloatingLabelInput from 'Shared/FloatingLabelInput/FloatingLabelInput'
import GuestFields from 'Checkout/Components/Details/GuestFields/GuestFields'
import MaxPeopleMessage from 'Shared/MaxPeopleMessage'
import PageSubtitle from 'Shared/PageSubtitle'
import './GuestInformation.scss'

const GuestInformation = ({ formikValues, guestValidations, setFieldValue, setGuests }) => {
  const { guests } = formikValues
  const { adults, children } = guests
  const currentTotalGuests = totalGuests({ adults, children })
  const { guests: { additionalNotes, maxPeople, pets, useGuests } } = guestValidations
  const petBreedClasses = classNames({ 'd-none': !parseInt(guests.pets) })

  useEffect(() => {
    if (useGuests) setFieldValue('useGuests', useGuests)
  }, [useGuests])

  useEffect(() => {
    setGuests(formikValues)
  }, [guests])

  return (
    <div className="guest-information mb-4">
      <PageSubtitle text="Guest Information" />
      <Row className="g-0">
        <Col className="ps-md-0 pe-lg-3" lg="12">
          <GuestFields currentTotalGuests={currentTotalGuests} guestValidations={guestValidations} />
        </Col>
      </Row>

      {pets && (
        <Row className="g-0">
          <Col className={petBreedClasses} lg="6">
            <FloatingLabelInput className="mt-3 mb-0" label="What type and breed is your pet?" name="guests.petBreeds" />
          </Col>
        </Row>
      )}

      {!!additionalNotes?.length && (
        <ul className="note text-muted ms-1 mt-3">
          {additionalNotes.map(note => (
            <li key={note}>{note}</li>
          ))}
        </ul>
      )}

      <MaxPeopleMessage maxPeople={maxPeople} total={currentTotalGuests} />
    </div>
  )
}

GuestFields.propTypes = {
  formikValues: PropTypes.shape({
    guests: PropTypes.shape({
      adults: PropTypes.number,
      children: PropTypes.number,
      pets: PropTypes.number,
    }),
  }),
  guestValidations: PropTypes.shape({
    maxPeople: PropTypes.number,
    useGuests: PropTypes.bool,
  }).isRequired,
  setGuests: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  setGuests: data => dispatch(detailsSetData(data)),
})

export default storeConnect({ mapDispatchToProps })(GuestInformation)
