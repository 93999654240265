import { useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { setPageView } from 'Checkout/Redux/Actions/TrackSegment'
import storeConnect from 'Checkout/Redux/Connect'
import useMetaTags from 'Checkout/Hooks/useMetaTags'
import useReloadWithParams from 'Checkout/Hooks/useReloadWithParams'
import useUnload from 'Checkout/Hooks/useUnload'
import Header from 'Checkout/Components/Shared/Header/Header'
import MainColumn from 'Checkout/Components/Shared/MainColumn/MainColumn'
import PageTitle from 'Checkout/Components/Shared/PageTitle/PageTitle'
import ProgressNav from 'Checkout/Components/Shared/ProgressNav/WrapperProgressNav'
import PaymentForm from 'Checkout/Components/Payment/PaymentForm/PaymentForm'
import ReservationDetails from 'Checkout/Components/Shared/ReservationDetails/ReservationDetails'
import Section from 'Checkout/Components/Shared/Section/Section'
import SideColumn from 'Checkout/Components/Shared/SideColumn/SideColumn'

const Payment = ({ campgroundName, setPageView }) => {
  const metatags = { title: `Finalize Your Reservation at ${campgroundName} | RoverPass` }

  useReloadWithParams({ checkDetails: true })
  useMetaTags(metatags)
  useUnload()

  useEffect(() => {
    setPageView()
  }, [])

  return (
    <Section name="payment">
      <MainColumn lg="7" xl="8">
        <Header />
        <PageTitle className="d-none d-md-block text-center" text="Enter Payment Info" />
        <ProgressNav />
        <Elements stripe={window.roverData.stripePromise}>
          <PaymentForm />
        </Elements>
      </MainColumn>

      <SideColumn showOnMobile lg="5" xl="4">
        <ReservationDetails editBtnSmall showCompleteDetails />
      </SideColumn>
    </Section>
  )
}

const mapStateToProps = state => ({
  campgroundName: state.campground.name,
  checkIn: state.search.checkIn,
})

const mapDispatchToProps = dispatch => ({
  setPageView: () => dispatch(setPageView('Checkout: Payment')),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(Payment)
