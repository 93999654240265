import store from 'Checkout/Redux/Store'

const paymentSetData = data => ({
  type: 'PAYMENT_SET_DATA', payload: data,
})

const updatePaymentSiteLock = data => ({
  type: 'UPDATE_PAYMENT_SITE_LOCK',
  payload: data,
  slug: store.getState().campground.slug,
})

export { paymentSetData, updatePaymentSiteLock }
