import { gql } from 'Utils/apolloClient'

const productsQuery = gql`
  query ProductsQuery($filters: ProductFiltersAttributes, $slug: String!, $rateId: ID) {
    campground(slug: $slug) {
      id
      rate(id: $rateId) {
        id
        addons(filters: $filters) {
          countOnHand
          description
          display
          id
          name
          onlineMax
          onlineRequired
          position
          price {
            amount
            display
          }
          priceDescription
          pricePerGuest
          pricePerNight
          productPicture {
            thumbUrl
          }
        }
      }
    }
  }`

export default productsQuery
