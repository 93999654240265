function validateForm({ card, newCard }) {
  let valid = false
  let errors = {}

  const { valid: validPayment, errors: paymentErrors } = _validPaymentMethod(card, newCard)

  valid = validPayment
  errors = { ...paymentErrors }

  return { valid, errors }
}

function _validPaymentMethod(card, newCard) {
  const valid = !!Object.keys(card).length || !newCard.empty
  const errors = {}

  if (!valid) errors.paymentMethod = 'Missing Payment Method'

  return { valid, errors }
}

export { validateForm }
