import PropTypes from 'prop-types'
import cnames from 'classnames'
import PageSubtitle from 'Shared/PageSubtitle'
import ProductsList from 'Checkout/Components/Shared/Products/ProductsList/ProductsList'
import './ProductsInDetails.scss'

const ProductsInDetails = ({ loading, products, setFieldValue }) => {
  const classNameContainer = cnames('products-in-details', { 'skeleton-loading': loading })

  if (!products.length) return

  return (
    <div className={classNameContainer}>
      <PageSubtitle text="Do you need anything else for your stay?" />

      <ProductsList data={products} setFieldValue={setFieldValue} />

      <hr className="mt-4" />
    </div>
  )
}

ProductsInDetails.defaultProps = {
  products: [],
}

ProductsInDetails.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
}

export default ProductsInDetails
