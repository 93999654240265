import storeConnect from 'Checkout/Redux/Connect'
import { scrollToElement } from 'Utils/Elements'
import Anchor from 'Shared/Anchor/Anchor'
import FormCheckbox from 'Shared/Formik/FormCheckbox'
import './AcceptTermsCheckbox.scss'

const AcceptTermsCheckbox = ({ termsOfService }) => (
  <div className="accept-terms-checkbox d-flex">
    <FormCheckbox className="mb-2 ms-1" name="acknowledgement">
      <p className="m-0 p-0">
        Yes, I accept the
        <Anchor className="text-salmon ms-1" onClick={() => scrollToElement('policy')}>
          cancellation policy
        </Anchor>
        ,
        <Anchor className="text-salmon mx-1" onClick={() => scrollToElement('rules')}>
          rules
        </Anchor>
        and
        <a href={termsOfService} target="_blank" rel="noreferrer" className="text-salmon ms-1">
          terms of service
        </a>
      </p>
    </FormCheckbox>
  </div>
)

const mapStateToProps = state => ({
  termsOfService: state.options.externalLinks.termsOfService,
})

export default storeConnect({ mapStateToProps })(AcceptTermsCheckbox)
