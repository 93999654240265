import { useEffect, useLayoutEffect, useState } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { parseObject } from 'Utils/Parse'
import routes from 'Checkout/Routes/routes'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import checkoutQuery from 'Checkout/Queries/CheckoutQuery'
import storeConnect from 'Checkout/Redux/Connect'
import RoutingContext from 'Checkout/Components/Routing/RoutingContext'
import { actionCampgroundSetData } from 'Checkout/Redux/Actions/Campground'
import { actionCamperSetData } from 'Checkout/Redux/Actions/Camper'
import { detailsSetData } from 'Checkout/Redux/Actions/Details'
import { actionOptionsSetData, updateURLs } from 'Checkout/Redux/Actions/Options'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { setIdentifyUser } from 'Checkout/Redux/Actions/TrackSegment'
import Routing from 'Checkout/Components/Routing/Routing'
import NotFound from 'Checkout/Components/NotFound/NotFound'
import 'Styles/BootstrapTheme.scss'
import 'Checkout/Styles/Global'

const App = ({ campgroundSlug, marketplace, maxLength, maxWidth, setCamper, setCampground, setDetails,
               setIdentifyUser, setOptions, setSearch, setUrl, userToken, utmParams }) => {
  const [getCheckoutData, { data }] = useLazyQueryNoCache(checkoutQuery)
  const routingState = useState({})
  const [isValid, setIsValid] = useState(true)
  const history = useHistory()

  const fetchCheckoutData = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const marketplaceOverrideUuid = urlParams.get('d')

    const checkoutQueryArgs = { variables: { filters: { maxLength, maxWidth }, marketplaceOverrideUuid,
                                             slug: campgroundSlug, userToken } }
    getCheckoutData(checkoutQueryArgs)
  }

  const setTheme = campground => {
    document.documentElement.style.setProperty('--theme-primary', campground.websiteColorPrimary)
    document.documentElement.style.setProperty('--theme-primary-accent', campground.websiteColorPrimaryAccent)
    document.documentElement.style.setProperty('--theme-secondary', campground.websiteColorSecondary)
  }

  const setUtmData = () => {
    const utmData = parseObject(utmParams)

    if (utmData?.url) setCampground({ utmData })
  }

  const checkoutPathPresent = () => {
    if (window.roverData && window.roverData.checkoutPath) return false
    setIsValid(false)
  }

  useLayoutEffect(() => {
    checkoutPathPresent()
    fetchCheckoutData()
  }, [])

  const getAccommodationValue = (canUseDaily, canUseOvernight) => {
    if (canUseDaily && canUseOvernight) return null

    if (canUseDaily) return 'daily'

    return 'overnight'
  }

  useLayoutEffect(() => {
    if (data) {
      const { campground, currentUser, options } = data
      const { canUseAccommodationDaily, canUseAccommodationNightly } = campground
      const accommodation = getAccommodationValue(canUseAccommodationDaily, canUseAccommodationNightly)
      if (accommodation) setSearch({ accommodation, dayUse: accommodation === 'daily' })
      setCampground({ ...campground, marketplace })
      setOptions(options)
      setTheme(campground)
      setUtmData()
      if (currentUser) {
        setCamper(currentUser)
        setDetails(currentUser)
        setIdentifyUser(currentUser)
      }
    }
  }, [data])

  useEffect(() => {
    const unlistenHistory = history.listen(() => setUrl(window.location.href))

    return () => unlistenHistory()
  }, [])

  // Refresh Queue-It connection to ensure the user is in the correct spot in the queue.
  window.QueueIt?.validateUser()

  if (!isValid) return <NotFound />

  return (
    <RoutingContext.Provider value={routingState}>
      <Switch>
        {Object.values(routes).map(route => (
          <Routing {...route.props} component={route.component} key={route.key}
                   route={route} />
        ))}
      </Switch>
    </RoutingContext.Provider>
  )
}

App.defaultProps = {
  campgroundSlug: null,
  setCamper: null,
  setCampground: null,
  setIdentifyUser: null,
  setOptions: null,
  usertoken: null,
  utmParams: null,
}

App.propTypes = {
  campgroundSlug: PropTypes.string,
  setCamper: PropTypes.func,
  setCampground: PropTypes.func,
  setIdentifyUser: PropTypes.func,
  setOptions: PropTypes.func,
  usertoken: PropTypes.string,
  utmParams: PropTypes.shape({
    url: PropTypes.string,
  }),
}

const mapStateToProps = state => ({
  maxLength: state.search.rigLength,
  maxWidth: state.search.rigWidth,
})

const mapDispatchToProps = dispatch => ({
  setCampground: data => dispatch(actionCampgroundSetData(data)),
  setCamper: data => dispatch(actionCamperSetData(data)),
  setDetails: data => dispatch(detailsSetData(data)),
  setIdentifyUser: data => dispatch(setIdentifyUser(data)),
  setOptions: data => dispatch(actionOptionsSetData(data)),
  setSearch: data => dispatch(searchFilterUpdate(data)),
  setUrl: url => dispatch(updateURLs(url)),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(App)
