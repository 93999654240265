import cnames from 'classnames'
import { autoAssignLabel } from 'Utils/siteHelpers'
import { guestsAddedToString } from 'Utils/guestHelpers'
import storeConnect from 'Checkout/Redux/Connect'
import InfoTripInsurance from 'Checkout/Components/Shared/InfoTripInsurance'
import ReservationDetailItem from 'Shared/ReservationDetailItem'
import ReservationAddons from './ReservationAddons'
import TaxDetails from 'Shared/TaxDetails'
import TotalRow from 'Checkout/Components/Shared/TotalRow'

const ReservationConfirmationInfo = ({ className, tripInsuranceSupportPhone, reservation }) => {
  const { autoAssign, balance, balanceDue, balancePaid, balanceRemaining, balanceTotal, customFieldValues, data,
          email, formattedFrom: from, formattedTo: to, guests, hasBalance, hasTripInsurance, isDayUse,
          marketplace, name, phone, pricing, rate, rig, site, tripInsurancePolicy,
          tripInsurancePrice } = reservation
  const { camperFee, camperProcessingFee, isTaxIncluded, items, nonRefundableAmount, subtotal, tax,
          taxConfig } = pricing
  const { pet_breeds: petBreeds, special_request: specialRequest } = data
  const { useGuests, vehicles, vehiclesTowed } = guests
  const containerClasses = cnames('reservation-details--information', className)
  const feeLabel = marketplace ? 'Service Fee' : 'Platform Fee'
  const isTowingLabel = vehiclesTowed ? 'Yes' : 'No'
  const tripProtectionPrice = tripInsurancePolicy ? tripInsurancePolicy.grossPremium :
                              tripInsurancePrice
  const datesToShow = isDayUse ? from : `${from} - ${to}`
  const datesLabel = isDayUse ? 'Date' : 'Dates'
  const siteTypeLabel = isDayUse ? 'Type' : 'Site Type'
  const splitItems = items.reduce((acc, item) => {
    const property = item.itemableType === 'discount' ? 'discounts' : 'addons'
    if (!acc[property]) acc[property] = []

    acc[property].push(item)
    return acc
  }, {})

  return (
    <div className={containerClasses}>
      <h5 className="fw-bold mb-3">Details</h5>

      <div className="ps-3">
        <ReservationDetailItem containerClass="mt-md-4" itemName="Name" value={name}
                               valueClass="text-gray" />

        <ReservationDetailItem itemName="Phone" value={phone.display} valueClass="text-gray" />

        <ReservationDetailItem itemName="Email" value={email} valueClass="text-gray" />

        <ReservationDetailItem containerClass="mt-3" itemName={datesLabel} value={datesToShow}
                               valueClass="text-gray" />

        <ReservationDetailItem itemName="Guests" value={guestsAddedToString(guests, useGuests)}
                               valueClass="text-gray text-capitalize" />

        {vehicles > 0 && (
          <ReservationDetailItem itemName="Towing Extra Vehicle?" value={isTowingLabel} valueClass="text-gray" />
        )}

        {petBreeds && (
          <ReservationDetailItem itemName="Pet's Breed" value={petBreeds} valueClass="text-gray" />
        )}

        {rate && (
          <ReservationDetailItem containerClass="mt-3" itemName={siteTypeLabel} value={rate.siteTypeName}
                                 valueClass="text-gray" />
        )}

        {!isDayUse && site && (
          <ReservationDetailItem itemName="Site" value={autoAssignLabel(autoAssign === 'auto', site.name)}
                                 valueClass="text-gray" />
        )}

        {rig && (
          <ReservationDetailItem itemName="Rig Size" value={`${rig.rigLength} ft. x ${rig.rigWidth} ft.`}
                                 valueClass="text-gray" />
        )}

        {splitItems?.addons && <ReservationAddons addons={splitItems.addons} className="mt-4" />}

        {splitItems?.discounts && splitItems?.discounts.map(({ base, id, name }) => (
          <ReservationDetailItem itemName={name} key={id} value={base?.display} valueClass="text-gray" />
        ))}

        <ReservationDetailItem containerClass="mt-4" itemName="Subtotal" value={subtotal?.display}
                               valueClass="text-gray" />

        {!isTaxIncluded && (
          <ReservationDetailItem itemName="Tax" value={tax} valueClass="text-gray" />
        )}

        {camperProcessingFee?.amount > 0 && (
          <ReservationDetailItem itemName="Processing Fee" value={camperProcessingFee} valueClass="text-gray" />
        )}

        <ReservationDetailItem itemName={feeLabel} value={camperFee?.display} valueClass="text-gray" />

        {hasTripInsurance && (
          <ReservationDetailItem itemName="Trip Insurance" value={tripProtectionPrice.display}
                                 valueClass="text-gray" />
        )}

        <TotalRow {...{ balancePaid, balanceDue, balanceRemaining, balanceTotal, hasBalance, pricing }}
                  balanceRefunded={balance?.refunded} />

        {isTaxIncluded && (
          <TaxDetails className="mb-3 mb-md-4" nameClass="fw-bold" tax={tax} taxName={taxConfig?.taxName}
                      titleClass="text-gray" valueClass="text-gray" />
        )}

        {nonRefundableAmount?.amount > 0 && (
          <>
            <hr />

            <p>
              <small className="text-muted fst-italic">
                {nonRefundableAmount?.display} of this reservation is non-refundable per the RoverPass
                Cancellation policy. Check the campground cancellation policy for any other non-refundable amounts.
              </small>
            </p>
          </>
        )}
      </div>

      {specialRequest && (
        <div>
          <h5 className="fw-bold mb-3">Special Requests</h5>
          <p className="text-gray mt-3">{specialRequest}</p>
        </div>
      )}

      {hasTripInsurance && (
        <div className="mt-4">
          <h5 className="fw-bold mb-3">Trip Protection Information</h5>

          <p className="fw-bold">Policy Number: { tripInsurancePolicy?.number || 'Processing...' }</p>

          <p>
            This policy covers any expenses charged at the time of booking the reservation. If any modifications
            are made to this reservation, reach out to Generali at {` ${tripInsuranceSupportPhone}`} to ensure any
            additional charges made for modifications on this reservation are included in your coverage
          </p>

          <InfoTripInsurance className="mt-1" showFirstParagraph={false}
                             termsAndConditionsClass="text-muted fst-italic" />
        </div>
      )}

      {!!customFieldValues.length && (
        <div>
          <h5 className="fw-bold mb-3">More Information</h5>
          {customFieldValues.map(({ question, answer }) => {
            const fetchAnswer = typeof answer === 'object' ? answer.join(', ') : answer

            return (
              <div key={question} className="ps-3">
                <ReservationDetailItem itemName={question} value={fetchAnswer} valueClass="text-gray" />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  tripInsuranceSupportPhone: state.options.tripInsuranceSupportPhone,
})

export default storeConnect({ mapStateToProps })(ReservationConfirmationInfo)
