export const skeletonProducts = [{
  countOnHand: 1,
  description: '',
  display: '$10.00  / stay for skeleton',
  id: '1',
  name: 'Skeleton product',
  onlineMax: 5,
  position: 10,
  price: {
    amount: 10,
    display: '$10.00',
  },
  priceDescription: 'stay',
  pricePerGuest: false,
  pricePerNight: false,
}]
