import { Row } from 'reactstrap'
import Card from 'Checkout/Components/Payment/Card/Card'

const Cards = ({ cards }) => (
  <Row className="g-0 w-100 m-0">
    {cards.map(card => <Card key={card.id} card={card} />)}
  </Row>
)

export default Cards
