import { amenityName } from 'Checkout/Components/Shared/AmenityIcons'
import AmenityIcon from 'Checkout/Components/Shared/AmenityIcon/AmenityIcon'
import './Amenities.scss'

const Amenities = ({ amenities }) => {
  const amenitiesList = amenities.map(amenity => {
    const name = amenityName(amenity)

    return (
      <li className="amenity fw-bold align-middle text-gray list-inline-item pe-2" key={name}>
        <div className="d-flex align-items-center">
          <AmenityIcon amenity={amenity} />
          {name}
        </div>
      </li>
    )
  })

  return (
    <div className="amenities">
      <ul className="list-inline">
        {amenitiesList}
      </ul>
    </div>
  )
}

export default Amenities
