import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'

const SearchAlerts = ({ alerts }) => (
  alerts.map(alert => (
    <Alert color={alert.type} className="mt-3" key={alert.message}>
      <p className=" mb-0">{alert.message}</p>
    </Alert>
  ))
)

SearchAlerts.propTypes = {
 alerts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ),
}

SearchAlerts.defaultProps = {
  alerts: [],
}

export default SearchAlerts
