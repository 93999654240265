import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useRouter from 'Checkout/Hooks/useRouter'
import { useAsyncQuery } from 'Shared/Hooks/useApolloClient'
import { actionCampgroundSetData } from 'Checkout/Redux/Actions/Campground'
import useSiteTypes from 'Checkout/Hooks/useSiteTypes/useSiteTypes'
import rateQuery from 'Checkout/Queries/RateQuery'
import { isFutureDate, isValidDatesRange, parseMoment } from 'Utils/Dates'
import { validateRigSize } from 'Checkout/Utils/rvHelpers'
import { searchFilterUpdate, searchUpdateWithSessionStorage } from 'Checkout/Redux/Actions/Search'

const useCheckoutParams = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { validSiteType } = useSiteTypes()
  const [paramsLoading, setParamsLoading] = useState()
  const { canUseAccommodationDaily, canUseAccommodationNightly } = useSelector(state => state.campground)
  const siteLockPurchased = useSelector(state => state.payment.isSiteLock)
  const getRate = useAsyncQuery(rateQuery)

  const { accommodation, check_in: checkIn, check_out: checkOut, site_type: siteType, rate_id: rateId,
          rig_length: rigLength, rig_width: rigWidth } = router.query
  const clearSiteParams = { rate_id: '', rig_length: '', rig_width: '' }
  const dayUse = accommodation === 'daily'

  const validateSearchParams = () => {
    const isDatesRangeValid = isValidDatesRange({ from: checkIn, to: checkOut }) && canUseAccommodationNightly
                              isFutureDate({ from: checkIn })
    const isValidDaily = isFutureDate({ from: checkIn }) && canUseAccommodationDaily && dayUse
    const siteTypeName = validSiteType(siteType)

    if (!isDatesRangeValid && !isValidDaily)
      return _goToHome({ accommodation: '', check_in: '', check_out: '', site_type: '', ...clearSiteParams })

    _updateDates({ checkIn, checkOut })

    if (!siteTypeName) return _goToHome({ site_type: '', ...clearSiteParams })
    _updateSiteType({ type: siteType, siteTypeName })
  }

  const validateSiteParams = async ignoreSiteLock => {
    setParamsLoading(true)
    const rateArgs = { from: checkIn, rateId, slug: window.roverData.campgroundSlug, to: checkOut }
    const { data: rateData } = await _fetchRate(rateArgs)
    setParamsLoading(false)

    if (rateData) {
      const { rate, canUseNonRefundable, canUseSiteLock,
              isInstantBooking, isRefundableFully } = rateData.campground

      dispatch(actionCampgroundSetData({ canUseNonRefundable, isInstantBooking, isRefundableFully }))

      if (!canUseSiteLock || ignoreSiteLock) {
        _updateRate(rate)

        const { rigRequired } = rate
        if (rigRequired) {
          const isRigSizeValid = validateRigSize({ rigLength, rigWidth })
          if (isRigSizeValid) _updateRigSize({ rigLength: parseInt(rigLength), rigWidth: parseInt(rigWidth) })
        }
        return false
      }
    }
    if (!siteLockPurchased) _goToSearch()
  }

  const getAccommodation = (checkIn, checkOut) => {
    if (checkIn && checkOut) return { accommodation: 'overnight', dayUse: false }

    if (accommodation) return { accommodation, dayUse }

    return { accommodation: null, dayUse: null }
  }

  // private
  const _goToHome = search => {
    _updateSearchParams({ siteType: null })
    router.go({ pathname: '/', search })
  }

  const _goToSearch = () => router.go({ pathname: '/search', search: clearSiteParams })

  const _fetchRate = async ({ from, rateId, slug, to }) => {
    const rateQueryArgs = { variables: { from, rateId, slug, to } }
    const response = await getRate(rateQueryArgs)
    return response
  }

  const _updateSearchParams = data => dispatch(searchFilterUpdate(data))

  const _updateDates = ({ checkIn, checkOut }) => {
    const dates = { checkIn: parseMoment(checkIn, { isFormatNeeded: true }),
                    checkOut: parseMoment(checkOut, { isFormatNeeded: true }) }
    _updateSearchParams({ ...dates, ...getAccommodation(checkIn, checkOut) })
  }

  const _updateRate = rate => dispatch(searchUpdateWithSessionStorage({ rate }, 'selected-rate'))

  const _updateRigSize = ({ rigLength, rigWidth }) => {
    const rigSize = { rigWidth, rigLength }
    _updateSearchParams(rigSize)
  }

  const _updateSiteType = ({ type, siteTypeName }) => {
    const siteType = { type, name: siteTypeName }
    _updateSearchParams({ siteType })
  }

  return { validateSearchParams, validateSiteParams, paramsLoading }
}

export default useCheckoutParams
