import { useDispatch, useSelector } from 'react-redux'
import { setGlobalError } from 'Checkout/Redux/Actions/Errors'

const useErrors = () => {
  const { globalError } = useSelector(state => state.errors)
  const dispatch = useDispatch()

  const updateGlobalError = error => {
    dispatch(setGlobalError(error))
  }

  return {
    globalError,
    updateGlobalError,
  }
}

export default useErrors
