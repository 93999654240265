import { useSelector } from 'react-redux'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import InfoTripInsurance from 'Checkout/Components/Shared/InfoTripInsurance'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import TripProtectionActions from './TripProtectionActions'
import './AddTripProtection.scss'

const AddTripProtection = ({ className, children, price, setIsTripProtection }) => {
  const classes = cnames('trip-protection', className, { 'mb-3 rounded p-3': !className })
  const travelProtectionUrl = useSelector(state => state.options.externalLinks.travelProtection)

  return (
    <div className={classes}>
      <div className="d-flex align-items-center">
        <h5 className="fw-bold mb-3">
          <FontAwesomeIcon className="me-2" color="#1E93EC" icon="shield-halved" size="lg" />
          Add Trip Protection
        </h5>

        <a className="small text-decoration-none mt-1 ms-2 mb-3" href={travelProtectionUrl} rel="noreferrer"
           target="_blank">
          See Plan Details Here
        </a>
      </div>

      <ul className="list-unstyled mb-3">
        <li className="mb-2">
          <FontAwesomeIcon className="ms-2 me-2" color="#23741F" icon={['far', 'hand-holding-circle-dollar']}
                           size="lg" />
          Up to 100% reimbursement
        </li>
      </ul>

      {children ? (
        <>{children}</>
      ) : (
        <>
          <TripProtectionActions setIsTripProtection={setIsTripProtection} price={price} />

          <InfoTripInsurance travelProtectionUrl={travelProtectionUrl} />
        </>
      )}

    </div>
  )
}

AddTripProtection.defaultProps = {
  price: '',
}

AddTripProtection.propTypes = {
  price: PropTypes.string,
  setIsTripProtection: PropTypes.func.isRequired,
}

export default AddTripProtection
