import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import useRouter from 'Checkout/Hooks/useRouter'
import useRatesAvailabilityAlerts from 'Checkout/Hooks/useRatesAvailabilityAlerts/useRatesAvailabilityAlerts'
import { formatDate } from 'Utils/Dates'
import storeConnect from 'Checkout/Redux/Connect'
import siteTypesQuery from './SiteTypesQuery'
import { actionCampgroundSetData } from 'Checkout/Redux/Actions/Campground'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import NoticesModal from 'Checkout/Components/Shared/ViewButtons/Modals/NoticesModal'
import SearchAlerts from 'Checkout/Components/Search/SearchAlerts'
import SiteTypesList from '../SiteTypesList/SiteTypesList'

const SiteTypesSelector = ({ accommodation, campgroundSlug, dayUse, setAlerts, setSiteType }) => {
  const router = useRouter()
  const alerts = useRatesAvailabilityAlerts()
  const { checkIn, checkOut, validStayDates } = useStayDates()

  const [siteTypesDataSet, setSiteTypesDataSet] = useState({ isAvailable: true, message: null, siteTypes: [] })
  const [getSiteTypes, { data: siteTypesData, loading: siteTypesLoading,
                         error: siteTypesError }] = useLazyQueryNoCache(siteTypesQuery)
  const { isAvailable, message, siteTypes } = siteTypesDataSet

  const fetchSiteTypes = fetchQueryArgs => {
    const { from, slug, to } = fetchQueryArgs
    const siteTypesQueryArgs = { variables: { filters: { dayUse }, from, slug, to } }

    getSiteTypes(siteTypesQueryArgs)
  }

  const goNext = siteType => {
    let search = { accommodation, check_in: formatDate(checkIn), site_type: siteType }
    if (!dayUse) search = { ...search, check_out: formatDate(checkOut) }

    router.next({ search })
  }

  useEffect(() => {
    if (validStayDates && campgroundSlug) {
      const fetchQueryArgs = { from: formatDate(checkIn), slug: campgroundSlug, to: formatDate(checkOut) }
      fetchSiteTypes(fetchQueryArgs)
    }
  }, [checkIn, checkOut, dayUse, campgroundSlug])

  useEffect(() => {
    if (siteTypesData && !siteTypesError) {
      const { alerts, availability: { isAvailable, message },
              sitesCountByTypeDuring: siteTypesResult } = siteTypesData.campground

      if (siteTypesResult.length === 1 && (totalSiteTypesUnavailable === 0 || dayUse)) {
        setSiteType(siteTypesResult[0])
        goNext(siteTypesResult[0].type)
      } else {
        if (siteTypesResult.length === 0) setAlerts({ alerts, alertsModal: true })
        setSiteTypesDataSet({ isAvailable, message, siteTypes: siteTypesResult })
      }
    }
  }, [siteTypesData])

  const totalSiteTypesUnavailable = useMemo(() => {
    const hasData = siteTypesData?.campground && !siteTypesError
    if (!hasData) return []

    const { campground: { unavailableSitesTypesCount: siteTypesUnavailable } } = siteTypesData

    return siteTypesUnavailable
  }, [siteTypesData])

  return (
    <div className="home-site-types">
      {checkIn && checkOut && alerts.length > 0 && <SearchAlerts alerts={alerts} />}

      <SiteTypesList isAvailable={isAvailable} loading={siteTypesLoading} message={message}
                     siteTypes={siteTypes} />

      {!siteTypesLoading && !siteTypes.length && <NoticesModal notAvailable />}
    </div>
  )
}

SiteTypesSelector.propTypes = {
  campgroundSlug: PropTypes.string.isRequired,
  setSiteType: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  accommodation: state.search.accommodation,
  campgroundSlug: state.campground.slug,
  dayUse: state.search.dayUse,
})

const mapDispatchToProps = dispatch => ({
  setAlerts: data => dispatch(actionCampgroundSetData(data)),
  setSiteType: ({ name, type }) => dispatch(searchFilterUpdate({ siteType: { name, type } })),
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(SiteTypesSelector)
