import { useSelector } from 'react-redux'

const useSiteTypes = () => {
  const { siteTypes } = useSelector(state => state.options)

  const validSiteType = siteType => siteTypes[siteType]

  return { validSiteType }
}

export default useSiteTypes
