import * as Sentry from '@sentry/browser'

function sentrySetup(sentryDsn) {
  if (sentryDsn) Sentry.init({ dsn: sentryDsn })
}

const notifyError = message => {
  // eslint-disable-next-line
  console.error('ERROR: ', message)
  // TODO: notify Sentry
}

export { notifyError, sentrySetup }
