import ErrorPage from 'Checkout/Components/Shared/ErrorPage/ErrorPage'
import errorImage from 'Checkout/Images/AppError'

const ErrorView = () => (
  <ErrorPage contactButtonGoTo="https://help.roverpass.com/contact-camper-support"
             image={errorImage} title="Oops, something went wrong"
             subtitle="Try to refresh the page or feel free to contact us if the problem persist" />
)

export default ErrorView
