import { useDeferredValue, useEffect, useLayoutEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import sitesQuery from './SitesQuery'
import storeConnect from 'Checkout/Redux/Connect'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import useRouter from 'Checkout/Hooks/useRouter'
import useUnload from 'Checkout/Hooks/useUnload'
import useCheckoutParams from 'Checkout/Hooks/useCheckoutParams'
import { updatePaymentSiteLock } from 'Checkout/Redux/Actions/Payment'
import { searchFilterUpdate, searchUpdateWithSessionStorage } from 'Checkout/Redux/Actions/Search'
import { setCheckoutStepViewed, setPageView } from 'Checkout/Redux/Actions/TrackSegment'
import { formatDate } from 'Utils/Dates'
import Header from 'Checkout/Components/Shared/Header/Header'
import { InteractiveMap } from 'Shared/InteractiveMap'
import MainColumn from 'Checkout/Components/Shared/MainColumn/MainColumn'
import MapSitesPopoverContent from 'Checkout/Components/Sites/MapSitesPopoverContent/MapSitesPopoverContent'
import PageTitle from 'Checkout/Components/Shared/PageTitle/PageTitle'
import ProgressNav from 'Checkout/Components/Shared/ProgressNav/WrapperProgressNav'
import ReservationDetails from 'Checkout/Components/Shared/ReservationDetails/ReservationDetails'
import Section from 'Checkout/Components/Shared/Section/Section'
import SideColumn from 'Checkout/Components/Shared/SideColumn/SideColumn'
import SitesList from 'Checkout/Components/Sites/SitesList'
import SiteLockModal from 'Checkout/Components/Search/SiteLockModal/SiteLockModal'

const Sites = ({ campgroundSlug, canUseSiteLock, checkIn, checkOut, isAutoAssign, rate, rigLength, rigWidth,
                 setSite, setSiteLockModal, siteLockFee, siteLockModal, siteLockPurchased, siteType, sitesMapUrl,
                 setCheckoutStepViewed, setIsSiteLock, setPageView }) => {
  const [sites, setSites] = useState()
  const [selectedSite, setSelectedSite] = useState()
  const dataLoaded = useDeferredValue(sites)
  const router = useRouter()
  const { validateSearchParams, validateSiteParams, paramsLoading } = useCheckoutParams()
  const [getSites, { data: sitesData, loading: sitesLoading }] = useLazyQueryNoCache(sitesQuery)
  const componentRef = useRef(null)
  useUnload()

  const fetchSites = fetchQueryArgs => {
    const { from, to, maxLength, maxWidth, rateId, types, slug } = fetchQueryArgs
    const filters = { maxLength, maxWidth, types }
    const sitesQueryArgs = { variables: { from, rateId, slug, to, filters } }

    getSites(sitesQueryArgs)
  }

  const goBack = () => {
    const search = { rate_id: '', rig_length: '', rig_width: '' }
    router.back?.({ search })
  }

  const areSitesLoading = () => {
    const noDataYet = dataLoaded === undefined
    if (!rate && !siteLockPurchased) return ((sitesLoading && paramsLoading) || noDataYet)

    return noDataYet || sitesLoading
  }

  const onSelectSite = siteSelected => {
    setSite(siteSelected)
    if (canUseSiteLock && sitesMapUrl) return setSiteLockModal(true)

    router.next()
  }

  const resetSiteSelected = () => setSelectedSite(null)

  const scrollToSiteMap = siteSelected => {
    setSelectedSite(siteSelected)
    document.getElementById('interactive-map').scrollIntoView({ behavior: 'smooth' })
  }

  useLayoutEffect(() => {
    validateSearchParams()
    if (!siteLockPurchased && sitesMapUrl) setIsSiteLock(false)
  }, [])

  useEffect(() => {
    if (sitesData?.campground) setSites(sitesData.campground.sitesAvailableDuring)
  }, [sitesData])

  useEffect(() => {
    if (isAutoAssign && (!canUseSiteLock || (!siteLockPurchased && !sitesMapUrl))) goBack()
    if (!rate && (!isAutoAssign || sitesMapUrl)) validateSiteParams(!!sitesMapUrl)
    if (!isAutoAssign) setCheckoutStepViewed()
    setPageView()
  }, [canUseSiteLock, isAutoAssign, rate, siteLockPurchased])

  useEffect(() => {
    if (!isAutoAssign || siteLockPurchased || (!siteLockPurchased && sitesMapUrl))
      if (campgroundSlug && rate && !sitesLoading) {
        const fetchQueryArgs = { from: formatDate(checkIn), maxLength: rigLength,
                                maxWidth: rigWidth, rateId: rate.id, slug: campgroundSlug,
                                to: formatDate(checkOut), types: [siteType] }
        fetchSites(fetchQueryArgs)
      }
  }, [campgroundSlug, isAutoAssign, rate])

  const loading = areSitesLoading()

  return (
    <Section name="sites">
      <MainColumn lg="7" xl="8">
        <Header />

        <PageTitle className="mb-3 mb-md-4 text-center" text="Select Your Site" />

        <ProgressNav />

        <div>
          {(sitesMapUrl) && (
            <InteractiveMap resetSiteSelected={resetSiteSelected} siteLockFee={siteLockFee?.display} sites={sites}
                            src={sitesMapUrl} selectedSite={selectedSite}>
              {props => (
                <MapSitesPopoverContent {...props} onSelectSite={onSelectSite} />
              )}
            </InteractiveMap>
          )}

          <SitesList loading={loading} ref={componentRef} sites={sites} scrollToSiteMap={scrollToSiteMap} />
        </div>
      </MainColumn>

      <SideColumn showOnMobile lg="5" xl="4">
        <ReservationDetails showSite={false} showCompleteDetails editBtnSmall />
      </SideColumn>

      {siteLockModal && sitesMapUrl && <SiteLockModal useInSites />}
    </Section>
  )
}

Sites.defaultProps = {
  campgroundSlug: null,
  canUseSiteLock: null,
  checkIn: null,
  checkOut: null,
  isAutoAssign: null,
  rate: null,
  rigLength: null,
  rigWidth: null,
  siteLockPurchased: null,
  siteLockFee: null,
  siteType: null,
  setPageView: null,
  setCheckoutStepViewed: null,
}

Sites.propTypes = {
  campgroundSlug: PropTypes.string,
  canUseSiteLock: PropTypes.bool,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  isAutoAssign: PropTypes.bool,
  rate: PropTypes.shape({
    id: PropTypes.string,
  }),
  rigLength: PropTypes.number,
  rigWidth: PropTypes.number,
  setSite: PropTypes.func.isRequired,
  setSiteLockModal: PropTypes.func.isRequired,
  siteLockFee: PropTypes.shape({
    display: PropTypes.string,
  }),
  siteLockModal: PropTypes.bool.isRequired,
  siteLockPurchased: PropTypes.bool,
  siteType: PropTypes.string,
  setPageView: PropTypes.func,
  setCheckoutStepViewed: PropTypes.func,
}

const mapStateToProps = state => ({
  isAutoAssign: state.campground.isAutoAssign,
  campgroundSlug: state.campground.slug,
  canUseSiteLock: state.campground.canUseSiteLock,
  checkIn: state.search.checkIn,
  checkOut: state.search.checkOut,
  rate: state.search.rate,
  rigLength: state.search.rigLength,
  rigWidth: state.search.rigWidth,
  site: state.search.site,
  siteLockFee: state.campground.siteLockFee,
  siteLockModal: state.search.siteLockModal,
  siteLockPurchased: state.payment.isSiteLock,
  sitesMapUrl: state.campground.sitesMapUrl,
  siteType: state.search.siteType?.type,
})

const mapDispatchToProps = dispatch => ({
  setCheckoutStepViewed: () => dispatch(setCheckoutStepViewed(3)),
  setIsSiteLock: isSiteLock => dispatch(updatePaymentSiteLock({ isSiteLock })),
  setPageView: () => dispatch(setPageView('Checkout: Site Selection')),
  setSite: ({ id, name }) => dispatch(searchUpdateWithSessionStorage({ site: { id, name } }, 'site')),
  setSiteLockModal: siteLockModal => dispatch(searchFilterUpdate({ siteLockModal })),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(Sites)
