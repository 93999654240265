import { Col, Row } from 'reactstrap'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import NoAvailabilityAlert from 'Checkout/Components/Shared/NoAvailabilityAlert'
import SiteType from '../SiteType/SiteType'
import { skeletonSiteTypesList } from './skeletonSiteTypesList'
import 'Shared/Skeleton/Skeleton.scss'
import './SiteTypesList.scss'

const SiteTypesList = ({ isAvailable, loading, loadingRates, message, siteTypes, useInSearch }) => {
  const alertMessage = 'It looks like we have no availability for the selected dates! Please select a new date range.'
  const classesWrapper = cnames('site-types-list', { 'skeleton-loading': loading })
  const columnsLG = useInSearch ? 3 : 6
  const sitesToShow = loading && !siteTypes.length ? skeletonSiteTypesList : siteTypes

  if ((!siteTypes.length || !isAvailable) && !loading)
    return <NoAvailabilityAlert alertMessage={message || alertMessage} />

  return (
    <div className={classesWrapper}>
      <Row className="site-types-list__container mt-3 ms-0">
      {sitesToShow.map(site => {
        const { name, type, available_sites: availableSites } = site

        return (
          <Col className="ps-0 pe-3" key={type || site} lg={columnsLG} xs="6">
            <SiteType loading={loading} loadingRates={loadingRates} siteType={type} siteTypeName={name}
                      totalSites={availableSites} useInSearch={useInSearch} />
          </Col>
        )
      })}
      </Row>
    </div>
  )
}

SiteTypesList.defaultProps = {
  isAvailable: true,
  loading: true,
  message: null,
  siteTypes: [],
}

SiteTypesList.propTypes = {
  isAvailable: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  siteTypes: PropTypes.instanceOf(Array),
}

export default SiteTypesList
