import FlexTwoColums from './FlexTwoColums'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import Image from 'Shared/Image/Image'
import Link from 'Shared/Link/Link'
import RatingWithStars from 'Checkout/Components/RatingWithStars'
import defaultPicture from 'Checkout/Images/photo-coming-soon-list.png'
import './CardCampground.scss'

const CardCampground = ({ averageReviewRating, cityName, links, milesDistanceToCampground, name,
                          picturesCount, pictureUrl, priceDailyLow, reviewsCount, stateName }) => (
  <div className="card-campground col-12 col-lg-6 mb-4 me-0 px-md-4 px-lg-4" data-name={name}>
    <div className="border rounded shadow">
      <div className="site-img-wrapper">
        <Image alt="Site" className="h-100 w-100" defaultImage={defaultPicture} srcToLoad={pictureUrl} />
      </div>

      <div className="px-3 py-2">
        <h5>{name}</h5>

        <FlexTwoColums className="text-muted mb-3">
          <FlexTwoColums.HalfRow className="me-2" withRightBorder>
            <FontAwesomeIcon className="me-1" icon="map-marker-alt" />
            <span className="text-smaller-2">{`${cityName}, ${stateName}`}</span>
          </FlexTwoColums.HalfRow>

          <FlexTwoColums.HalfRow className="text-end text-truncate">
            <FontAwesomeIcon className="mx-1" icon="location-arrow" />
            <span className="text-smaller-2">{milesDistanceToCampground}</span>
          </FlexTwoColums.HalfRow>
        </FlexTwoColums>

        <FlexTwoColums className="border-bottom pb-3 text-center text-salmon">
          <RatingWithStars className="d-flex align-items-start flex-column" rating={averageReviewRating} />

          <FlexTwoColums className="w-50 text-smaller-2">
            <div className="border-end w-50">
              <p className="fw-bold mb-1">Reviews</p>
              <p className="mb-0">{reviewsCount}</p>
            </div>

            <div className="w-50">
              <p className="fw-bold mb-1">Photos</p>
              <p className="mb-0">{picturesCount}</p>
            </div>
          </FlexTwoColums>
        </FlexTwoColums>

        <FlexTwoColums className="my-4">
          <FlexTwoColums.HalfRow className="me-2">
            <p className="mb-1 text-muted text-smaller-2">From</p>
            <span className="h5 me-1">{priceDailyLow?.display}</span>
            <span className="text-muted text-smaller-2">/Night</span>
          </FlexTwoColums.HalfRow>

          <FlexTwoColums.HalfRow className="text-end">
            <Link className="btn btn-salmon px-4" isExternal to={links?.marketPlaceUrl}>
              See Details
            </Link>
          </FlexTwoColums.HalfRow>
        </FlexTwoColums>
      </div>
    </div>
  </div>
)

export default CardCampground
