import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'
import storeConnect from 'Checkout/Redux/Connect'
import { isRvAdequateForRig } from 'Checkout/Utils/rvHelpers'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import useRouter from 'Checkout/Hooks/useRouter'
import CamperVehicles from 'Shared/CamperVehicles/CamperVehicles'
import FormErrorMessages from 'Shared/Formik/FormErrorMessages'
import FormGroupField from 'Shared/Formik/FormGroupField'
import FormYesOrNoButtons from 'Shared/Formik/FormYesOrNoButtons'
import PageSubtitle from 'Shared/PageSubtitle'
import AddRig from './AddRig'
import ScaleInput from '../ScaleInput/ScaleInput'
import WrapVehicleCard from './WrapVehicleCard'
import './VehicleInformation.scss'
import 'Styles/Text.scss'

const VehicleInformation = ({ isCampgroundRequest, rate, rigLength, rigWidth, setFieldValue, setRigSize,
                              savedVehicles, validations, vehicle }) => {
  const { guests, rig } = validations
  const { extraVehicles } = guests

  const router = useRouter()
  const [selectedVehicle, setSelectedVehicle] = useState(null)

  const maxExtraVehicle = extraVehicles?.max
  const maxPeopleMessage = `This site only accepts up to ${maxExtraVehicle} extra vehicles`

  useEffect(() => {
    if (selectedVehicle?.id !== vehicle.id) setSelectedVehicle(vehicle)
  }, [vehicle.id])

  useEffect(() => {
    if (selectedVehicle?.id) setFieldValue('vehicle', { id: selectedVehicle.id, length: '', slideouts: '', type: '',
                                                        year: '', width: '' })
    else {
      const { rig_length: rigLength, rig_width: rigWidth } = router.query
      setRigSize({ rigLength: parseInt(rigLength), rigWidth: parseInt(rigWidth) })
      setFieldValue('vehicle.id', '')
    }
  }, [selectedVehicle])

  const filterVehicle = vehicle => {
    const { rig_length: rigLength, rig_width: rigWidth } = router.query
    const isAllowedSizeRv = isRvAdequateForRig({ rigLength: vehicle.rigLength, rigWidth: vehicle.rigWidth,
                                                 maxLength: parseInt(rigLength), maxWidth: parseInt(rigWidth) })

    return isCampgroundRequest || isAllowedSizeRv
  }

  const filteredSavedVehicles = useMemo(() => (
    savedVehicles.filter(vehicle => filterVehicle(vehicle))
  ), [])

  const canAddVehicleInfo = rate?.canAddVehicle

  return (
    <div className="vehicle-information mb-4">
      <PageSubtitle text="Vehicle Information" />

      {canAddVehicleInfo && (
        <div className="rig-information">
          <CamperVehicles addRigClassName="mt-3" Component={WrapVehicleCard} selectedVehicle={selectedVehicle}
                          setSelectedVehicle={setSelectedVehicle} vehicles={filteredSavedVehicles} />

          {!selectedVehicle?.id && (
            <AddRig rig={rig} rigLength={rigLength} rigWidth={rigWidth} />
          )}
        </div>
      )}

      <div className="mb-3 mt-3">
        {maxExtraVehicle && (
          <div className="justify-content-between row align-items-center">
            <FormGroupField className="col-md-6 mb-0" name="guests.vehicles" showErrors>
              {({ field, form }) => (
                <>
                  <Label for="guests.vehicles" className="d-flex flex-column mb-2 fw-bold">
                    Extra Vehicles
                    <span className="fw-light">*excluding your tow vehicle</span>
                  </Label>
                  <ScaleInput enabled inputId="guests.vehicles" inputName="guests.vehicles" max={maxExtraVehicle}
                              setCountsFor={form.setFieldValue} value={field.value} />

                  {(field.value > maxExtraVehicle) && (
                    <FormErrorMessages className="mt-3" errors={[maxPeopleMessage]} />
                  )}
                </>
              )}
            </FormGroupField>

            <FormYesOrNoButtons className="col-md-6 mt-3 mt-md-0" label="Are you towing your extra vehicle?"
                                labelClasses="mt-3 form-label fw-bold" name="guests.vehiclesTowed" />
          </div>
        )}
      </div>
    </div>
  )
}

VehicleInformation.defaultProps = {
  rate: null,
  rigLength: null,
  rigWidth: null,
  savedVehicles: [],
}

VehicleInformation.propTypes = {
  isCampgroundRequest: PropTypes.bool.isRequired,
  rate: PropTypes.shape({
    rigRequired: PropTypes.bool,
  }),

  rigLength: PropTypes.number,

  rigWidth: PropTypes.number,

  savedVehicles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    rigLength: PropTypes.number,
    rigWidth: PropTypes.number,
  })),

  setFieldValue: PropTypes.func.isRequired,

  validations: PropTypes.shape({
    guests: PropTypes.shape({
      extraVehicles: PropTypes.shape({
        max: PropTypes.number,
      }),
    }),

    rig: PropTypes.shape({
      slideouts: PropTypes.shape({
        max: PropTypes.number,
        min: PropTypes.number,
      }),
      type: PropTypes.arrayOf(PropTypes.string),
      year: PropTypes.shape({
        max: PropTypes.number,
        min: PropTypes.number,
      }),
    }),
  }).isRequired,
  vehicle: PropTypes.shape({ id: PropTypes.string }).isRequired,
}

const mapStateToProps = state => ({
  isCampgroundRequest: state.campground.isRequest,
  rate: state.search.rate,
  rigLength: state.search.rigLength,
  rigWidth: state.search.rigWidth,
  savedVehicles: state.camper.equipment,
  vehicle: state.details.vehicle,
})

const mapDispatchToProps = dispatch => ({
  setRigSize: data => dispatch(searchFilterUpdate(data)),
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(VehicleInformation)
