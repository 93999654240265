import { Col } from 'reactstrap'
import FormGroupField from 'Shared/Formik/FormGroupField'
import ScaleInput from './ScaleInput/ScaleInput'

const GuestInput = ({ age, category, enabled, isEmpty, name, max }) => (
  <Col className="guest-input" md="12" lg="4">
    <div className="me-lg-3">
      <div className="d-flex align-items-center">
        <label className="text-sm mb-0 me-1" htmlFor={name}>{category}</label>

        {age && <small>Age {age}</small>}
      </div>

      <FormGroupField name={name}>
        {({ field, form, invalid }) => (
          <ScaleInput enabled={enabled} inputId={name} inputName={field.name} invalid={invalid} isEmpty={isEmpty}
                      max={max} setCountsFor={form.setFieldValue} value={field.value} />
        )}
      </FormGroupField>
    </div>
  </Col>
)

export default GuestInput
