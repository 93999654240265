import * as Yup from 'yup'

const messageRequired = 'Required field.'
const validZipCodeRegx = /^\d{5}$/

const PaymentViewSchema = ({ isTripProtection }) => {
  const schema = Yup.object().shape({
    card: Yup.bool(),

    address: Yup.string()
      .when('selectedCard', {
        is: selectedCard => !selectedCard,
        then: Yup.string()
          .required(messageRequired),
      }),

    address2: Yup.string(),

    acknowledgement: Yup.bool()
      .oneOf([true], 'You need to accept the terms & policies.'),

    city: Yup.string()
      .when('selectedCard', {
        is: selectedCard => !selectedCard,
        then: Yup.string()
          .required(messageRequired),
      }),

    discount: Yup.object().shape({
      code: Yup.string()
        .when('numberRequired', {
          is: numberRequired => numberRequired,
          then: Yup.string()
            .required(messageRequired),
        }),

      id: Yup.string(),

      numberRequired: Yup.bool(),
    }),

    state: Yup.string()
      .when('selectedCard', {
        is: selectedCard => !selectedCard,
        then: Yup.string()
          .required(messageRequired),
      }),

    tripInsurance: Yup.string(),

    homeAddress: Yup.object()
      .when({
        is: () => isTripProtection,
        then: Yup.object({
          address: Yup.string()
            .required(messageRequired),

          address2: Yup.string(),

          city: Yup.string()
            .required(messageRequired),

          state: Yup.string()
            .required(messageRequired),

          zipCode: Yup.string()
            .matches(validZipCodeRegx, 'Please enter a valid zip code')
            .required(messageRequired),
        }),
      }),
  })

  return schema
}

export default PaymentViewSchema
