import { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { useLazyQueryWithPolling } from 'Shared/Hooks/useApolloClient'
import useRouter from 'Checkout/Hooks/useRouter'
import { setCheckoutData, setCheckoutStepViewed, setPageView } from 'Checkout/Redux/Actions/TrackSegment'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import { detailsSetData } from 'Checkout/Redux/Actions/Details'
import defaultPicture from 'Checkout/Images/photo-coming-soon-list.png'
import AwinConversion from 'Checkout/Components/AwinConversion'
import CampgroundData from 'Checkout/Components/Shared/CampgroundData'
import DeepLinkAutomation from 'Shared/CJ/Scripts/DeepLinkAutomation'
import Image from 'Shared/Image/Image'
import RichText from 'Shared/RichText/RichText'
import MainColumn from 'Checkout/Components/Shared/MainColumn/MainColumn'
import NotFound from 'Checkout/Components/NotFound/NotFound'
import PoliciesWrap from 'Checkout/Components/Shared/PoliciesWrap'
import ProgressNav from 'Checkout/Components/Shared/ProgressNav/WrapperProgressNav'
import ReservationActions from 'Checkout/Components/ReservationDetails/ReservationActions/ReservationActions'
import ReservationConfirmationInfo from 'Checkout/Components/ReservationDetails/ReservationConfirmationInfo'
import ReservationDetailItem from 'Shared/ReservationDetailItem'
import ReservationHomeButton from 'Checkout/Components/ReservationDetails/ReservationActions/ReservationHomeButton'
import Section from 'Checkout/Components/Shared/Section/Section'
import reservationQuery from 'Shared/Queries/ReservationQuery'
import storeConnect from 'Checkout/Redux/Connect'
import RVezyModal from 'Checkout/Components/Shared/RVezyModal/RVezyModal'
import { skeletonReservation } from './skeletonReservation'
import 'Styles/MediaPrint.scss'

const CONFIRMATION_TEXT = "Please save this confirmation for your records; it will not be emailed to you. If you'd prefer, you can always digitally access this by logging into RoverPass as a Traveler here with the email address used to create this reservation."

const Confirmation = ({ campgroundImage, campgroundName, campgroundSlug, campgroundSystemType, imageAlt,
                        reservation, rvEzyUrl, setCheckoutData, setCheckoutStepViewed, setDetailsData,
                        setPageView, setPaymentData }) => {
  const router = useRouter()
  const [getReservation, { data, called, loading, error }] = useLazyQueryWithPolling(reservationQuery, {
    milliseconds: 1500,
    onPolling: ({ campground }) => campground?.reservation?.isPurchasePending,
  })
  const [isPurchasePending, setIsPurchasePending] = useState(false)
  const { uuid } = router.query
  const isPendingOrApproved = ['pending', 'approved'].includes(reservation?.state)

  useEffect(() => {
    if (!data || error) {
      setDetailsData({ reservationDetails: null })
      return
    }

    const { id: reservationId, isPurchasePending, pricing } = data.campground.reservation
    const { savings: discount } = pricing
    setCheckoutData({ discount, name: 'Order Completed', reservationId })
    setDetailsData({ reservationDetails: data.campground.reservation })
    setIsPurchasePending(isPurchasePending)
  }, [data])

  useEffect(() => {
    setCheckoutStepViewed()
    setPageView()

    if (!data && campgroundSlug && uuid) getReservation({ variables: { slug: campgroundSlug, uuid } })
  }, [campgroundSlug, uuid])

  useEffect(() => {
    setPaymentData({ card: {}, selectedCard: null })
  }, [])

  const reservationToShow = !called || loading || isPurchasePending ? skeletonReservation : reservation

  if (!reservationToShow) return <NotFound />

  return (
    <Section name="confirmation" className={!called || loading || isPurchasePending ? 'skeleton-loading' : ''}>
      <MainColumn>
        <div className="d-flex align-items-center justify-content-center my-5">
          <h2 className="mb-0 text-center">{reservationToShow.confirmationTitle}</h2>
        </div>

        <ProgressNav progressNavClass="not-loading" />

        <Row>
          <Col md={{ order: 2, size: 6 }} className="mt-5">
            {/* TODO: replace this with lightbox image gallery */}
            <div className="not-loading" style={{ height: 400 }}>
              <Image alt={imageAlt} defaultImage={defaultPicture} className="w-100 h-100 mb-2 img-cover"
                     srcToLoad={campgroundImage} />
            </div>

            <CampgroundData className="p-0 my-4 not-loading text-gray" />

            <ReservationHomeButton />
          </Col>

          <Col md={{ order: 1, size: 6 }} className="mt-5">
            <div className="pb-3 mb-3 border-bottom border-secondary">
              <ReservationDetailItem itemName="Confirmation Number" itemNameClass="h5"
                                     value={reservationToShow.code} valueClass="h5" />

              {/* NOTE: API sanitizes the message, so it's not really dangerous at this point */}
              {reservationToShow.confirmationMessage && !reservationToShow.isDayUse ? (
                <RichText className="text-gray" html={reservationToShow.confirmationMessage} />
              ) : <p>{CONFIRMATION_TEXT}</p>}

              <ReservationActions reservation={reservationToShow} />

              <RVezyModal openModalOnLoad={isPendingOrApproved && !isPurchasePending} rvEzyUrl={rvEzyUrl} />
            </div>

            {reservationToShow && (
              <ReservationConfirmationInfo className="mb-4" reservation={reservationToShow} />
            )}

            <PoliciesWrap applyReservationPolicy isDayUse={reservationToShow?.isDayUse}
                          reservationPolicy={reservationToShow?.cancellationPolicy}
                          showWhatToKnow={reservationToShow?.canShowWhatToKnow} />
          </Col>
        </Row>
      </MainColumn>

      {reservationToShow && (
        <AwinConversion campgroundName={campgroundName} campgroundSlug={campgroundSlug}
                        campgroundSystemType={campgroundSystemType} reservation={reservationToShow} />)}

      <DeepLinkAutomation />
    </Section>
  )
}

const mapDispatchToProps = dispatch => ({
  setCheckoutData: dataToCompleteOrderEvent => dispatch(setCheckoutData(dataToCompleteOrderEvent)),
  setCheckoutStepViewed: () => dispatch(setCheckoutStepViewed(6)),
  setDetailsData: data => dispatch(detailsSetData(data)),
  setPageView: () => dispatch(setPageView('Checkout: Confirmation')),
  setPaymentData: data => dispatch(paymentSetData(data)),
})

const mapStateToProps = state => ({
  campgroundImage: state.campground.pictureUrl,
  campgroundName: state.campground.name,
  campgroundSlug: state.campground.slug,
  campgroundSystemType: state.campground.systemType,
  reservation: state.details.reservationDetails,
  rvEzyUrl: state.options.externalLinks?.rvEzyUrl,
  imageAlt: state.campground.pictureAlt,
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(Confirmation)
