import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import storeConnect from 'Checkout/Redux/Connect'
import SizeFilterViewSchema from './SizeFilterViewSchema'
import { ValidationsPropTypes } from 'Checkout/PropTypes'
import { uniqueItems } from 'Utils/Arrays'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import FormErrorMessages from 'Shared/Formik/FormErrorMessages'
import SizeFilterInput from '../SizeFilterInput/SizeFilterInput'
import rvIcon from 'Checkout/Images/Icons/type_rv_site.svg'
import './SizeFilter.scss'

const SizeFilter = ({ children, checkRatesAvailability, isRvFitsRateSize, className, classErrors, onSubmit,
                      rigLength, rigSizeModal, rigWidth, validations }) => {
  const [sizeFilterSubmitting, setSizeFilterSubmitting] = useState(false)

  const sizeFilterClasses = cnames('rv-filter d-flex align-items-center w-100 rounded', className)

  const initialValues = { rigLength: rigLength || 0, rigSizeModal, rigWidth: rigWidth || 0 }
  const { maxLength, maxWidth, minLength, minWidth } = validations
  const rangeLength = { min: minLength, max: maxLength }
  const rangeWidth = { min: minWidth, max: maxWidth }

  return (
    <FormWithValidations initialValues={initialValues} validationSchema={SizeFilterViewSchema}
                         onSubmit={onSubmit}>
      {({ errors, isSubmitting, setFieldValue }) => {
        const errorsArray = uniqueItems(Object.values(errors))

        useEffect(() => {
          if (rigSizeModal && !isRvFitsRateSize && isSubmitting)
            checkRatesAvailability()
        }, [rigSizeModal, isRvFitsRateSize, isSubmitting])

        useEffect(() => {
          if (!sizeFilterSubmitting && isSubmitting) setSizeFilterSubmitting(!sizeFilterSubmitting)
        }, [isSubmitting])

        return (
          <div className="size-filter">
            <div className={sizeFilterClasses}>
              <div className="rig-wrapper">
                <img src={rvIcon} className="me-2" height="35" alt="RV Icon" />
              </div>
              <div className="d-flex align-items-center justify-content-center w-100">
                <SizeFilterInput name="rigLength" placeholder="Rig Length" rangeOptions={rangeLength}
                                 setFieldValue={setFieldValue} />

                <FontAwesomeIcon icon="xmark" className="text-grey" />

                <SizeFilterInput name="rigWidth" placeholder="Rig Width" rangeOptions={rangeWidth}
                                 setFieldValue={setFieldValue} />
              </div>
            </div>


            {!!errorsArray.length && sizeFilterSubmitting && (
              <FormErrorMessages errors={errorsArray} className={classErrors} />
            )}

            {children}
          </div>
      )
    }}
    </FormWithValidations>
  )
}

SizeFilter.defaultProps = {
  checkRatesAvailability: undefined,
  isRvFitsRateSize: undefined,
  classErrors: undefined,
  onSubmit: undefined,
  rigLength: null,
  rigWidth: null,
}

SizeFilter.propTypes = {
  checkRatesAvailability: PropTypes.func,
  isRvFitsRateSize: PropTypes.bool,
  classErrors: PropTypes.string,
  onSubmit: PropTypes.func,
  rigLength: PropTypes.number,
  rigSizeModal: PropTypes.bool.isRequired,
  rigWidth: PropTypes.number,
  validations: ValidationsPropTypes.isRequired,
}

const mapStateToProps = state => ({
  rigWidth: state.search.rigWidth,
  rigLength: state.search.rigLength,
  rigSizeModal: state.search.rigSizeModal,
})

export default storeConnect({ mapStateToProps })(SizeFilter)
