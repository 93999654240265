import PropTypes from 'prop-types'
import { Button, Modal, ModalBody } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import storeConnect from 'Checkout/Redux/Connect'
import useSiteLock from 'Checkout/Hooks/useSiteLock'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'

const SiteLockModal = ({ setSiteLockModal, siteLockFee, siteLockModal, useInSites }) => {
  const { selectSiteLock } = useSiteLock({ useInSites })

  const search = {
    title: 'Would you like to select your site?',
    subtitle: `For ${siteLockFee.display} more, you can select the exact site you'll be staying at for
               your reservation.`,
    chooseSiteText: 'Yes, I\'ll Choose My Site',
    skipSiteText: 'No, I\'ll let the campground choose.',
  }

  const sites = {
    title: 'Would you like to guarantee the site?',
    subtitle: `You can guarantee this site for ${siteLockFee.display}`,
    chooseSiteText: `Guarantee this site for ${siteLockFee.display}`,
    skipSiteText: 'Allow campground to assign',
  }

  const pageTexts = useInSites ? sites : search
  const { chooseSiteText, skipSiteText, subtitle, title } = pageTexts

  const toggleModal = () => setSiteLockModal(!siteLockModal)

  return (
    <Modal id="site-lock-modal" className="modal-dialog-centered" isOpen={siteLockModal} toggle={toggleModal}>
      <ModalBody className="my-4 px-5 py-2">
        <div className="text-center">
          <h5 className="fw-bold mb-2">{title}</h5>

          <p className="text-gray">
            {subtitle}
          </p>
        </div>

        <div className="d-grid gap-2">
          <Button className="fw-bold" color="primary"
                  onClick={() => selectSiteLock(true)}>
            {useInSites && <FontAwesomeIcon className="me-2" icon="lock" color="#fff" size="lg" />}
            {chooseSiteText}
          </Button>

          <Button color="secondary" onClick={() => selectSiteLock(false)}>
            {skipSiteText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

SiteLockModal.defaultProps = {
  useInSites: false,
}

SiteLockModal.propTypes = {
  setSiteLockModal: PropTypes.func.isRequired,
  siteLockFee: PropTypes.shape({
    display: PropTypes.string,
  }).isRequired,
  siteLockModal: PropTypes.bool.isRequired,
  useInSites: PropTypes.bool,
}

const mapDispatchToProps = dispatch => ({
  setSiteLockModal: siteLockModal => dispatch(searchFilterUpdate({ siteLockModal })),
})

const mapStateToProps = state => ({
  siteLockFee: state.campground.siteLockFee,
  siteLockModal: state.search.siteLockModal,
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(SiteLockModal)
