import { useState, useEffect } from 'react'

const useSiteTypeIcon = siteType => {
  const [icon, setIcon] = useState('')

  const getIcon = type => import(`Checkout/Images/Icons/${type}.svg`)

  useEffect(() => {
    let isMounted = true
    const loadIcon = async () => {
      const response = await getIcon(siteType)
      if (isMounted) setIcon(response.default)
    }

    if (siteType) loadIcon()

    return () => { isMounted = false }
  }, [siteType])

  return { icon }
}

export default useSiteTypeIcon
