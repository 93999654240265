import { useContext } from 'react'
import PropTypes from 'prop-types'
import storeConnect from 'Checkout/Redux/Connect'
import RoutingContext from 'Checkout/Components/Routing/RoutingContext'
import PageLoading from 'Shared/PageLoading/PageLoading'
import ReservationsDisabledPage from 'Shared/ReservationsDisabledPage/ReservationsDisabledPage'

const Layout = ({ children, isBookable }) => {
  const [routing] = useContext(RoutingContext)

  if (isBookable === null) return <PageLoading />

  if (!isBookable && !routing?.to?.showAlways) return <ReservationsDisabledPage />

  return (
    <main className="layout d-flex flex-column flex-fill">
      {children}
    </main>
  )
}

Layout.defaultProps = {
  isBookable: null,
}

Layout.propTypes = {
  isBookable: PropTypes.bool,
}

const mapStateToProps = state => ({
  isBookable: state.campground.isBookable,
})

export default storeConnect({ mapStateToProps })(Layout)
