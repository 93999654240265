import { gql } from 'Utils/apolloClient'

const ratesQuery = gql`
  query CampgroundRatesAvailable ($filters: ValidationsFiltersAttributes, $from: ISO8601Date!,
                                  $to: ISO8601Date!, $slug: String!) {
    campground(slug: $slug, from: $from, to: $to, filters: $filters) {
      id
      ratesAvailableDuring {
        id
        name
        amenities
        canAddVehicle
        description
        maxLength
        maxWidth
        minNightsRequired
        nightPrice {
          amount
          display
          displayWithCode
        }
        pictureUrl
        accessibility
        rigRequired
        totalNights
        totalPrice {
          amount
          display
        }
      }
    }
  }
`

export default ratesQuery
