import { useContext, useMemo } from 'react'
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import queryString from 'query-string'
import RoutingContext from 'Checkout/Components/Routing/RoutingContext'

const useRouter = () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const params = useParams()
  const [routing] = useContext(RoutingContext)

  /**
   * Goes to a specific pathaname passing query parameters through the history.push event
   * @param {(string|Object)} args -  pathname or object with a pathname
   * @param {Object} [args.search] - query parameters to keep
   */
  const go = args => {
    const isPathnameObject = typeof args === 'object'
    const currentParams = _getSearchParams()
    const params = isPathnameObject ? { ...currentParams, ...args.search } : currentParams
    const query = _mergeQueryParams(params)
    const to = isPathnameObject ? { ...args, search: query } : { pathname: args, search: query }

    /* This setTimeout with 0 milliseconds sends the history push to the end of the event loop to make sure it is
     * executed after a backward redirect
     */
    setTimeout(() => history.push(to), 0)
  }

  /**
   * Replaces the query parameters in the same path
   * @param {Object} search - query params to replace
   */
  const replaceQuery = search => {
    const currentParams = _getSearchParams()
    const params = { ...currentParams, ...search }
    const query = _mergeQueryParams(params)

    history.replace({ search: query })
  }

  const router = useMemo(() => ({
    // data
    history: { ...history, from: routing.from, to: routing.to },
    location,
    match,
    pathname: location.pathname,
    query: { ...queryString.parse(location.search), ...params },
    // functions
    back: routing.back,
    go,
    next: routing.next,
    push: history.push,
    replace: history.replace,
    replaceQuery,
  }), [history, location, match, params, routing])

  // private
  const _getSearchParams = () => queryString.parse(location.search)

  const _mergeQueryParams = params => queryString.stringify(params, { skipEmptyString: true })

  return router
}

export default useRouter
