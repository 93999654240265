import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import ratesAvailabilityAlertsQuery from './ratesAvailabilityAlertsQuery'
import { buildAlerts } from 'Checkout/Utils/alerts'

const useRatesAvailabilityAlerts = () => {
  const campgroundSlug = useSelector(state => state.campground.slug)
  const { checkIn, checkOut, dayUse, siteType } = useSelector(state => state.search)

  const [alerts, setAlerts] = useState([])
  const [getAvailabilityAlerts, { data, error }] = useLazyQueryNoCache(ratesAvailabilityAlertsQuery)

  useEffect(() => {
    if (campgroundSlug && checkIn && checkOut) {
      const variables = { from: checkIn, slug: campgroundSlug, to: checkOut, filters: filters() }
      getAvailabilityAlerts({ variables })
    }
  }, [campgroundSlug, checkIn, checkOut])

  useEffect(() => {
    if (data && !error) {
      const { daysBookingTogether, daysRequiredByNbt, ratesAvailableDuring, unavailableDueToHolidays,
              unavailableRatesNbtRequiredCount, unavailableRatesMinNightsCount } = data.campground
      const alerts = buildAlerts({ checkIn, checkOut, daysBookingTogether, daysRequiredByNbt, ratesAvailableDuring,
                                   unavailableDueToHolidays, unavailableRatesNbtRequiredCount,
                                   unavailableRatesMinNightsCount })

      setAlerts(alerts)
    }
  }, [data])

  const filters = () => {
    const filter = { dayUse }
    if (!siteType) return filter

    return { ...filter, types: [siteType.type] }
  }

  return alerts
}

export default useRatesAvailabilityAlerts
