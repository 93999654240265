import * as Yup from 'yup'

const SizeFilterViewSchema = Yup.object().shape({
  rigLength: Yup.number()
    .when('rigSizeModal', {
      is: rigSizeModal => rigSizeModal,
      then: Yup.number()
        .moreThan(0, 'RV length required'),
    }),

  rigWidth: Yup.number()
    .when('rigSizeModal', {
      is: rigSizeModal => rigSizeModal,
      then: Yup.number()
        .moreThan(0, 'RV width required'),
    }),
})

export default SizeFilterViewSchema
