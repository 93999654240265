import PropTypes from 'prop-types'
import ReservationDetailItem from 'Shared/ReservationDetailItem'
import { amountCurrencyField } from 'Utils/Currency'

const SITE_LOCK_FEE = 'Site Lock Fee'

const ReservationAddons = ({ className, addons, sitesMapUrl }) => (
  <div className={className}>
    {addons.map(({ base, itemableType, name, units }, i) => {
      const showAmount = ['extra_fee', 'product'].includes(itemableType)
      const amount = showAmount && units
      const icon = SITE_LOCK_FEE === name && sitesMapUrl ? 'lock' : null
      const showFreeLabel = showAmount && amountCurrencyField(base) === 0
      const price = showFreeLabel ? 'FREE' : base?.display

      return (
        <ReservationDetailItem amount={amount} amountClass="fw-bold me-4" key={name} hideColon
                               icon={icon} itemNameClass="text-truncate fw-bold" itemName={name}
                               idTooltipName={`addon-${i}`} value={price} valueClass="text-gray" />
      )
    })}
  </div>
)

ReservationAddons.defaultProps = {
  className: 'my-4',
  sitesMapUrl: '',
}

ReservationAddons.propTypes = {
  className: PropTypes.string,
  addons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    quantity: PropTypes.number,
  })).isRequired,
  sitesMapUrl: PropTypes.string,
}

export default ReservationAddons
