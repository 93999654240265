import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'reactstrap'
import { detailsSetData } from 'Checkout/Redux/Actions/Details'
import storeConnect from 'Checkout/Redux/Connect'
import { inputMaxNumber } from 'Shared/Formik/Utils'
import ScaleInput from 'Checkout/Components/Details/ScaleInput/ScaleInput'
import './Product.scss'

const Product = ({ product, productsPurchased, setFieldValue, setProducts }) => {
  const { id: productId, name: productName, price: productPrice, priceDescription, onlineMax,
          onlineRequired } = product
  const [productValue, setProductValue] = useState(0)

  const scaleInputClasses = { button: 'rounded', input: 'bg-transparent border-0' }

  useEffect(() => {
    const currentProductValue = getCurrentProductValue()

    if (currentProductValue !== productValue) setProductValue(currentProductValue)
    setFieldValue('productsPurchased', productsPurchased)
  }, [productsPurchased])

  const minimumProductValue = onlineRequired ? 1 : 0

  const getCurrentProductValue = () =>
    productsPurchased.filter(product => product.id === parseInt(productId))[0]?.amount || minimumProductValue

  const setQuantityProduct = (id, amount) => {
    const updatedproductsPurchased = updateProductsStock({ currentProduct: { id: parseInt(id), amount } })

    setProducts({ productsPurchased: updatedproductsPurchased })
  }

  const updateProductsStock = ({ currentProduct }) => {
    const selectedProductIndex = productsPurchased.findIndex(item => item.id === parseInt(productId))
    const itemExists = selectedProductIndex > -1

    if (itemExists) {
      const newItemsArray = [...productsPurchased]

      newItemsArray[selectedProductIndex] = currentProduct
      return newItemsArray
    }
    return [...productsPurchased, currentProduct]
  }

  return (
    <Col xl={6}>
      <div className="d-flex flex-sm-row flex-column p-2 product">
        <div className="d-flex flex-grow-1 flex-column mb-3 mb-sm-0 align-items-center align-items-sm-start
                        justify-content-center">
          <label className="m-0" htmlFor={productId}>{productName}</label>

          {onlineMax && (<p className="text-muted m-0">Max: {onlineMax}</p>)}
        </div>

        <div className="d-flex flex-column align-items-center">
          {productPrice?.amount > 0 && (
            <div className="mb-1">
              <span>{`${productPrice.display} / ${priceDescription}`}</span>
            </div>
          )}

          <ScaleInput classes={scaleInputClasses} enabled inputId={productId} inputName={productName}
                      min={minimumProductValue} max={onlineMax || inputMaxNumber} needsLowerCase
                      setCountsFor={setQuantityProduct} value={productValue} width={140} />
        </div>
      </div>
    </Col>
  )
}

Product.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.shape({
      amount: PropTypes.number,
      display: PropTypes.string,
    }),
  }).isRequired,
  productsPurchased: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  setProducts: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  products: state.details.products,
  productsPurchased: state.details.productsPurchased,
})

const mapDispatchToProps = dispatch => ({
  setProducts: data => dispatch(detailsSetData(data)),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(Product)
