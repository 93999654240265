import { fillArrayWithMinToMax } from 'Utils/Arrays'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'

const RatingWithStars = ({ className, rating }) => (
  <div className={className}>
    <div>
      {fillArrayWithMinToMax({ max: 5, min: 1 }).map(index => {
        let starIcon = ['far', 'star']

        if (index <= rating)
          starIcon = 'star'
        else if (rating >= index - 0.5)
          starIcon = ['far', 'star-half-stroke']

        return (
          <FontAwesomeIcon key={`star-${index}`} icon={starIcon} />
        )
      })}
    </div>

    <p className="mb-0 text-center text-smaller-2 w-100">{rating}</p>
  </div>
  )

export default RatingWithStars
