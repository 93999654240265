import { useEffect } from 'react'
import storeConnect from 'Checkout/Redux/Connect'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import Discount from 'Shared/Discount/Discount'

const DiscountWrap = ({ campgroundSlug, formikValues, from, rate, to, setPaymentData }) => {
  useEffect(() => {
    const { discount } = formikValues

    setPaymentData({ discount, isNonRefundable: discount.clubName === 'Non-Refundable' })
  }, [formikValues.discount.id])

  return (
    <Discount {...{ campgroundSlug, from, rate, to }} isFloating layoutColumn={{ first: 12, second: 12 }}
              numberRequired={formikValues.discount.numberRequired} />
  )
}

const mapStateToProps = state => ({
  campgroundSlug: state.campground.slug,
  from: state.search.checkIn,
  rate: state.search.rate,
  to: state.search.checkOut,
})

const mapDispatchToProps = dispatch => ({
  setPaymentData: data => dispatch(paymentSetData(data)),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(DiscountWrap)
