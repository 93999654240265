import classNames from 'classnames'
import storeConnect from 'Checkout/Redux/Connect'
import ReservationDetailItem from 'Shared/ReservationDetailItem'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import { formatDate } from 'Utils/Dates'
import { autoAssignLabel } from 'Utils/siteHelpers'
import { guestsAddedToString } from 'Utils/guestHelpers'

const ReservationInfo = ({ dayUse, className, guests, isAutoAssign, isInventory, rate,
                           rigLength, rigWidth, site, siteLockPurchased, siteType, showSite, useGuests }) => {
  const { checkIn, checkOut, validStayDates } = useStayDates()
  const parseDate = date => formatDate(date, { format: 'MM/DD/YY' })
  const defaultClasses = classNames('reservation-details--information', className)
  const guestsLabel = guestsAddedToString(guests, useGuests)
  const isAutoAssignSelected = isAutoAssign && !siteLockPurchased
  const dates = validStayDates && dayUse ? parseDate(checkOut) : `${parseDate(checkIn)} - ${parseDate(checkOut)}`
  const datesLabel = dayUse ? 'Date' : 'Dates'
  const siteTypeLabel = dayUse ? 'Type' : 'Site Type'

  return (
    <div className={defaultClasses}>

      <ReservationDetailItem itemName={datesLabel} value={dates} valueClass="text-brownie" />

      {guestsLabel && (
        <ReservationDetailItem itemName="Guests" value={guestsLabel} valueClass="text-brownie text-capitalize" />
      )}

      {siteType?.name && (
        <ReservationDetailItem itemName={siteTypeLabel} value={`${siteType.name} ${rate ? ` - ${rate.name}` : ''}`}
                               valueClass="text-brownie" />
      )}

      {(showSite && isInventory && !dayUse) && (
        <ReservationDetailItem itemName="Site" value={autoAssignLabel(isAutoAssignSelected, site?.name)}
                               valueClass="text-brownie" />
      )}

      {rate && rate.rigRequired && rigLength && rigWidth && (
        <ReservationDetailItem itemName="Rig Size" value={`${rigLength} ft. x ${rigWidth} ft.`}
                               valueClass="text-brownie" />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  dayUse: state.search.dayUse,
  guests: state.details.guests,
  isAutoAssign: state.campground.isAutoAssign,
  isInventory: state.campground.isInventory,
  siteLockPurchased: state.payment.isSiteLock,
  rate: state.search.rate,
  rigLength: state.search.rigLength,
  rigWidth: state.search.rigWidth,
  site: state.search.site,
  siteType: state.search.siteType,
  useGuests: state.details.useGuests,
})

export default storeConnect({ mapStateToProps })(ReservationInfo)
