import cnames from 'classnames'
import { AddTripProtection } from 'Checkout/Components/Shared/TripInsurance'
import AddressInformation from 'Checkout/Components/Shared/TripInsurance/AddressInformation/AddressInformation'

const TripInsuranceContainer = ({ children, loading, setIsTripProtection, isTripProtection,
                                  tripProtectionClassName, tripProtectionQuote }) => {
  const classNameContainer = cnames(tripProtectionClassName, { 'skeleton-loading': loading })

  if (!tripProtectionQuote) return null

  return (
    <>
      <AddTripProtection className={classNameContainer} price={tripProtectionQuote}
                         setIsTripProtection={setIsTripProtection}>
        {children}
      </AddTripProtection>

      {isTripProtection && <AddressInformation />}
    </>
  )
}

export default TripInsuranceContainer
