const initialState = {
  alerts: [],
  availability: {
    isAvailable: true,
    message: null,
  },
  displayRigFilter: false,
  hasValidations: false,
  siteTypes: [],
  // NOTE: using the same values than defined in `app/models/equipment.rb`
  validations: { maxLength: 50, minLength: 10, maxWidth: 70, minWidth: 5 },
}

export default initialState
