import { gql } from 'Utils/apolloClient'

const validationsQuery = gql`
  query GuestRigValidations ($checkArchived: Boolean, $filters: ValidationsFiltersAttributes, $from: ISO8601Date!,
                             $rateId: ID!, $siteId: ID, $siteType: String, $slug: String!, $to: ISO8601Date!) {
    campground(from: $from, slug: $slug, to: $to) {
      validations(checkArchived: $checkArchived, filters: $filters, rateId: $rateId, siteId: $siteId,
                  siteType: $siteType) {
        id
        areGuestsRequired
        guests {
          additionalNotes
          adults{
            ageRange
            max
            min
          }
          children {
            ageRange
            max
            min
          }
          maxPeople
          extraVehicles {
            max
            min
          }
          pets {
            max
            min
          }
          useGuests
        }
        rig {
          length {
            max
            min
          }
          slideouts {
            max
            min
          }
          type
          year {
            max
            min
          }
          width {
            max
            min
          }
        }
      }
    }
  }
`

export default validationsQuery
