import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cnames from 'classnames'
import { formatDate } from 'Utils/Dates'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import pricingQuery from './PricingQuery'
import storeConnect from 'Checkout/Redux/Connect'
import ReservationAddons from 'Checkout/Components/ReservationDetails/ReservationAddons'
import ReservationDetailItem from 'Shared/ReservationDetailItem'
import TaxDetails from 'Shared/TaxDetails'
import { skeletonPrice } from './skeletonPrice.js'
import 'Shared/Skeleton/Skeleton.scss'

const ReservationTotal = ({ guests, marketplace, payment, productsPurchased, rate, rigLength, rigWidth,
                            setPricing, site, sitesMapUrl, siteType, slug }) => {
  const history = useHistory()
  const [getPrice, { data: dataPrice, loading: priceLoading,
                     called: isPriceFetched, error: errorPrice }] = useLazyQueryNoCache(pricingQuery)
  const { checkIn, checkOut } = useStayDates()
  const { adults, children, pets, vehicles } = guests
  const loading = !isPriceFetched || priceLoading
  const currentPrice = loading ? skeletonPrice : payment
  const { discount, isNonRefundable, isSiteLock, isTripProtection } = payment
  const { camperFee, camperProcessingFee, camperTotal, dueNow, dueUponCheckIn, isPurchaseComplete, isTaxIncluded,
          items, nonRefundableAmount, subtotal, tax, taxConfig, tripProtectionQuote } = currentPrice
  const containerClasses = cnames('mb-5 mb-lg-0', { 'skeleton-loading': loading })
  const discountId = discount?.id
  const feeLabel = marketplace ? 'Service Fee' : 'Platform Fee'
  const filters = { maxLength: rigLength, maxWidth: rigWidth, type: siteType?.type }

  const fetchPrice = () => {
    const extras = { discountId, filters, guests, isSiteLock, marketplace, nonRefundable: isNonRefundable,
                     productsPurchased, siteId: site?.id, hasTripInsurance: isTripProtection }
    const variables = { from: formatDate(checkIn), rateId: rate?.id, slug, to: formatDate(checkOut), extras }
    const pricingQueryArgs = { variables }

    getPrice(pricingQueryArgs)
  }

  useEffect(() => {
    if (rate && !isPurchaseComplete) fetchPrice()
  }, [adults, checkIn, checkOut, children, discountId, isNonRefundable, pets, productsPurchased, rate,
      isTripProtection, vehicles])

  useEffect(() => {
    if (dataPrice && !errorPrice) {
      const { calculatePricing } = dataPrice.campground.rate
      setPricing(calculatePricing)
    }
  }, [dataPrice])

  useEffect(() => {
    const message = errorPrice?.graphQLErrors[0].message
    const regex = new RegExp('NBT Errors')

    if (regex.test(message))
      history.push(`/search?check_in=${formatDate(checkIn)}&check_out=${formatDate(checkOut)}`)
  }, [errorPrice])

  return (
    <div className={containerClasses}>
      <ReservationAddons addons={items} className="reservation-details--addons my-4" sitesMapUrl={sitesMapUrl} />

      <div className="reservation-details--total text-gray">
        <ReservationDetailItem containerClass="mt-4" itemName="Subtotal" value={subtotal?.display}
                               valueClass="fw-bold" />

        {!isTaxIncluded && (
          <ReservationDetailItem itemName="Tax" value={tax} valueClass="fw-bold" />
        )}

        { camperProcessingFee?.amount > 0 && (
          <ReservationDetailItem itemName="Processing Fee" value={camperProcessingFee} valueClass="fw-bold" />
        )}

        <ReservationDetailItem itemName={feeLabel} value={camperFee?.display} valueClass="fw-bold" />

        {isTripProtection && tripProtectionQuote && (
          <ReservationDetailItem itemName="Trip Protection" value={tripProtectionQuote} valueClass="fw-bold" />
        )}

        <ReservationDetailItem containerClass="mt-4" itemName="Reservation Total"
                               itemNameClass="col-6 align-text-bottom" value={camperTotal.displayWithCode}
                               valueClass="h4 text-green pe-0 text-end" />

        <hr />

        <ReservationDetailItem containerClass="mt-4" itemName="Due" value={dueNow?.display} valueClass="fw-bold" />

        {dueUponCheckIn?.amount > 0 && (
          <ReservationDetailItem itemName="Remaining Balance" value={dueUponCheckIn?.display}
                                 valueClass="fw-bold" />
        )}

        {isTaxIncluded && (
          <TaxDetails nameClass="fw-bold" tax={tax} taxName={taxConfig?.taxName} valueClass="fw-bold" />
        )}

        {nonRefundableAmount?.amount > 0 && (
          <>
            <hr />

            <p>
              <small className="text-muted fst-italic">
                {nonRefundableAmount?.display} of this reservation is non-refundable per the RoverPass
                Cancellation policy. Check the campground cancellation policy for any other non-refundable amounts.
              </small>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  checkIn: state.search.checkIn,
  checkOut: state.search.checkOut,
  guests: state.details.guests,
  marketplace: state.campground.marketplace.isMarketplace,
  payment: state.payment,
  productsPurchased: state.details.productsPurchased,
  rate: state.search.rate,
  rigLength: state.search.rigLength,
  rigWidth: state.search.rigWidth,
  site: state.search.site,
  sitesMapUrl: state.campground.sitesMapUrl,
  sitetype: state.search.sitetype,
  slug: state.campground.slug,
})

const mapDispatchToProps = dispatch => ({
  setPricing: pricing => dispatch(paymentSetData(pricing)),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(ReservationTotal)
