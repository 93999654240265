import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import AmenityIcon from 'Checkout/Components/Shared/AmenityIcon/AmenityIcon'
import Anchor from 'Shared/Anchor/Anchor'
import Image from 'Shared/Image/Image'
import defaultPicture from 'Checkout/Images/photo-coming-soon-map.png'
import './MapSitesPopoverContent.scss'

const MapSitesPopoverContent = ({ onSelectSite, resetMapState, siteSelected }) => {
  if (!siteSelected) return null

  const { amenities, maxLength, maxPeople, maxWidth, name, pictureSizes: { mapUrl } } = siteSelected

  const onClickSelectSite = () => onSelectSite(siteSelected)

  const amenitiesList = amenities.map(amenity => (
    <li className="list-inline-item my-0 border-end d-inline-block" key={amenity}>
      <AmenityIcon amenity={amenity} />
    </li>
  ))

  return (
    <div className="row popover-content">
      <div className="col-5">
        <Image alt="Site" className="h-100 site-img w-100" defaultImage={defaultPicture} srcToLoad={mapUrl} />
      </div>

      <div className="col-6 py-2">
        <h4>{name}</h4>

        <Anchor className="text-gray popover-content__close position-absolute p-2 " href="#close-button"
                onClick={resetMapState}>
          <FontAwesomeIcon icon="xmark" size="lg" />
        </Anchor>

        <div className="d-flex flex-column h-75">
          {!!parseInt(maxWidth) && !!parseInt(maxLength) && (
            <p className="m-0">
              <FontAwesomeIcon icon="ruler-combined" /> {maxLength} ft. x {maxWidth} ft.
            </p>
          )}

          <p className="m-0 mt-2">
            <FontAwesomeIcon icon="users" /> {maxPeople} People Max
          </p>

          {!!amenities.length && (
            <>
              <hr className="w-100 my-2" />

              <ul className="list-inline my-2">
                {amenitiesList}
              </ul>
            </>
          )}

          <Button className="site-btn btn-selected-green shadow-sm rounded-pill px-5 fw-bold mt-auto"
                  onClick={onClickSelectSite}>
            Select
          </Button>
        </div>
      </div>
    </div>
  )
}

MapSitesPopoverContent.defaultProps = {
  siteSelected: null,
}

MapSitesPopoverContent.propTypes = {
  onSelectSite: PropTypes.func.isRequired,
  resetMapState: PropTypes.func.isRequired,
  siteSelected: PropTypes.shape({}),
}

export default MapSitesPopoverContent
