import CardCampground from './CardCampground'

const NearCampgrounds = ({ campgrounds }) => (
  <div className="mt-4 pt-3 px-lg-5">
    <p className="mb-3 text-center">Here are some available Campgrounds near the area you searched.</p>

    <div className="d-flex flex-wrap justify-content-between">
      {campgrounds.map(campground => (
        <CardCampground {...campground} key={campground.id} />
      ))}
    </div>
  </div>
)

export default NearCampgrounds
