import { useEffect, useState } from 'react'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import { useSelector } from 'react-redux'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import { escapeBlank, parsePrice, valuesToNumber } from 'Utils/Parse'
import { parsePhoneToObject } from 'Utils/PhoneHelpers'
import { formatDate } from 'Utils/Dates'
import useRouter from 'Checkout/Hooks/useRouter'
import createReservationMutation from './CreateReservationMutation'

const useReservationCreate = () => {
  const router = useRouter()
  const [createReservation, { data, error: errorData, loading }] = useMutation(createReservationMutation)
  const { checkIn, checkOut, validStayDates } = useStayDates()
  const { dayUse, marketplaceOverrideUuid, rate, rigLength, rigWidth, site } = useSelector(state => state.search)
  const { camperTotal, defaultPayment, discount, homeAddress, isNonRefundable,
          isTripProtection, tripInsurancePrice, dueNow } = useSelector(state => state.payment)
  const details = useSelector(state => state.details)
  const isSiteLock = useSelector(state => state.payment.isSiteLock)
  const { canUseNonRefundable, isAutoAssign, isRequest, marketplace,
          slug, systemType, utmData } = useSelector(state => state.campground)
  const [error, setError] = useState()

  useEffect(() => {
    if (errorData) {
      const { graphQLErrors } = errorData
      const errorsList = graphQLErrors.map(graphQLError => graphQLError.message)
      setError(errorsList[0])
    }
  }, [errorData])

  const buildHomeAddress = () => isTripProtection && { homeAddress: { ...homeAddress } }

  const buildUtm = () => utmData && { utm: { ...utmData } }

  const getCustomFieldsValues = objectField => {
    const fieldValues = Object.values(objectField)

    return fieldValues.map(({ id, value }) => ({ answer: value, customFieldId: id }))
                      .filter(({ answer }) => answer.length > 0)
  }

  useEffect(() => {
    const { reservation } = data?.createReservation || {}
    const isoDateFormat = 'MM-DD-YYYY'
    if (reservation) {
      const awindData = window.AWIN
      if (typeof awindData?.Tracking !== 'undefined') {
        awindData.Tracking.Sale = {}
        awindData.Tracking.Sale.amount = camperTotal.amount
        awindData.Tracking.Sale.channel = 'aw'
        awindData.Tracking.Sale.currency = reservation.pricing.currencyCode
        awindData.Tracking.Sale.custom = [slug, formatDate(checkIn, { format: isoDateFormat }),
                                          formatDate(checkOut, { format: isoDateFormat }), systemType,
                                          reservation.source]
        awindData.Tracking.Sale.orderRef = reservation.id
        awindData.Tracking.Sale.parts = `DEFAULT:${camperTotal.amount}`
        awindData.Tracking.run()
      }
    }
  }, [data])

  const buildReferringDomain = () => (
    marketplace?.referringDomain && { referringDomain: marketplace.referringDomain }
  )

  const buildMarketplaceOverrideUuid = () => (
    { marketplaceOverrideUuid: marketplaceOverrideUuid || router.query.d }
  )

  function buildVariables(card) {
    const isoDateFormat = 'YYYY-MM-DD'
    const { code: discountNumber, id: discountId } = discount
    const guestsNoNumerics = ['petBreeds', 'useGuests', 'vehiclesTowed']
    const paymentSchedule = { installments: [] } // TODO: use data sent from API
    const specialEvent = '' // TODO: Add special event

    const { customFields, email, firstName, guests, lastName, password, phone, productsPurchased,
            specialRequests, vehicle } = details

    const customFieldValues = getCustomFieldsValues(customFields)

    const reservationVariables = { campgroundId: slug, customFieldValues, discountId, discountNumber, email,
                                   firstName, from: formatDate(checkIn, { format: isoDateFormat }),
                                   guests: valuesToNumber(guests, { except: guestsNoNumerics }), isSiteLock,
                                   lastName, nonRefundable: canUseNonRefundable && isNonRefundable,
                                   password, paymentSchedule, phone: parsePhoneToObject(phone),
                                   productsPurchased, rateId: rate.id,
                                   specialEvent, specialRequest: specialRequests,
                                   to: formatDate(checkOut, { format: isoDateFormat }),
                                   hasTripInsurance: isTripProtection,
                                   tripInsurancePrice: parsePrice(tripInsurancePrice),
                                   totalWithTripInsurance: dueNow.display }

    return { ...reservationVariables, ...buildSite(), ...buildRig(vehicle), ...buildPaymentMethod(card),
             ...buildUtm(), ...buildHomeAddress(), ...buildMarketplaceOverrideUuid(), ...buildReferringDomain() }
  }

  function buildSite() {
    if (isRequest || (isAutoAssign && !isSiteLock) || dayUse) return null

    return { siteId: site.id }
  }

  function buildRig(vehicle) {
    if (!rate.canAddVehicle || !vehicle) return {}

    if (vehicle.id) return { rigId: vehicle.id }

    const rig = { type: escapeBlank(vehicle.type) }
    return { rig: { ...rig, ...valuesToNumber({ length: vehicle.length || rigLength, slideouts: vehicle.slideouts,
                                                width: vehicle.width || rigWidth, year: vehicle.year }) } }
  }

  function buildPaymentMethod(card) {
    if (card.id)
      return { paymentMethodId: card.id }

    return { paymentMethod: { isDefaultSource: defaultPayment, stripeToken: escapeBlank(card.token) } }
  }

  function createReservationMethod(card) {
    if (validStayDates) {
      createReservation({ variables: buildVariables(card) })
      setError(null)
    } else setError('Missing data, please edit the reservation and ensure everything is right.')
  }

  return [createReservationMethod, data, error, loading]
}

export default useReservationCreate
