import { gql } from 'Utils/apolloClient'

const siteTypesQuery = gql`
  query SitePlansData($filters: ValidationsFiltersAttributes, $from: ISO8601Date!, $slug: String!,
                      $to: ISO8601Date!) {
    campground(filters: $filters, from: $from, slug: $slug, to: $to) {
      id
      alerts
      availability {
        isAvailable
        message
      }
      sitesCountByTypeDuring
      unavailableSitesTypesCount
    }
  }`

export default siteTypesQuery
