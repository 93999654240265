import initialState from './RvValidationsInitialState'
import { buildAlerts } from 'Checkout/Utils/alerts'

const rvValidationsReducer = (currentState = {}, action) => {
  const handler = rvValidationsHandlers[action.type]
  if (handler) return (handler(currentState, action))

  return currentState
}

const resetRvValidations = state => {
  const { alerts, availability, hasValidations, siteTypes, validations } = initialState

  return ({ ...state, alerts, availability, hasValidations, siteTypes, validations })
}

const updateHasValidations = (state, { payload }) => ({ ...state, hasValidations: payload })

const updateRvValidations = (state, { payload }) => {
  const { availability, checkIn, checkOut, daysBookingTogether, daysRequiredByNbt, ratesAvailableDuring,
          sitesCountByTypeDuring, unavailableDueToHolidays, unavailableRatesMinNightsCount,
          unavailableRatesNbtRequiredCount, validations: { isRigRequired, rig } } = payload
  const { length, width } = rig

  const alerts = buildAlerts({ checkIn, checkOut, daysBookingTogether, daysRequiredByNbt, ratesAvailableDuring,
                               unavailableDueToHolidays, unavailableRatesNbtRequiredCount,
                               unavailableRatesMinNightsCount })

  const validations = { maxLength: length.max, minLength: length.min, maxWidth: width.max, minWidth: width.min }

  return ({
    ...state,
    alerts,
    availability,
    displayRigFilter: isRigRequired,
    hasValidations: true,
    siteTypes: sitesCountByTypeDuring,
    validations,
  })
}

const rvValidationsHandlers = {
  RESET_RV_VALIDATIONS: resetRvValidations,
  UPDATE_HAS_VALIDATIONS: updateHasValidations,
  UPDATE_RV_VALIDATIONS: updateRvValidations,
}

export default rvValidationsReducer
