export const routesHandler = {
  home: {
    key: 'home',
    name: 'Home',
    path: '/',
  },
  search: {
    key: 'search',
    name: 'Search',
    path: '/search',
  },
  sites: {
    key: 'sites',
    name: 'Sites',
    path: '/search/sites',
  },
  checkout: {
    key: 'checkout',
    name: 'Details',
    path: '/checkout',
  },
  payment: {
    key: 'payment',
    name: 'Payment',
    path: '/checkout/payment',
  },
  confirmation: {
    key: 'confirmation',
    name: 'Confirmation',
    path: '/confirmation/:uuid',
  },
}
