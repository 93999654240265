import { gql } from 'Utils/apolloClient'

const createReservationMutation = gql`
  mutation CreateReservation($address: HomeAddressAttributes, $campgroundId: ID!,
                             $customFieldValues: [CustomFieldValuesAttributes!], $discountId: ID,
                             $discountNumber: String, $email: String, $firstName: String!, $from: ISO8601Date!,
                             $guests: GuestsAttributes, $hasTripInsurance: Boolean,
                             $homeAddress: HomeAddressAttributes, $lastName: String!,
                             $marketplaceOverrideUuid: String, $nonRefundable: Boolean,
                             $password: String, $paymentMethod: PaymentMethodAttributes, $paymentMethodId: ID,
                             $paymentSchedule: InstallmentScheduleAttributes!,
                             $phone: PhoneAttributes!, $rateId: ID!, $referringDomain: String,
                             $rig: RigAttributes, $rigId: ID, $isSiteLock: Boolean, $specialEvent: String,
                             $specialRequest: String, $siteId: ID, $to: ISO8601Date!,
                             $productsPurchased: [AddonAttributes!], $tripInsurancePrice: Float,
                             $totalWithTripInsurance: String, $utm: UtmAttributes) {

    createReservation(input: { campgroundId: $campgroundId, paymentMethod: $paymentMethod,
                               paymentMethodId: $paymentMethodId,
                               reservation: { address: $address, customFieldValues: $customFieldValues,
                                 discountId: $discountId, discountNumber: $discountNumber, email: $email,
                                 firstName: $firstName, from: $from, guests: $guests,
                                 hasTripInsurance: $hasTripInsurance, homeAddress: $homeAddress,
                                 isSiteLock: $isSiteLock, lastName: $lastName,
                                 marketplaceOverrideUuid: $marketplaceOverrideUuid, nonRefundable: $nonRefundable,
                                 password: $password, paymentSchedule: $paymentSchedule,
                                 phone: $phone, productsPurchased: $productsPurchased,
                                 rateId: $rateId, rig: $rig, rigId: $rigId, referringDomain: $referringDomain,
                                 specialEvent: $specialEvent, specialRequest: $specialRequest, siteId: $siteId,
                                 to: $to, tripInsurancePrice: $tripInsurancePrice,
                                 totalWithTripInsurance: $totalWithTripInsurance, utm: $utm, }
                              }) {
      reservation {
        id
        uuid
        source
        pricing {
          currencyCode
        }
      }
    }
  }`

export default createReservationMutation
