import { Col, Row } from 'reactstrap'
import FormTextArea from 'Shared/Formik/FormTextArea'
import PageSubtitle from 'Shared/PageSubtitle'
import 'Styles/Text.scss'

const SpecialInformation = () =>
  <div className="mb-4">
    <PageSubtitle text="Special Requests" />
    <p> Let the campground know if you need anything else for your stay.</p>

    <Row className="g-0">
      <Col lg="8">
        <FormTextArea className="mb-0" inputClasses="h-auto" name="specialRequests"
                      placeholder="Enter in anything you may need" rows="5" showErrors />
      </Col>
    </Row>
  </div>

export default SpecialInformation
