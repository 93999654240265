import { gql } from 'Utils/apolloClient'

const rateQuery = gql`
  query CampgroundRate ($from: ISO8601Date, $rateId: ID, $slug: String!, $to: ISO8601Date) {
    campground(slug: $slug, from: $from, to: $to) {
      id
      canUseNonRefundable
      canUseSiteLock
      isAutoAssign
      isInstantBooking
      isRefundableFully
      rate(id: $rateId) {
        id
        canAddVehicle
        name
        maxLength
        maxWidth
        minNightsRequired
        nightPrice {
          amount
          display
          displayWithCode
        }
        pictureUrl
        rigRequired
        siteTypeName
      }
    }
  }
  `

  export default rateQuery
