import { useEffect } from 'react'

const useMetaTags = data => {
  const { title, meta } = data

  const setTitle = title => {
    document.title = title
  }

  const setMetatag = meta => {
    meta.forEach(tag => {
      const { name, property, content } = tag
      const key = name || property
      const metatag = document.getElementsByTagName('meta')[key]

      if (!metatag) return addMetatag(tag)

      document.getElementsByTagName('meta')[key].content = content
    })
  }

  const addMetatag = tag => {
    const metatag = document.createElement('meta')
    const attributes = Object.entries(tag)
    attributes.forEach(attr => metatag.setAttribute(attr[0], attr[1]))
    const metatags = document.getElementsByTagName('meta')
    const lastMetatag = metatags[metatags.length - 1]
    lastMetatag.after(metatag)
  }

  useEffect(() => {
    if (title) setTitle(title)
    if (meta) setMetatag(meta)
  }, [data])
}

export default useMetaTags
