import { forwardRef, memo } from 'react'
import storeConnect from 'Checkout/Redux/Connect'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import Site from './Site/Site'
import NoAvailabilitySites from 'Checkout/Components/Sites/NoAvailabilitySites'
import { skeletonSites } from './skeletonSites'

const SitesList = forwardRef(({ siteChosen, loading, sites, scrollToSiteMap }, ref) => {
  const sitesListContainer = cnames('sites-list row gap-4', { 'skeleton-loading': loading })
  const getSites = () => {
    if (loading) return skeletonSites

    return sites.sort(element => {
      if (element.id === siteChosen?.id) return -1
      return 1
    })
  }

  const finalSites = getSites()

  if (!finalSites?.length && !loading) return <NoAvailabilitySites />

  return (
    <>
      <div className="sites-list--header my-1">
        <p className="fw-bold">All Sites</p>
      </div>

      <div ref={ref} className={sitesListContainer}>
        {finalSites.map(site => <Site site={site} key={site.id} scrollToSiteMap={scrollToSiteMap} />)}
      </div>
    </>
  )
})

SitesList.displayName = 'SitesList'

SitesList.defaultProps = {
  loading: true,
  sites: [],
}

SitesList.propTypes = {
  loading: PropTypes.bool,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
}

const mapStateToProps = state => ({
  siteChosen: state.search.site,
})

export default memo(storeConnect({ mapStateToProps, options: { forwardRef: true } })(SitesList))
