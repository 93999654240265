import { useState, useEffect } from 'react'
import InitialState from 'Checkout/Redux/InitialState'
import FormGroupField from 'Shared/Formik/FormGroupField'
import RadioButton from 'Shared/RadioButton/RadioButton'

const tagClass = 'small tag d-none d-md-block px-2 ms-1 me-md-5 shadow-sm rounded text-white fw-bold bg-green-900'

const TripProtectionActions = ({ setIsTripProtection, price }) => (
  <FormGroupField check className="d-flex flex-column flex-md-row mb-3" name="tripInsurance">
    {({ field, form }) => {
      const [radioValue, setRadioValue] = useState('')
      const { setFieldValue, setValues, values } = form
      const isProtect = radioValue === 'yes'
      const labelAcceptTripProtection = `Yes, help protect my stay for ${price}`

      const onChange = ({ target }) => {
        setFieldValue('tripInsurance', target.value)
        setRadioValue(target.value)
      }

      useEffect(() => {
        setIsTripProtection(isProtect)
        if (!isProtect) setValues({ ...values, homeAddress: InitialState.payment.homeAddress })
      }, [radioValue])

      useEffect(() => {
        if (radioValue === '' && field.value !== '') {
            setRadioValue(field.value)
            setFieldValue(field.value)
        }
      }, [field.value])

      return (
        <>
          <div className="d-flex align-items-center">
            <RadioButton {...field} checked={isProtect} className="fw-bold text-blue me-1 me-md-1"
                        label={labelAcceptTripProtection} onChange={onChange} value="yes" />

            <span className={tagClass}>
              Recommended
            </span>
          </div>

          <RadioButton {...field} checked={radioValue === 'no'} className="text-blue"
                        label="No, I'll take my chances" onChange={onChange} value="no" />
        </>
      )
    }}
  </FormGroupField>
)

export default TripProtectionActions
