import { Col } from 'reactstrap'
import cnames from 'classnames'
import PropTypes from 'prop-types'

const SideColumn = ({ className, children, showOnMobile, ...props }) => {
  const classes = cnames('side-column flex-column', className, { 'd-flex': showOnMobile })

  return (
    <Col className={classes} {...props}>
      {children}
    </Col>
  )
}

SideColumn.defaultProps = {
  showOnMobile: false,
}

SideColumn.propTypes = {
  showOnMobile: PropTypes.bool,
}

export default SideColumn
