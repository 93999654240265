import { gql } from 'Utils/apolloClient'

const pricingQuery = gql`
  query Pricing($slug: String!, $rateId: ID, $from: ISO8601Date!, $to: ISO8601Date!,
                $extras: PricingExtrasAttributes) {
    campground(slug: $slug, from: $from, to: $to) {
      id
      rate(id: $rateId) {
        id
        calculatePricing(extras: $extras) {
          id
          camperFee {
            display
          }
          camperProcessingFee {
            amount
            display
          }
          camperTotal {
            amount
            cents
            display
            displayWithCode
          }
          dueNow {
            amount
            display
          }
          dueUponCheckIn {
            amount
            display
          }
          isTaxIncluded
          items {
            base {
              amount
              display
            }
            itemableType
            name
            units
          }
          nonRefundableDisclaimer
          subtotal {
            display
          }
          tax {
            display
          }
          taxConfig {
            isIncluded
            rate
            taxName
          }
          taxesAndFees
          totalRevenue
          tripInsurancePrice
        }
      }
    }
  }`

export default pricingQuery
