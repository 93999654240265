import { formatDate } from 'Utils/Dates'

const AwinConversion = ({ campgroundName, campgroundSlug, campgroundSystemType, reservation }) => {
  const { data, from, pricing, to } = reservation || {}
  const { amount } = pricing?.camperTotal || {}
  const startDate = formatDate(from, { format: 'MM-DD-YYYY' })
  const endDate = formatDate(to, { format: 'MM-DD-YYYY' })
  const imgUrl = `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=32065&amount=${amount}&ch=aw` +
                 `&parts=DEFAULT:${amount}&ref=${reservation.id}&cr=${pricing.currencyCode}&p1=${campgroundSlug}` +
                 `&p2=${startDate}&p3=${endDate}&p4=${campgroundSystemType}&p5=${data.source}`

  return (
    <>
      <img alt="awin" className="d-none border-0 w-0 h-0" src={imgUrl} />

      <form className="d-none" name="aw_basket_form">
        <textarea wrap="physical" id="aw_basket"
                  defaultValue={`AW:P|32065|${reservation.id}|${campgroundSlug}|${campgroundName}`} />
      </form>
    </>
  )
}

export default AwinConversion
