import { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import storeConnect from 'Checkout/Redux/Connect'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { isVehicleSelected } from 'Checkout/Utils/rvHelpers'
import Anchor from 'Shared/Anchor/Anchor'
import FloatingInternationalPhone from './FloatingInternationalPhone'
import FloatingLabelInput from 'Shared/FloatingLabelInput/FloatingLabelInput'
import FormCheckbox from 'Shared/Formik/FormCheckbox'
import PageSubtitle from 'Shared/PageSubtitle'
import 'Styles/Text.scss'

// TODO: Add campgroundName prop to use FormCheckbox
const ContactInformation = ({ camperId, defaultPhoneCountry, email, formikValues, sendMarketingPreference,
                              setSignInModal, signInModal }) => {
  const { allowMarketing, vehicle } = formikValues
  const toggleSignInModal = () => setSignInModal(!signInModal)

  useEffect(() => {
    if (camperId && isVehicleSelected(vehicle)) sendMarketingPreference({ ...formikValues })
  }, [allowMarketing, vehicle])

  return (
    <div className="mb-4">
      <PageSubtitle text="Contact Information" />
      {!camperId && (
        <div className="mb-3 text-smaller-1">
          <Anchor className="link-theme-primary" onClick={toggleSignInModal}>Sign in</Anchor>

          <span> to your RoverPass account for a quicker checkout.</span>
        </div>
      )}

      <Row>
        <Col md="6">
          <FloatingLabelInput className="mb-3" label="First Name" name="firstName" />
        </Col>

        <Col md="6">
          <FloatingLabelInput className="mb-3" label="Last Name" name="lastName" />
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FloatingInternationalPhone defaultCountry={defaultPhoneCountry} name="phone" />
        </Col>

        <Col md="6">
          <FloatingLabelInput className="mb-3" label="Email" name="email" textOnly={camperId} type="email"
                              value={email} />
        </Col>

        <Col>
          <FormCheckbox name="allowMarketing" message="Sign up for exclusive updates and offers" />
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = state => ({
  camperId: state.camper.id,
  // campgroundName: state.campground.name,
  email: state.camper.email,
  signInModal: state.search.signInModal,
})

const mapDispatchToProps = dispatch => ({
  setSignInModal: signInModal => dispatch(searchFilterUpdate({ signInModal })),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(ContactInformation)
