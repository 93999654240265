import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { formatDate } from 'Utils/Dates'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Tooltip from 'Shared/Tooltip'


const ReservationActions = ({ reservation }) => {
  const campgroundName = useSelector(state => state.campground.name)
  const parseDate = date => formatDate(date, { format: 'YYYYMMDD' })
  const printIt = () => window.print()
  const { from, to } = reservation
  const dates = `${parseDate(from)}/${parseDate(to)}`
  const title = `Your trip at ${campgroundName} `
  const addToCalendar = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${dates}`
  const reservationLink = reservation?.links?.accountReservationUrl

  return (
    <div className="d-flex">
      <div id="traveler-log-in" className="flex-grow-1">
        <a className="w-100 btn btn-dark-blue" href={reservationLink}>Traveler Log In</a>
      </div>

      <div>
        <a className="btn btn-outline-dark-blue fw-bold ms-3 text-dark" href={addToCalendar} id="add-calendar"
           rel="noreferrer" target="_blank">
          <FontAwesomeIcon icon="calendar-days" />
        </a>

        <Tooltip idTarget="add-calendar" placement="bottom" value="Save on Calendar" />

        <Button className="btn btn-outline-dark-blue ms-3 fw-bold text-dark" color="link" id="print-it"
                onClick={printIt}>
          <FontAwesomeIcon icon="print" />
        </Button>

        <Tooltip idTarget="print-it" placement="bottom" value="Print Page" />
      </div>
    </div>
  )
}

export default ReservationActions
