import { useEffect } from 'react'
import { useLazyQuery } from 'Shared/Hooks/useApolloClient'
import storeConnect from 'Checkout/Redux/Connect'
import useUnload from 'Checkout/Hooks/useUnload'
import productsQuery from './ProductsQuery'
import { setCheckoutStepViewed } from 'Checkout/Redux/Actions/TrackSegment'
import { skeletonProducts } from './skeletonProducts'
import ProductsInDetails from 'Checkout/Components/Shared/Products/ProductsInDetails/ProductsInDetails'
import ProductButtons from 'Checkout/Components/Products/ProductButtons/ProductButtons'
import ProductCards from 'Checkout/Components/Products/ProductCards'
import 'Checkout/Containers/Products/Products.scss'

const Products = ({ campgroundSlug, rate, setCheckoutStepViewed, setFieldValue, showProductsInDetails }) => {
  const [getProducts, { called, data, error, loading }] = useLazyQuery(productsQuery)

  useEffect(() => {
    setCheckoutStepViewed()
  }, [])

  useEffect(() => {
    if (campgroundSlug && rate) {
      const variables = { slug: campgroundSlug, rateId: rate.id, filters: { sources: ['online'] } }
      getProducts({ variables })
    }
  }, [campgroundSlug, rate])

  if ((called && !loading && !data) || error) return null

  const addons = data?.campground?.rate?.addons
  const addonsToShow = loading ? skeletonProducts : addons

  if (showProductsInDetails) return <ProductsInDetails loading={loading} products={addonsToShow}
                                                                         setFieldValue={setFieldValue} />

  useUnload()

  return (
    <div className="products h-100 d-flex flex-column justify-content-center">
      <h2 className="py-2 mx-md-4 text-dark-grey mb-0 fw-bold text-center">
        Enhance Your Stay
      </h2>

      <ProductCards />
      <ProductButtons />
    </div>
  )
}

const mapStateToProps = state => ({
  campgroundSlug: state.campground.slug,
  rate: state.search.rate,
})

const mapDispatchToProps = dispatch => ({
  setCheckoutStepViewed: () => dispatch(setCheckoutStepViewed(4)),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(Products)
