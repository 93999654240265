import { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import useWindowDimensions from 'Shared/Hooks/useWindowDimensions'
import Anchor from 'Shared/Anchor/Anchor'
import CloseModalButton from 'Shared/CloseModalButton'
import Link from 'Shared/Link/Link'
import RichText from 'Shared/RichText/RichText'
import RVezyLogo from 'Shared/Assets/rvezy_logo'
import './RVezyModal.scss'

const DESKTOP_MESSAGE = 'Search and book an RV rental with our partners at RVezy.<br />Drive it, tow it, or get it delivered to your campsite.'
const MOBILE_MESSAGE = 'Book an RV rental with our partners at RVezy.<br />Drive it, tow it, or get it delivered to your campsite.'

const RVezyModal = ({ openModalOnLoad, rvEzyUrl }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isDesktopView } = useWindowDimensions()

  const toggle = () => setIsOpen(prevState => !prevState)

  useEffect(() => {
    if (openModalOnLoad) setIsOpen(true)
  }, [openModalOnLoad])

  return (
    <Modal className="modal-dialog-centered modal-lg view-modal rvezy-modal" isOpen={isOpen}
           toggle={toggle}>
      <ModalBody className="m-0 p-0 d-lg-flex text-center">
        <div className="image-content me-lg-2" />

        <div className="ms-lg-3 text-content p-3">
          <div className="close-container d-none d-lg-flex justify-content-end">
            <CloseModalButton onClick={toggle} />
          </div>

          <img alt="RVezy logo" className="logo mb-3 mb-lg-5" src={RVezyLogo} />

          <h2 className="title fw-bold">Need an RV for your trip?</h2>

          <RichText className="subtitle mb-0 mb-lg-3" html={isDesktopView ? DESKTOP_MESSAGE : MOBILE_MESSAGE}
                    tag="p" />

          <Link className="btn btn-primary-rvezy my-3 my-lg-4 rounded-pill px-4 py-3 text-bigger-1" isExternal
                rel="noreferrer" target="_blank" to={rvEzyUrl}>
            Search RVs now
          </Link>

          <Anchor className="d-block no-thanks-link text-smaller-1" href="#no-thanks" onClick={toggle}>
            No thanks, I already have an RV.
          </Anchor>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default RVezyModal
