import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import storeConnect from 'Checkout/Redux/Connect'

const PaymentButton = ({ isCampgroundRequest }) => (
  <>
    <FontAwesomeIcon className="me-2" icon="lock" />
    {isCampgroundRequest ? 'Submit Request' : 'Confirm and Pay'}
  </>
)
const mapStateToProps = state => ({
  isCampgroundRequest: state.campground.isRequest,
})

export default storeConnect({ mapStateToProps })(PaymentButton)
