import cnames from 'classnames'
import storeConnect from 'Checkout/Redux/Connect'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import Anchor from 'Shared/Anchor/Anchor'
import Checkbox from 'Shared/Checkbox/Checkbox'
import PageSubtitle from 'Shared/PageSubtitle'
import { PaymentMethodIcons } from 'Shared/Stripe'
import SavedCards from '../SavedCards'
// import stripeLogo from '../Policies/stripe.png' # TODO: enable for showing our payment processor in Checkout
import './PaymentMethod.scss'

const PaymentMethod = ({ camperCards, children, defaultPayment, errors, selectedCard, setDefaultPayment,
                         setCardData }) => {
  const formCardClasses = cnames('card-form postion-relative mt-3 mt-md-0',
                                 { 'card-form-empty': !camperCards.length })

  const setDefaultPaymentMethod = e => {
    const { checked } = e.target
    setDefaultPayment(checked)
  }

  return (
    <div className="payment-method mb-4">
      <div className="payment-title d-flex justify-content-between align-items-center">
        <PageSubtitle text="Payment Method" />
        {!camperCards.length && <PaymentMethodIcons />}
      </div>

      {!!camperCards.length && <SavedCards cards={camperCards} errors={errors} />}

      {selectedCard && (
        <Anchor className="mt-4 d-block" onClick={() => setCardData({}, false)}>
          Add a different payment Method
        </Anchor>
      )}

      {children && (
        <div className={formCardClasses}>
          {!!camperCards.length && (
            <div className="fw-bold d-flex mb-1 mt-4 justify-content-between">
              Add a different payment Method
              <PaymentMethodIcons />
            </div>
          )}
          <div className="m-0">{children}</div>

          <div className="d-flex align-items-center justify-content-between mt-4">
            <Checkbox handleClick={setDefaultPaymentMethod} isChecked={defaultPayment}
                      message="Set as default payment method" name="set-as-default-payment" />
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  camperCards: state.camper.stripeCards,
  defaultPayment: state.payment.defaultPayment,
  selectedCard: state.payment.selectedCard,
})

const mapDispatchToProps = dispatch => ({
  setCardData: (card, selectedCard) => dispatch(paymentSetData({ card, selectedCard })),
  setDefaultPayment: data => dispatch(paymentSetData({ defaultPayment: data })),
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(PaymentMethod)
