import PropTypes from 'prop-types'
import { InputGroup, InputGroupText } from 'reactstrap'
import { fillArrayWithMaxToMin, fillArrayWithMinToMax } from 'Utils/Arrays'
import FormSelect from 'Shared/Formik/FormSelect'
import SelectOptions from 'Shared/SelectOptions/SelectOptions'
import rvIcon from 'Checkout/Images/Icons/type_rv_site.svg'

const AddRig = ({ rig, rigLength, rigWidth }) => {
  const { length, slideouts, type, year, width } = rig
  const formSelectInputClasses = 'floating-label-height type-select'
  const formContainerClass = 'mb-0 form-group'

  return (
    <div className="mt-3">
      <p className="fw-bold mb-2">Add Rig</p>

      <p>
        Important: Please include the approximate rig length, width
        and <strong>accurate</strong> slide out information.
      </p>

      {(!!rigLength || !!rigWidth) && (
        <p>
          Rig size entered:
          <span className="fw-bold text-bigger-1">
            {` ${rigLength || '--'} ft. x ${rigWidth || '--'} ft.`}
          </span>
        </p>
      )}

      <InputGroup className="mb-4">
        <InputGroupText className="rig-wrapper d-none d-lg-block">
          <img src={rvIcon} className="me-2" height="35" alt="RV Icon" />
        </InputGroupText>

        <FormSelect className={formContainerClass} defaultMessage="-- Year --" id="year"
                    inputClasses={formSelectInputClasses} name="vehicle.year">
          <SelectOptions data={fillArrayWithMaxToMin(year)} />
        </FormSelect>

        <FormSelect className={formContainerClass} defaultMessage="-- Type of Rig --" id="type"
                    inputClasses={formSelectInputClasses} name="vehicle.type">
          <SelectOptions data={type} />
        </FormSelect>

        {!rigLength && (
          <FormSelect className={formContainerClass} defaultMessage="-- Length --" id="length"
                      inputClasses={formSelectInputClasses} name="vehicle.length">
            <SelectOptions data={fillArrayWithMaxToMin(length)} />
          </FormSelect>
        )}

        {!rigWidth && (
          <FormSelect className={formContainerClass} defaultMessage="-- Width --" id="width"
                      inputClasses={formSelectInputClasses} name="vehicle.width">
            <SelectOptions data={fillArrayWithMaxToMin(width)} />
          </FormSelect>
        )}

        <FormSelect className={formContainerClass} defaultMessage="-- Slideouts --" id="slideouts"
                    inputClasses="floating-label-height slideouts-select" name="vehicle.slideouts">
          <SelectOptions data={fillArrayWithMinToMax(slideouts)} label="Slideout" />
        </FormSelect>
      </InputGroup>
    </div>
  )
}

AddRig.defaultProps = {
  rigLength: null,
  rigWidth: null,
}

AddRig.propTypes = {
  rigLength: PropTypes.number,
  rigWidth: PropTypes.number,
  rig: PropTypes.shape({
    length: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
    }),
    slideouts: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
    }),
    type: PropTypes.arrayOf(PropTypes.string),
    width: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
    }),
    year: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
    }),
  }).isRequired,
}

export default AddRig
