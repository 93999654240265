import { useEffect } from 'react'
import storeConnect from 'Checkout/Redux/Connect'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import VehicleCard from 'Shared/CamperVehicles/VehicleCard/VehicleCard'

const WrapVehicleCard = ({ handleSelect, isSelected, length, name, rigLength, rigWidth, setRigSize, width }) => {
  useEffect(() => {
    if (isSelected && (rigLength !== length || rigWidth !== width))
      setRigSize({ rigLength: length, rigWidth: width })
  }, [isSelected, rigLength, rigWidth])

  return (
    <VehicleCard {...{ handleSelect, isSelected, length, name, width }} />
  )
}

const mapStateToProps = state => ({
  rigLength: state.search.rigLength,
  rigWidth: state.search.rigWidth,
})

const mapDispatchToProps = dispatch => ({
  setRigSize: data => dispatch(searchFilterUpdate(data)),
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(WrapVehicleCard)
