import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCheckoutParams from './useCheckoutParams'
import useRouter from './useRouter'
import { detailsSetData } from 'Checkout/Redux/Actions/Details'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import { getSessionStorage } from 'Utils/SessionStorage'

const useReloadWithParams = props => {
  const { slug, isAutoAssign, isRequest } = useSelector(state => state.campground)
  const { guests } = useSelector(state => state.details)
  const { rate } = useSelector(state => state.search)
  const { isPurchaseComplete } = useSelector(state => state.payment)
  const { validateSearchParams, validateSiteParams } = useCheckoutParams()
  const dispatch = useDispatch()
  const router = useRouter()
  const ignoreSiteLock = true

  const goToSearch = () => {
    const search = { rate_id: '', rig_length: '', rig_width: '' }
    router.go({ pathname: '/search', search })
  }

  useLayoutEffect(() => {
    validateSearchParams()
  }, [])

  useLayoutEffect(() => {
    if (slug) {
      const selectedRate = getSessionStorage(`${slug}-selected-rate`)
      const site = getSessionStorage(`${slug}-site`)
      const siteLock = getSessionStorage(`${slug}-isSiteLock`)

      if (props?.checkDetails && guests.adults <= 0) {
        const detailsData = getSessionStorage(`${slug}-details-form`)

        if (!detailsData) return router.go('/checkout')

        dispatch(detailsSetData(detailsData))
      }

      if (site) dispatch(searchFilterUpdate(site))

      if (siteLock) dispatch(paymentSetData(siteLock))

      if (!selectedRate)
        if (isAutoAssign || isRequest || isPurchaseComplete) goToSearch()
        else router.go('/search/sites')
      else if (!rate) validateSiteParams(ignoreSiteLock)
    }
  }, [slug])
}

export default useReloadWithParams
