import { useDispatch, useSelector } from 'react-redux'
import { formatDate, today } from 'Utils/Dates'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import useRouter from 'Checkout/Hooks/useRouter'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { DatePickerSingle } from 'Shared/DatePicker'
import './DailyAccommodation.scss'

const DailyAccommodation = ({ checkIn, onlyInput }) => {
  const blockPastDays = day => day.isBefore(today())
  const closureDates = useSelector(state => state.campground.closeSeasonDates)
  const dispatch = useDispatch()
  const router = useRouter()
  const label = onlyInput ? null : 'Select Date'

  const updateDate = startDate => {
    const checkIn = formatDate(startDate)
    dispatch(searchFilterUpdate({ checkIn, checkOut: null }))
    router.replaceQuery({ check_in: checkIn })
  }

  return (
    <div className="daily-accommodation">
      {!onlyInput && (
        <p>
          <FontAwesomeIcon className="me-2" icon="sun" size="lg" />

          Daily Accommodations
        </p>
      )}

      <DatePickerSingle block className="col-lg-6" closureDates={closureDates} date={checkIn}
                        displayFormat="MM/DD/YYYY" id="checkIn" isOutsideRange={blockPastDays}
                        label={label} labelClassName="text-muted" onDateChange={updateDate}
                        placeholder="Select Date" showDefaultInputIcon textToLeft />
    </div>
  )
}

DailyAccommodation.defaultProps = {
  onlyInput: false,
}

export default DailyAccommodation
