import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { setCheckoutStepViewed } from 'Checkout/Redux/Actions/TrackSegment'
import storeConnect from 'Checkout/Redux/Connect'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import useCheckoutParams from 'Checkout/Hooks/useCheckoutParams'
import useDebounce from 'Checkout/Hooks/useDebounce'
import useMetaTags from 'Checkout/Hooks/useMetaTags'
import useRouter from 'Checkout/Hooks/useRouter'
import useRvValidations from 'Checkout/Hooks/useRvValidations/useRvValidations'
import useUnload from 'Checkout/Hooks/useUnload'
import { formatDate } from 'Utils/Dates'
import ratesQuery from './RatesQuery'
import Header from 'Checkout/Components/Shared/Header/Header'
import MainColumn from 'Checkout/Components/Shared/MainColumn/MainColumn'
import PageTitle from 'Checkout/Components/Shared/PageTitle/PageTitle'
import ProgressNav from 'Checkout/Components/Shared/ProgressNav/WrapperProgressNav'
import RateList from 'Checkout/Components/Search/RateList'
import ReservationDetails from 'Checkout/Components/Shared/ReservationDetails/ReservationDetails'
import RigSizeModal from 'Checkout/Components/Search/RigSizeModal/RigSizeModal'
import SearchAlerts from 'Checkout/Components/Search/SearchAlerts'
import Section from 'Checkout/Components/Shared/Section/Section'
import SideColumn from 'Checkout/Components/Shared/SideColumn/SideColumn'
import SiteFilters from 'Checkout/Components/Search/SiteFilters'
import SiteLockModal from 'Checkout/Components/Search/SiteLockModal/SiteLockModal'

const DEBOUNCED_MILLISECONDS = 300

const Search = ({ campgroundName, campgroundSlug, isPurchaseComplete, resetIsPurchaseComplete, search,
                  setCheckoutStepViewed, setSearch, siteLockFee }) => {
  const { validateSearchParams } = useCheckoutParams()
  const router = useRouter()
  const { rvValidationState, validRigSizes, loadingValidations } = useRvValidations()
  const { alerts, availability, displayRigFilter, hasValidations, siteTypes, validations } = rvValidationState
  const [ratesInCache, setRatesInCache] = useState([])
  const [searchLoading, setSearchLoading] = useState(true)
  const [getRates, { data: dataRates, loading: loadingRates, error: errorRates }] = useLazyQueryNoCache(ratesQuery)
  const { dayUse, filters, rigLength, rigWidth, siteType, rigSizeModal } = search
  const debouncedRigLength = useDebounce(rigLength, DEBOUNCED_MILLISECONDS)
  const debouncedRigWidth = useDebounce(rigWidth, DEBOUNCED_MILLISECONDS)
  const { checkIn, checkOut, validStayDates } = useStayDates()
  const metatags = { title: `${campgroundName} Booking | RoverPass` }
  const haveRatesInCache = ratesInCache.length
  const isRv = displayRigFilter && rigLength && rigWidth
  const loading = searchLoading || loadingRates

  useMetaTags(metatags)
  useUnload()

  const fetchRates = extraQueryArgs => {
    let campgroundFilters = { dayUse }
    if (siteType?.type) campgroundFilters = { ...campgroundFilters, types: [siteType.type] }

    if (extraQueryArgs)
      campgroundFilters = { ...campgroundFilters, ...extraQueryArgs }

    const queryVariables = { filters: campgroundFilters, from: formatDate(checkIn), slug: campgroundSlug,
                             to: formatDate(checkOut) }
    const ratesQueryArgs = { variables: queryVariables }
    getRates(ratesQueryArgs)
  }

  const amenitiesFilter = (filters, amenities) => filters.every(filter => amenities.includes(filter))

  const ratesWithFilters = rates => rates.filter(rate => amenitiesFilter(filters, rate.amenities))

  const ratesFiltered = useMemo(() => {
    const hasData = dataRates?.campground && !errorRates
    if (!hasData) return []

    const { campground: { ratesAvailableDuring: ratesAvailable } } = dataRates

    const haveFilters = !!filters.length

    if (!ratesAvailable) return []

    if (loadingValidations || loadingRates) return []

    if (!haveRatesInCache && !haveFilters && !rigLength && !rigWidth && !searchLoading) {
      setRatesInCache(ratesAvailable)
      return ratesAvailable
    }

    if (haveFilters) {
      if (rigLength && rigWidth && !rigSizeModal) {
        if (!validRigSizes) return []

        return ratesWithFilters(ratesAvailable)
      }

      if (haveRatesInCache) return ratesWithFilters(ratesInCache)

      return ratesWithFilters(ratesAvailable)
    }

    if (rigLength && rigWidth && !rigSizeModal) {
      if (!validRigSizes) return []

      return ratesAvailable
    }

    return ratesInCache
  }, [dataRates, debouncedRigLength, debouncedRigWidth, filters, loadingRates, loadingValidations])

  useLayoutEffect(() => {
    if (isPurchaseComplete) resetIsPurchaseComplete()

    const { d: marketplaceOverrideUuid, rig_length: rigLength, rig_width: rigWidth } = router.query
    const search = { marketplaceOverrideUuid, rate: null, rigLength: parseInt(rigLength) || null,
                     rigWidth: parseInt(rigWidth) || null, site: null }
    setSearch(search)
    validateSearchParams()
    setCheckoutStepViewed()
  }, [])

  useEffect(() => {
    if (validStayDates) {
      const rigSizeParams = isRv ? { rig_length: rigLength, rig_width: rigWidth } :
                                   { rig_length: '', rig_width: '' }

      router.replaceQuery({ ...rigSizeParams, site_type: siteType?.type })
    }
  }, [siteType, rigLength, rigWidth])

  useEffect(() => {
    if (validStayDates && haveRatesInCache) {
      setRatesInCache([])
      setSearchLoading(true)
      if (isRv) setSearch({ rigLength: null, rigWidth: null })
    }
  }, [checkIn, checkOut, siteType])

  useEffect(() => {
    if (campgroundSlug && validStayDates && hasValidations && !rigSizeModal && siteType) {
      const rigSize = { maxLength: rigLength, maxWidth: rigWidth }
      const rigQueryArgs = validRigSizes ? rigSize : null

      if (!haveRatesInCache || validRigSizes) {
        fetchRates(rigQueryArgs)
        setSearchLoading(false)
      }
    }
  }, [campgroundSlug, debouncedRigLength, debouncedRigWidth, hasValidations])

  return (
    <Section name="search">
      <MainColumn lg="7" xl="8">
        <Header />
        <PageTitle className="text-center" text="Search Availability" />
        <ProgressNav />
        <SiteFilters {...{ displayRigFilter, siteTypes, validations, searchLoading, loadingRates }}
                     loading={loadingValidations} />
        {validStayDates && alerts.length > 0 && !loading && <SearchAlerts alerts={alerts} />}
        <RateList availability={availability} loading={loadingRates || loadingValidations} rates={ratesFiltered} />
      </MainColumn>

      <SideColumn lg="5" xl="4">
        <ReservationDetails showSite={false} />
      </SideColumn>

      <RigSizeModal validations={validations} handleRefetchRates={fetchRates} />

      {siteLockFee && <SiteLockModal />}
    </Section>
  )
}

const mapStateToProps = state => ({
  campgroundName: state.campground.name,
  campgroundSlug: state.campground.slug,
  isPurchaseComplete: state.payment.isPurchaseComplete,
  search: state.search,
  siteLockFee: state.campground.siteLockFee,
})

const mapDispatchToProps = dispatch => ({
  resetIsPurchaseComplete: () => dispatch(paymentSetData({ isPurchaseComplete: false })),
  setCheckoutStepViewed: () => dispatch(setCheckoutStepViewed(2)),
  setSearch: data => dispatch(searchFilterUpdate(data)),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(Search)
