import useRouter from 'Checkout/Hooks/useRouter'
import { Button } from 'reactstrap'
import './ProductButtons.scss'

const Buttons = () => {
  const router = useRouter()

  const goToCheckout = () => router.go('/checkout')

  return (
    <div className="product-buttons d-flex justify-content-center">
      <Button className="product-button btn-theme-primary me-2 fw-bold" onClick={goToCheckout}>
        No, Thanks
      </Button>
      <Button className="product-button btn-theme-primary text-white ms-2 fw-bold">Add to Trip</Button>
    </div>
  )
}

export default Buttons
