import { useEffect, useState } from 'react'
import { setCheckoutData, setCheckoutStepViewed, setPageView } from 'Checkout/Redux/Actions/TrackSegment'
import storeConnect from 'Checkout/Redux/Connect'
import useGuestRigValidations from 'Checkout/Hooks/useGuestRigValidations/useGuestRigValidations'
import useMetaTags from 'Checkout/Hooks/useMetaTags'
import useReloadWithParams from 'Checkout/Hooks/useReloadWithParams'
import useUnload from 'Checkout/Hooks/useUnload'
import CamperForm from 'Checkout/Components/Details/CamperForm/CamperForm'
import Header from 'Checkout/Components/Shared/Header/Header'
import Loading from 'Shared/Loading'
import MainColumn from 'Checkout/Components/Shared/MainColumn/MainColumn'
import PageTitle from 'Checkout/Components/Shared/PageTitle/PageTitle'
import ProgressNav from 'Checkout/Components/Shared/ProgressNav/WrapperProgressNav'
import ReservationDetails from 'Checkout/Components/Shared/ReservationDetails/ReservationDetails'
import Section from 'Checkout/Components/Shared/Section/Section'
import SideColumn from 'Checkout/Components/Shared/SideColumn/SideColumn'
import 'Styles/Text.scss'

const Details = ({ camperTotal, savings, setCheckoutData, setCheckoutStepViewed, setPageView }) => {
  const metatags = { title: 'Checkout | RoverPass' }
  useReloadWithParams()
  useMetaTags(metatags)
  useUnload()

  const { validations, validationsLoading } = useGuestRigValidations()
  const loadingClassnames = 'align-items-center d-flex justify-content-center h-100 w-100'

  const [isCamperTotalPresent, setIsCamperTotalPresent] = useState(false)

  useEffect(() => {
    setCheckoutStepViewed()
    setPageView()
  }, [])

  useEffect(() => {
    let cancel = false

    if (camperTotal?.cents) {
      if (!isCamperTotalPresent) setCheckoutData({ discount: savings, name: 'Checkout Started' })

      if (cancel) return
      setIsCamperTotalPresent(true)
    }

    return () => {
      cancel = true
    }
  }, [camperTotal])

  return (
    <Section name="checkout">
      <MainColumn lg="7" xl="8">
        <Header hr={false} />
        <PageTitle className="d-none d-md-block text-center" text="Enter Your Details" />
        <ProgressNav />
        {validationsLoading ? <Loading className={loadingClassnames} /> : <CamperForm validations={validations} />}
      </MainColumn>

      <SideColumn showOnMobile lg="5" xl="4">
        <ReservationDetails editBtnSmall showCompleteDetails />
      </SideColumn>
    </Section>
  )
}

const mapDispatchToProps = dispatch => ({
  setCheckoutData: dataToCompleteOrderEvent => dispatch(setCheckoutData(dataToCompleteOrderEvent)),
  setCheckoutStepViewed: () => dispatch(setCheckoutStepViewed(5)),
  setPageView: () => dispatch(setPageView('Checkout: Details')),
})

const mapStateToProps = state => ({
  camperTotal: state.payment.camperTotal,
  savings: state.payment.savings,
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(Details)
