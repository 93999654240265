import cnames from 'classnames'
import RichText from 'Shared/RichText/RichText'
import './RateDescription.scss'

const RateDescription = ({ description, isSeeMoreActive, minNightsRequired }) => {
  const classes = cnames('rate-description', { 'rate-description__hide': !isSeeMoreActive })

  return (
    <div className={classes}>
      <hr />
      {minNightsRequired > 0 && (
        <p className="mb-3 px-3 text-muted">
          Min. nights required:
          <span className="fw-bold ms-1">{minNightsRequired}</span>
        </p>
      )}

      {description && (<RichText className="mb-0 px-3 text-muted text-smaller-1" html={description} />)}
    </div>
  )
}

export default RateDescription
