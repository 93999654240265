import { useState } from 'react'
import { Carousel, CarouselControl } from 'reactstrap'
import './CarouselContainer.scss'

const CarouselContainer = ({ cardData }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const next = () => {
    const nextIndex = activeIndex >= cardData.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    const nextIndex = activeIndex <= 0 ? cardData.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  return (
    <Carousel className="carousel-container d-md-none d-block mt-4 w-100" interval={false}
              activeIndex={activeIndex} next={next} previous={previous}>
      {cardData}

      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}
                       className="carousel-control-prev-icon my-auto" />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next}
                       className="carousel-control-next-icon my-auto" />
    </Carousel>
  )
}

export default CarouselContainer
