import { gql } from 'Utils/apolloClient'

const ratesAvailabilityAlertsQuery = gql`
  query RatesAvailabilityAlerts($filters: ValidationsFiltersAttributes, $from: ISO8601Date!,
                                $slug: String!, $to: ISO8601Date!) {
    campground(filters: $filters, from: $from, slug: $slug, to: $to) {
      id
      daysBookingTogether
      daysRequiredByNbt
      ratesAvailableDuring {
        name
      }
      unavailableDueToHolidays
      unavailableRatesNbtRequiredCount
      unavailableRatesMinNightsCount
    }
  }`

export default ratesAvailabilityAlertsQuery
