import cnames from 'classnames'
import PropTypes from 'prop-types'
import './PageTitle.scss'

const PageTitle = ({ className, text }) => {
  const titleClasses = cnames('fw-bold mb-3 page-title', className)

  return <h3 className={titleClasses}>{text}</h3>
}

PageTitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export default PageTitle
