import cnames from 'classnames'
import { Button } from 'reactstrap'
import storeConnect from 'Checkout/Redux/Connect'
import useRouter from 'Checkout/Hooks/useRouter'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import './EditReservationButton.scss'

const EditReservationButton = ({ camperTotal, className, editBtnClass, editBtnSmall }) => {
  const router = useRouter()
  const classes = {
    container: cnames('btn-edit-reservation d-flex d-lg-inline justify-content-between text-lg-end', className),
    button: cnames('btn-theme-primary', { 'btn-small': editBtnSmall }, editBtnClass),
  }

  const goToSearch = () => {
    const search = { rate_id: '', rig_length: '', rig_width: '' }
    router.go({ pathname: '/search', search })
  }

  return (
    <>
      <div className={classes.container}>
        <Button className={classes.button} onClick={goToSearch}>
          <FontAwesomeIcon className="mx-2" icon="pencil" />
          Edit Reservation
        </Button>

        {(editBtnSmall && camperTotal?.cents >= 0) && (
          <div className="d-lg-none">
            <span className="total-label me-1">TOTAL:</span>
            <span className="d-inline fw-bold text-green">{camperTotal.displayWithCode}</span>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  camperTotal: state.payment.camperTotal,
})

export default storeConnect({ mapStateToProps })(EditReservationButton)
