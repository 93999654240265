import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import MainColumn from 'Checkout/Components/Shared/MainColumn/MainColumn'
import Section from 'Checkout/Components/Shared/Section/Section'
import './ErrorPage.scss'

const ErrorPage = ({ contactButtonGoTo, image, subtitle, title }) => {
  const history = useHistory()

  const goHome = () => {
    history.replace('/')
    history.go()
  }

  return (
    <Section name="not-found">
      <MainColumn>
        <div className="error-page d-flex flex-column justify-content-center align-items-center flex-fill">
          <div>
            <img src={image} alt="error" />
          </div>
          <div className="text-center mb-4 mt-4">
            <h1 className="fw-bold mb-2">{title}</h1>
            <h5>{subtitle}</h5>
          </div>
          <div className="mt-2">
            <Button className="btn-theme-primary error-button p-2" onClick={goHome}>GUIDE ME HOME</Button>

            {contactButtonGoTo && (
              <a href={contactButtonGoTo} className="btn btn-outline-theme-primary ms-4 p-2 error-button">
                CONTACT US
              </a>
            )}
          </div>
        </div>
      </MainColumn>
    </Section>
  )
}

export default ErrorPage
