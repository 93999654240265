import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import { getAmenity } from 'Checkout/Components/Shared/AmenityIcons'
import defaultImg from 'Checkout/Components/Shared/Icons/default.svg'

const AmenityIcon = ({ amenity }) => {
  function renderIcon() {
    if ((amenity ?? '').trim() === '') return

    const { icon, img } = getAmenity(amenity)
    if (icon) return <FontAwesomeIcon icon={icon} className="amenity-icon me-2" />

    return <img src={img || defaultImg} height="14" className="amenity-image align-baseline me-2"
                alt={`Amenity: ${amenity} icon.`} />
  }

  return <div className="amenity-icon">{renderIcon()}</div>
}

export default AmenityIcon
