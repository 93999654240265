const validateRigSize = ({ rigLength, rigWidth, minLength, minWidth, maxLength, maxWidth }) => {
  const rigLengthValidation = _validateSize(rigLength, minLength || 10, maxLength)
  const rigWidthValidation = _validateSize(rigWidth, minWidth || 5, maxWidth)

  return rigLengthValidation && rigWidthValidation
}

const isRvAdequateForRig = ({ rigLength, rigWidth, maxLength, maxWidth }) =>
  maxLength >= rigLength && maxWidth >= rigWidth

const isVehicleSelected = ({ id, slideouts, type, year }) => id || (slideouts && type && year)

// private
const _validateSize = (size, min, max) => {
  const isSizeGreaterThanMin = size >= min
  const isSizeLessThanMax = !max || size <= max

  return isSizeGreaterThanMin && isSizeLessThanMax
}

export { validateRigSize, isRvAdequateForRig, isVehicleSelected }
