import { useEffect } from 'react'
import storeConnect from 'Checkout/Redux/Connect'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import { formatDate } from 'Utils/Dates'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import { TripInsuranceContainer } from 'Checkout/Components/Shared/TripInsurance'
import tripInsuranceQuery from './TripInsuranceQuery'

const TripInsuranceWrapper = ({ checkIn, checkOut, dayUse, discountId, guests, isSiteLock, productsPurchased, rate,
                                rigLength, rigWidth, setIsTripProtection, setTripProtectionQuote, site, sitetype,
                                slug, isTripProtection, tripProtectionQuote }) => {
  const [getPrice, { data, loading }] = useLazyQueryNoCache(tripInsuranceQuery)
  const tripProtectionQuoteToShow = loading ? '$10.00' : tripProtectionQuote

  const fetchPrice = () => {
    const rateId = rate?.id
    const siteId = site?.id
    const filters = { maxLength: rigLength, maxWidth: rigWidth, type: sitetype?.type }
    const extras = { discountId, filters, guests, isSiteLock, productsPurchased, siteId }
    const variables = { from: formatDate(checkIn), rateId, slug, to: formatDate(checkOut), extras }
    const tripInsuranceQueryArgs = { variables }

    getPrice(tripInsuranceQueryArgs)
  }

  useEffect(() => {
    if (checkIn && checkOut && rate && !dayUse) fetchPrice()
  }, [checkIn, checkOut, discountId, rate])

  useEffect(() => {
    if (data) setTripProtectionQuote(data?.campground?.tripInsurancePrice)
  }, [data])

  return <TripInsuranceContainer {...{ loading, setIsTripProtection, isTripProtection,
                                       tripProtectionQuote: tripProtectionQuoteToShow }} />
}

const mapStateToProps = state => ({
  checkIn: state.search.checkIn,
  checkOut: state.search.checkOut,
  dayUse: state.search.dayUse,
  discountId: state.payment.discount?.id,
  guests: state.details.guests,
  isSiteLock: state.payment.isSiteLock,
  productsPurchased: state.details.productsPurchased,
  rate: state.search.rate,
  rigLength: state.search.rigLength,
  rigWidth: state.search.rigWidth,
  site: state.search.site,
  sitetype: state.search.sitetype,
  slug: state.campground.slug,
  isTripProtection: state.payment.isTripProtection,
  tripProtectionQuote: state.payment.tripProtectionQuote,
})

const mapDispatchToProps = dispatch => ({
  setTripProtectionQuote: tripProtectionQuote => dispatch(paymentSetData({ tripProtectionQuote })),
  setIsTripProtection: isTripProtection => dispatch(paymentSetData({ isTripProtection })),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(TripInsuranceWrapper)
