import * as Yup from 'yup'
import { isValidPhone } from 'Utils/PhoneHelpers'
import { textareaMaxLength } from 'Shared/Formik/Utils'
import { totalGuests } from 'Utils/guestHelpers'

const messageRequired = 'Required field.'

Yup.addMethod(Yup.string, 'isValidPhone', isValidPhone)

const DetailsViewSchema = ({ canAddVehicle, isRequest, rigLength, rigWidth, validations }) => {
  const { areGuestsRequired, guests: { adults, children, extraVehicles, maxPeople, pets } } = validations
  const minGuests = areGuestsRequired ? 1 : 0
  const maxExtraVehicle = extraVehicles?.max || 0

  const schema = Yup.object().shape({
    // address: Yup.string()
    //   .required(messageRequired),

    // address2: Yup.string(),

    // city: Yup.string()
    //   .required(messageRequired),

    email: Yup.string()
      .email('Email is invalid.')
      .required(messageRequired).max(255, 'Text exceeds 255 chars'),

    firstName: Yup.string()
      .required(messageRequired).max(255, 'Text exceeds 255 chars'),

    guests: Yup.object().shape({
      adults: Yup.number()
        .min(minGuests)
        .max(adults.max)
        .required(messageRequired),

      children: Yup.number()
        .when('useGuests', {
          is: useGuests => !useGuests,
          then: Yup.number()
            .max(children?.max || 20),
        }),

      pets: Yup.number()
        .max(pets?.max || 20),

      petBreeds: Yup.string()
        .when('pets', {
          is: pets => !!parseInt(pets),
          then: Yup.string()
            .required(messageRequired),
        }),

      vehicles: Yup.number()
        .max(maxExtraVehicle, `Max values is ${maxExtraVehicle}`)
        .when('vehiclesTowed', {
          is: vehiclesTowed => vehiclesTowed,
          then: Yup.number()
            .min(1, 'Min value is 1'),
        }),

      vehiclesTowed: Yup.bool(),
    }),

    lastName: Yup.string()
      .required(messageRequired).max(255, 'Text exceeds 255 chars'),

    maxPeople: Yup.number()
      .when('guests', {
        is: ({ adults, children }) => totalGuests({ adults, children }) > maxPeople,
        then: Yup.number()
          .required(),
      }),

    // state: Yup.string()
    //   .required(messageRequired),

    phone: Yup.string()
      .required(messageRequired)
      .isValidPhone(),

    rigRequired: Yup.bool(),

    vehicle: Yup.object()
      .when('rigRequired', {
        is: rigRequired => rigRequired || (isRequest && canAddVehicle),
        then: Yup.object({
          id: Yup.string()
            .nullable(),

          type: Yup.string()
            .when('id', {
              is: id => !id,
              then: Yup.string()
                .required(messageRequired),
            }),

          year: Yup.string()
            .when('id', {
              is: id => !id,
              then: Yup.string()
                .required(messageRequired),
            }),

          length: Yup.string()
            .when('id', {
              is: id => !id && !rigLength,
              then: Yup.string()
                .required(messageRequired),
            }),

          width: Yup.string()
            .when('id', {
              is: id => !id && !rigWidth,
              then: Yup.string()
                .required(messageRequired),
            }),

          slideouts: Yup.string()
            .when('id', {
              is: id => !id,
              then: Yup.string()
                .required(messageRequired),
            }),
        }),
      }),

    customFields: Yup.array().of(
      Yup.object().shape({
        data: Yup.object().shape({}),
        type: Yup.string().required(),
        requiredInCheckout: Yup.boolean(),
        value: Yup.mixed().when(['type', 'data'], {
          is: (type, data) => type === 'CustomFieldMultipleChoice' && data.multiple === 'true',
          then: Yup.array(),
          otherwise: Yup.string(),
        }).when('requiredInCheckout', {
          is: requiredInCheckout => requiredInCheckout,
          then: schema => schema.required(),
        }),
      })
    ),

    specialRequests: Yup.string()
      .max(textareaMaxLength, `Max characters ${textareaMaxLength}`),

    // zipCode: Yup.string()
    //   .required(messageRequired),
  })

  return schema
}

export default DetailsViewSchema
