import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import useRouter from 'Checkout/Hooks/useRouter'
import storeConnect from 'Checkout/Redux/Connect'
import { searchFilterUpdate, searchUpdateWithSessionStorage } from 'Checkout/Redux/Actions/Search'
import { amenityName } from 'Checkout/Components/Shared/AmenityIcons'
import Anchor from 'Shared/Anchor/Anchor'
import Image from 'Shared/Image/Image'
import defaultPicture from 'Checkout/Images/photo-coming-soon-list.png'
import './Site.scss'

const Site = ({ canUseSiteLock, scrollToSiteMap, site, sitesMapUrl, setSite, setSiteLockModal }) => {
  const router = useRouter()
  const { id, maxLength, maxPeople, maxWidth, name, pictureSizes: { listUrl } } = site

  const selectSite = () => {
    setSite({ id, name }, 'site')
    if (canUseSiteLock && sitesMapUrl) return setSiteLockModal(true)

    router.next()
  }

  const amenitiesNames = site.amenities.map(amenity => amenityName(amenity))
  const amenities = amenitiesNames.join(', ')

  return (
    <div className="site-wrapper col-12" data-name={name}>
      <div className="site-card border rounded d-flex">
        <div className="site-img-wrapper col-3">
          <Image alt="Site" className="h-100 w-100" defaultImage={defaultPicture} srcToLoad={listUrl} />
        </div>

        <div className="site-details px-3 pt-3 col-9 d-flex justify-content-between">
          <div className="site-info d-flex flex-column flex-fill">
            <div className="d-flex align-items-center">
              <h4>{name}</h4>

              {sitesMapUrl && (
                <Anchor className="fst-italic mb-1 cursor-pointer ms-2 " onClick={() => scrollToSiteMap(site)}>
                  View on Map
                </Anchor>
              )}
            </div>

            <div className="row text-muted">
              <div className="col-4">
                <p className="m-0">
                  <FontAwesomeIcon icon="users" /> {maxPeople} People Max
                </p>

                {!!parseInt(maxWidth) && !!parseInt(maxLength) && (
                  <p className="m-0">
                    <FontAwesomeIcon icon="ruler-combined" /> {maxLength} ft. x {maxWidth} ft.
                  </p>
                )}
              </div>

              {site.amenities.length > 0 && (
                <div className="col me-3">
                  <b>Amenities:</b>

                  <br />

                  <small>{amenities}</small>
                </div>
              )}
            </div>
          </div>

          <Button className="site-btn fw-bold px-3 align-self-center" color="outline-dark" onClick={selectSite}>
            Select
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  canUseSiteLock: state.campground.canUseSiteLock,
  sitesMapUrl: state.campground.sitesMapUrl,
})

const mapDispatchToProps = dispatch => ({
  setSite: (data, sessionKey) => dispatch(searchUpdateWithSessionStorage({ site: data }, sessionKey)),
  setSiteLockModal: siteLockModal => dispatch(searchFilterUpdate({ siteLockModal })),
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(Site)
