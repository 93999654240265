import PropTypes from 'prop-types'

const ValidationsPropTypes = PropTypes.shape({
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
})

export { ValidationsPropTypes }
