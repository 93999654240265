export const skeletonReservation = {
  balanceDue: {
    display: '$-.--',
  },
  balanceRemaining: {
    display: '$-.--',
  },
  balancePaid: {
    display: '$-.--',
  },
  balanceTotal: {
    display: '$-.--',
    displayWithCode: '$-.-- USD',
  },
  cancellationPolicy: 'asdasdasd',
  confirmationMessage: '<p>Skeleton text paragraph.</p>',
  confirmationTitle: 'Fake Title',
  customFieldValues: [
    {
      answer: 'Picture 1',
      question: 'Image thing',
    },
    {
      answer: 'Okay',
      question: 'I understand ',
    },
  ],
  id: 'fake',
  email: 'fake@fake.com',
  firstName: 'Fake',
  name: 'Fake',
  phone: {
    country: '+1',
    number: '1111111111',
    display: '+1 1111111111',
  },
  from: '2023-03-27',
  to: '2023-03-31',
  data: {
    source: 'marketplace',
    pet_breeds: '',
  },
  discount: null,
  rate: {
    id: '00000',
    rigRequired: true,
    siteTypeName: 'Fake Sites - Fake Site',
    totalNights: 4,
    totalPrice: {
      amount: 27,
      displayWithCode: '$27.00 USD',
    },
  },
  site: {
    id: '000000',
    name: 'Fake 5',
  },
  guests: {
    adults: 2,
    children: 0,
    pets: 0,
    useGuests: null,
    vehicles: 0,
    vehiclesTowed: false,
  },
  marketplace: true,
  lastName: 'Fake',
  rig: {
    numSlideouts: '0',
    rigLength: 10,
    rigType: 'Fake',
    rigWidth: 5,
    year: 2024,
  },
  pricing: {
    camperFee: {
      display: '$2.00',
      displayWithCode: '$2.00 USD',
    },
    camperTotal: {
      amount: 200,
      cents: 0,
      display: '$200.00',
      displayWithCode: '$200.00 USD',
    },
    dueNow: '$44.90',
    dueUponCheckIn: '$0.00',
    installments: null,
    isTaxIncluded: false,
    items: [
      {
        id: '515959',
        isFee: false,
        itemableId: '26421',
        itemableType: 'pricing_tier',
        name: 'Fake Sites - Fake Sites',
        total: {
            amount: 27,
            display: '$27.00',
            displayWithCode: '$27.00 USD',
        },
        units: 1,
        price: '$27.00',
      },
    ],
    nightlyTotal: {
      amount: 27,
      display: '$27.00',
      displayWithCode: '$27.00 USD',
    },
    subtotal: {
      amount: 39,
      display: '$39.00',
      displayWithCode: '$39.00 USD',
    },
    taxesAndFees: '$3.90',
    totalPaid: {
      amount: 44.9,
      display: '$44.90',
      displayWithCode: '$44.90 USD',
    },
    currencyCode: 'USD',
  },
  uuid: 'test-test-test-test',
  canShowWhatToKnow: true,
  nights: 4,
}
