import useRouter from 'Checkout/Hooks/useRouter'
import storeConnect from 'Checkout/Redux/Connect'

const UserDetails = ({ firstName, lastName }) => {
  const router = useRouter()

  const goBack = () => router.back()

  return (
    <div className="text-gray">
      <div className="d-flex justify-content-between">
        <p className="fw-bold mb-1 me-3">{`${firstName} ${lastName}`}</p>
        <a href="#editDetails" className="d-block fw-bold text-gray text-underlined"
           onClick={goBack}>
          Edit Details
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  firstName: state.details.firstName,
  lastName: state.details.lastName,
})

export default storeConnect({ mapStateToProps })(UserDetails)
