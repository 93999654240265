import PhoneInput from 'react-phone-number-input'
import { topContries } from 'Utils/PhoneHelpers'
import FloatingLabelInputRef from 'Shared/FloatingLabelInput/FloatingLabelInputRef'
import FormGroupField from 'Shared/Formik/FormGroupField'
import 'react-phone-number-input/style.css'

const FloatingInternationalPhone = ({ defaultCountry, name }) => (
  <FormGroupField name={name}>
    {({ field, form }) => {
      const changePhone = value => form.setFieldValue(name, value)

      return (
        <PhoneInput countryOptionsOrder={topContries} defaultCountry={defaultCountry || 'US'}
                    inputComponent={FloatingLabelInputRef} international label="Phone" limitMaxLength max={20}
                    name={name} onChange={changePhone} onChangeInput={changePhone} value={field.value} />
      )
    }}
  </FormGroupField>
)

export default FloatingInternationalPhone
