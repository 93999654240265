import ErrorPage from 'Checkout/Components/Shared/ErrorPage/ErrorPage'
import Footer from 'Checkout/Components/Shared/Footer/Footer'
import Header from 'Checkout/Components/Shared/Header/Header'
import notFoundImage from 'Checkout/Images/NotFoundError'

const NotFound = () =>
  <>
    <Header className="p-4" />

    <ErrorPage image={notFoundImage} title="Sorry this page is not available"
               subtitle="The link may be broken or the page may have been removed" />

    <Footer />
  </>

export default NotFound
