export const skeletonPrice = {
  camperFee: { display: '-.--' },
  camperTotal: { displayWithCode: '--.-- MON' },
  dueNow: {
    display: '$-.--',
  },
  dueUponCheckIn: {
    display: '$-.--',
  },
  items: [{
    itemableType: 'pricing_tier',
    name: 'rate name + site selected',
    total: { amount: 1, display: '-.--' },
  }],
  subtotal: { display: '-.--' },
  taxesAndFees: '-.--',
  taxConfig: { taxName: '--.--' },
}
