import PropTypes from 'prop-types'
import storeConnect from 'Checkout/Redux/Connect'
import useRouter from 'Checkout/Hooks/useRouter'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { formatDate, today } from 'Utils/Dates'
import { removeCookie } from 'Utils/Cookies'
import DateRangePicker from 'Shared/DatePicker/DateRangePicker/DateRangePicker'
import './StayPicker.scss'

const StayPicker = ({ advancedMonths, areNotices, checkInValue, checkOutValue, closureDates, onDateSet, slug,
                      updateDates }) => {
  const router = useRouter()
  const cookieName = `checkout-${slug}-view-noticies`
  const maxDate = today().add(advancedMonths, 'months')

  const updateDatesInPath = ({ startDate, endDate }) => {
    if (areNotices) removeCookie(cookieName)
    if (startDate && endDate) {
      const checkIn = formatDate(startDate)
      const checkOut = formatDate(endDate)

      updateDates({ checkIn, checkOut })
      router.replaceQuery({ check_in: checkIn, check_out: checkOut })
    }
  }

  return (
    <DateRangePicker checkInValue={checkInValue} checkOutValue={checkOutValue} closureDates={closureDates}
                     maxDate={maxDate} onDateSet={onDateSet} updateDates={updateDatesInPath} />
  )
}

StayPicker.defaultProps = {
  advancedMonths: 24,
  areNotices: false,
  checkInValue: null,
  checkOutValue: null,
  closureDates: null,
  onDateSet: null,
}

StayPicker.propTypes = {
  advancedMonths: PropTypes.number,
  areNotices: PropTypes.bool,
  checkInValue: PropTypes.string,
  checkOutValue: PropTypes.string,
  closureDates: PropTypes.instanceOf(Array),
  onDateSet: PropTypes.func,
  updateDates: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  advancedMonths: state.campground.advancedMonths,
  closureDates: state.campground.closeSeasonDates,
  slug: state.campground.slug,
})

const mapDispatchToProps = dispatch => ({
  updateDates: ({ checkIn, checkOut }) => dispatch(searchFilterUpdate({ checkIn, checkOut })),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(StayPicker)
