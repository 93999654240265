import { gql } from 'Utils/apolloClient'

const tripInsuranceQuery = gql`
  query TripInsurance($extras: PricingExtrasAttributes, $from: ISO8601Date!, $rateId: ID!, $slug: String!,
                      $to: ISO8601Date!) {
    campground(from: $from, slug: $slug, to: $to) {
      tripInsurancePrice(rateId: $rateId, extras: $extras)
    }
  }`

export default tripInsuranceQuery
