import { useMutation } from 'Shared/Hooks/useApolloClient'
import { useDispatch, useSelector } from 'react-redux'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import { getCookie, setCookie } from 'Utils/Cookies'
import bannedGuestMutation from 'Checkout/Queries/BannedGuestMutation'
import { actionCamperSetData } from 'Checkout/Redux/Actions/Camper'
import { actionCampgroundSetData } from 'Checkout/Redux/Actions/Campground'

const ONE_DAY = 24 * 60

const useBannedGuest = () => {
  const dispatch = useDispatch()
  const { slug } = useSelector(state => state.campground)
  const { checkIn, checkOut, validStayDates } = useStayDates()

  const [callBannedGuest, { data, error, loading }] = useMutation(bannedGuestMutation, {
    onCompleted: response => {
      const { isBanned, email, nearbyCampgrounds } = response.bannedGuestDetection

      if (isBanned) {
        const cookieName = `checkout-${slug}-${email}-notified`
        if (!getCookie(cookieName))
          setCookie(cookieName, true, { expires: ONE_DAY })

        dispatch(actionCampgroundSetData({ nearbyCampgrounds }))
      }

      dispatch(actionCamperSetData({ displayNotAvailable: isBanned, isBanned }))
    },
  })

  const handleBannedGuest = ({ email }) => {
    if (email && !loading && slug && validStayDates) {
      const cookieName = `checkout-${slug}-${email}-notified`
      const ownerNotified = getCookie(cookieName) || false
      const variables = { email, from: checkIn, slug, to: checkOut, toNotify: !ownerNotified }
      callBannedGuest({ variables })
    }
  }

  return [handleBannedGuest, { error, guestData: data, loading }]
}

export default useBannedGuest
