import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import { ValidationsPropTypes } from 'Checkout/PropTypes'
import storeConnect from 'Checkout/Redux/Connect'
import DailyAccommodation from 'Checkout/Components/Home/DailyAccommodation/DailyAccommodation'
import StayPicker from 'Checkout/Components/Shared/StayPicker/StayPicker'
import SizeFilter from './SizeFilter/SizeFilter'
import SiteTypesList from 'Checkout/Components/Home/SiteTypesList/SiteTypesList'

const SiteFilters = ({ checkIn, checkOut, dayUse, displayRigFilter, loading, loadingRates, searchLoading,
                       siteTypes, validations }) => (
  <div id="search-filters">
    {!dayUse && (
      <p className="d-none d-md-block mb-2">
        Enter your reservation details to search for availability.
      </p>
    )}

    <Row className="mb-3">
      <Col className="pe-xl-2" xl="6">
        {dayUse ? (
          <DailyAccommodation checkIn={checkIn} onlyInput />
        ) : (
          <StayPicker areNotices checkInValue={checkIn} checkOutValue={checkOut} />
        )}
      </Col>

      {displayRigFilter && !searchLoading && (
        <Col className="ps-xl-2 mt-3 mt-xl-0" xl="6">
          <SizeFilter validations={validations} />
        </Col>
      )}
    </Row>

    <SiteTypesList loading={loading} loadingRates={loadingRates} siteTypes={siteTypes} useInSearch />

    <hr className="mt-2 mb-4" />
  </div>
)

SiteFilters.defaultProps = {
  checkIn: null,
  checkOut: null,
  loading: false,
  searchLoading: false,
  siteTypes: [],
}

SiteFilters.propTypes = {
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  displayRigFilter: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  searchLoading: PropTypes.bool,
  siteTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  validations: ValidationsPropTypes.isRequired,
}

const mapStateToProps = state => ({
  checkIn: state.search.checkIn,
  checkOut: state.search.checkOut,
  dayUse: state.search.dayUse,
})

export default storeConnect({ mapStateToProps })(SiteFilters)
