import { useDispatch } from 'react-redux'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import useRouter from 'Checkout/Hooks/useRouter'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const AccomodationOptions = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const btnClass = 'btn btn-outline-dark-blue fw-bold mb-3 p-2 text-start'

  const setUseDaily = value => {
    const accommodation = value ? 'daily' : 'overnight'
    dispatch(searchFilterUpdate({ accommodation, dayUse: value }))
    router.replaceQuery({ accommodation })
  }

  return (
    <div className="accomodation-options mb-4">
      <p className="fw-normal mb-1 text-black">What are you looking for?</p>

      <div className="d-flex flex-column">
        <button className={btnClass} onClick={() => setUseDaily(false)} type="button">
          <FontAwesomeIcon className="me-2" icon="cloud-moon" size="lg" />

          Overnight Accommodations
        </button>

        <button className={btnClass} onClick={() => setUseDaily(true)} type="button">
          <FontAwesomeIcon className="me-2" icon="sun" size="lg" />

          Daily Accommodations
        </button>
      </div>
    </div>
  )
}

export default AccomodationOptions
