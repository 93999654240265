import { Button } from 'reactstrap'
import storeConnect from 'Checkout/Redux/Connect'
import Loading from 'Shared/Loading'
import PaymentButton from './PaymentButton'

const PaymentSection = ({ isCampgroundRequest, processingPayment }) => (
  <div className="mb-3">
    <Button className="col-md-6 btn-theme-primary fw-bold shadow-sm text-white w-50 h-100"
            disabled={processingPayment} type="submit">
      {processingPayment ? <Loading iconClass="text-white" /> : <PaymentButton />}
    </Button>

    {isCampgroundRequest && (
      <p className="fw-bold mt-4 text-gray">
        *You will not be charged until reservation is approved by the campground.
      </p>
    )}
  </div>
)

const mapStateToProps = state => ({
  isCampgroundRequest: state.campground.isRequest,
})

export default storeConnect({ mapStateToProps })(PaymentSection)
