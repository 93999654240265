import { useContext, useLayoutEffect } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import transformUrl from 'transform-url'
import { addDateTime, parseMoment } from 'Utils/Dates'
import routes from 'Checkout/Routes/routes'
import storeConnect from 'Checkout/Redux/Connect'
import useBannedGuest from 'Checkout/Hooks/useBannedGuest'
import useRouter from 'Checkout/Hooks/useRouter'
import RoutingContext from 'Checkout/Components/Routing/RoutingContext'
import Layout from 'Checkout/Components/Layout/Layout'
import Loading from 'Shared/Loading'
import NotAvailable from 'Checkout/Containers/NotAvailable/NotAvailable'

const Routing = ({ component: Component, displayNotAvailable, email, isCamperBanned, route, ...rest }) => {
  const router = useRouter()
  const [routing, setRouting] = useContext(RoutingContext)
  const [handleBannedGuest, { loading }] = useBannedGuest()
  const { accommodation, check_in: checkIn, check_out: checkOut } = router.query
  /**
   * Goes back through the checkout process
   * @param {Object} [args]
   * @param {Object} [args.search]
   */
  const back = args => {
    const lastKey = routing.from ? routing.from?.key : routing.prev?.key
    const pathname = routes[lastKey]?.props?.path
    let routeArgs

    routeArgs = { pathname }

    if (args?.search) routeArgs = { ...routeArgs, search: args.search }

    router.go({ ...routeArgs })
  }

  /**
   * Goes forward through the checkout process
   * @param {Object} [args]
   * @param {boolean} [args.canUseSiteLock]
   * @param {boolean} [args.isAutoAssign]
   * @param {boolean} [args.isSiteLockPurchased]
   * @param {Object} [args.search]
   * @param {Object} [args.template]
   */
  const next = args => {
    let noQueryParams
    let pathname = route.getNext().props.path
    let routeArgs

    if (route.key === 'search') {
      const dayUse = accommodation === 'daily'
      const canSkipSites = dayUse || !args?.canChooseSite || (args?.canUseSiteLock && !args?.isSiteLockPurchased)

      if (canSkipSites) pathname = routes.checkout.props.path
    }

    if (route.key === 'payment') noQueryParams = true

    if (args?.template) pathname = transformUrl(pathname, args.template)

    routeArgs = { pathname }

    if (noQueryParams) routeArgs = { ...routeArgs, noQueryParams: true }
    if (args?.search) routeArgs = { ...routeArgs, search: args.search }

    const method = noQueryParams ? 'push' : 'go'
    router[method]({ ...routeArgs })
  }

  /**
   * Required for restore scroll position when using history.push
   */
  const resetScroll = () => {
    window.scrollTo(0, 0)
  }

  useLayoutEffect(() => {
    if (isCamperBanned && ((checkIn && checkOut) || (accommodation === 'daily' && checkIn))) {
      const from = parseMoment(checkIn, { isFormatNeeded: true })
      const dailyTo = addDateTime(from, { day: 1 })
      const to = parseMoment(checkOut, { isFormatNeeded: true }) || parseMoment(dailyTo, { isFormatNeeded: true })

      handleBannedGuest({ email, from, to })
    }
  }, [checkIn, checkOut, isCamperBanned])

  useLayoutEffect(() => {
    resetScroll()
    if (route) setRouting({ from: routes[routing.to?.key], to: route, next })
  }, [route.key])

  useLayoutEffect(() => {
    if (routing.to?.key === route.key && !routing.prev) setRouting({ ...routing, prev: route.getPrev() })
  }, [routing.to])

  useLayoutEffect(() => {
    if ((routing.from || routing.prev) && !routing.back) setRouting({ ...routing, back })
  }, [routing.from, routing.prev])

  return (
    <Layout>
      <Route {...rest} render={props => {
        if (loading) return <Loading className="d-flex flex-fill align-items-center justify-content-center" />

        if (displayNotAvailable) return <NotAvailable />

        return (
          <Component {...props} />
        )
      }} />
    </Layout>
  )
}

Routing.defaultProps = {
  Component: null,
  route: null,
}

Routing.propTypes = {
  Component: PropTypes.symbol,
  route: PropTypes.shape({
    key: PropTypes.string,
  }),
}

const mapStateToProps = state => ({
  displayNotAvailable: state.camper.displayNotAvailable,
  email: state.camper.email,
  isCamperBanned: state.camper.isBanned,
})

export default storeConnect({ mapStateToProps })(Routing)
