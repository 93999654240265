import { useDispatch, useSelector } from 'react-redux'
import useRouter from 'Checkout/Hooks/useRouter'
import { formatDate } from 'Utils/Dates'
import { updatePaymentSiteLock } from 'Checkout/Redux/Actions/Payment'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { setProductClicked } from 'Checkout/Redux/Actions/TrackSegment'

const useSiteLock = ({ goSites, useInSites }) => {
  const dispatch = useDispatch()
  const { canUseSiteLock, siteLockFee } = useSelector(state => state.campground)
  const { checkIn, checkOut, rate, rigLength, rigWidth, siteType } = useSelector(state => state.search)
  const router = useRouter()

  const selectSiteLock = (isSiteLockPurchased, rateId) => {
    const siteLockData = { isSiteLock: isSiteLockPurchased, rateId: rateId || rate?.id || '',
                           siteLockFee: siteLockFee?.display || null }
    setSiteLockData(siteLockData)
  }

  const setSiteLockData = ({ isSiteLock, siteLockFee, rateId }) => {
    if (!goSites) {
      dispatch(setProductClicked)
      dispatch(updatePaymentSiteLock({ isSiteLock, siteLockFee: siteLockFee?.display }))
    }

    dispatch(searchFilterUpdate({ siteLockModal: false }))

    goNext({ isSiteLockPurchased: isSiteLock, rateId })
  }

  const goNext = ({ isSiteLockPurchased, rateId }) => {
    if (useInSites) return router.next()

    const search = { check_in: formatDate(checkIn), check_out: formatDate(checkOut),
                     rate_id: rateId, rig_length: rigLength || '', rig_width: rigWidth || '',
                     site_type: siteType.type }
    router.next({ search, canUseSiteLock, isSiteLockPurchased, canChooseSite: true })
  }

  return ({ selectSiteLock })
}

export default useSiteLock
