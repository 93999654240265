import { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { isEmpty } from 'Utils/objects'
import { useEffectAfterMount } from 'Shared/Hooks/useEffectAfterMount'
import useErrors from 'Checkout/Hooks/useErrors'
import useRouter from 'Checkout/Hooks/useRouter'
import NoAvailabilityAlert from 'Checkout/Components/Shared/NoAvailabilityAlert'
import Rate from './Rate/Rate'
import { ratesToShowInLoading } from './LoadingRateList'

const RateList = ({ availability, loading, rates }) => {
  const router = useRouter()
  const { globalError } = useErrors()
  const [currentSearch, setCurrentSearch] = useState({})
  const [alertMessage, setAlertMessage] = useState()
  const ratesToShow = loading ? ratesToShowInLoading : rates
  const rateListContainerClasses = cnames('rate-list mb-5 px-1 px-md-0', { 'skeleton-loading': loading })

  const { isAvailable, message } = availability
  const messages = {
    unavailable: 'No availability for the criteria you have entered. Please try a new search.',
    global: 'The rate you selected has sold out. Please choose another rate or change dates and try again.',
  }

  useEffect(() => {
    if (isEmpty(currentSearch)) {
      const currentData = { checkIn: router.query.check_in, checkOut: router.query.check_out,
                            siteType: router.query.site_type }
      setCurrentSearch(currentData)
    }
  }, [])

  useEffectAfterMount(() => {
    if (currentSearch.checkIn === router.query.check_in && currentSearch.checkOut === router.query.check_out &&
        currentSearch.siteType === router.query.site_type && !rates.length && globalError)
      setAlertMessage(messages.global)
    else setAlertMessage(message || messages.unavailable)
  }, [currentSearch])

  if ((!rates.length || !isAvailable) && !loading)
    return <NoAvailabilityAlert alertMessage={alertMessage} className="mt-1" />

  return (
    <div className={rateListContainerClasses}>
      {ratesToShow.map(rate => (
        <div key={rate?.id}>
          <Rate rate={rate} />
        </div>
      ))}
    </div>
  )
}

RateList.defaultProps = {
  loading: true,
  rates: [],
}

RateList.propTypes = {
  loading: PropTypes.bool,
  rates: PropTypes.instanceOf(Array),
}

export default memo(RateList)
