import { gql } from 'Utils/apolloClient'

const rvValidationsQuery = gql`
  query GuestRigValidations ($filters: ValidationsFiltersAttributes, $filtersCamp: ValidationsFiltersAttributes,
                             $from: ISO8601Date!, $slug: String!, $to: ISO8601Date!) {
    campground(filters: $filtersCamp, from: $from, slug: $slug, to: $to) {
      id
      availability {
        isAvailable
        message
      }
      daysBookingTogether
      daysRequiredByNbt
      canUseNonRefundable
      isInstantBooking
      isRefundableFully
      ratesAvailableDuring {
        name
        nightPrice {
          amount
          display
        }
        totalPrice {
          amount
          display
        }
      }
      sitesCountByTypeDuring
      unavailableDueToHolidays
      unavailableRatesMinNightsCount
      unavailableRatesNbtRequiredCount
      unavailableSitesTypesCount
      validations(filters: $filters) {
        isRigRequired
        rig {
          length {
            max
            min
          }
          width {
            max
            min
          }
        }
      }
    }
  }
`

export default rvValidationsQuery
