import { useEffect } from 'react'

const useUnload = () => {
  function onUnload(e) {
    e.preventDefault()
    e.returnValue = 'Your reservation will not be saved if you leave this site.'
  }

  useEffect(() => {
    if (window.QueueIt === undefined && window.roverData?.railsEnv !== 'development') {
      window.addEventListener('beforeunload', onUnload)

      return () => window.removeEventListener('beforeunload', onUnload)
    }
  }, [])
}

export default useUnload
