import { useEffect, useState } from 'react'
import { Col } from 'reactstrap'
import classNames from 'classnames'
import storeConnect from 'Checkout/Redux/Connect'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import './Card.scss'

const Card = ({ card: { expDate, id, lastFour, userAddress, userName }, paymentCard, setCardData }) => {
  const [selected, setSelected] = useState(false)
  const colClasses = classNames('card border bg-light rounded text-gray mx-auto shadow-sm',
                                'mx-md-0 p-0 mb-2 me-md-3', { 'selected-card': selected })

  useEffect(() => {
   setSelected(id === paymentCard?.id || false)
  }, [paymentCard.id])

  const selectCard = () => {
    let cardData = {}
    if (!selected) cardData = { id, userAddress, expDate, lastFour, userName }

    setCardData(cardData, !selected)
  }

  return (
    <Col xs="8" md="3" className={colClasses} onClick={selectCard}>
      <div className="m-0 mb-2">
        <div className="p-2">
          <FontAwesomeIcon icon="credit-card" />
          <span className="ms-2">{userName}</span>
        </div>

        <hr className="m-0 mb-2" />

        <p className="m-0 p-2">**** {lastFour}
          <span className="position-absolute pe-3 right">{expDate}</span>
        </p>
      </div>

      <div>
        {/* Commented by UX instructions
            <p className="m-0">{userAddress}</p> */}
      </div>
    </Col>
  )
}

const mapStateToProps = state => ({
  address: state.payment.address,
  address2: state.payment.address2,
  paymentCard: state.payment.card,
  zipCode: state.payment.zipCode,
})

const mapDispatchToProps = dispatch => ({
  setCardData: (card, selectedCard) => dispatch(paymentSetData({ card, selectedCard })),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(Card)
