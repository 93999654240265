import useRouter from 'Checkout/Hooks/useRouter'
import NoAvailabilityAlert from 'Checkout/Components/Shared/NoAvailabilityAlert'

const NoAvailabilitySites = () => {
  const router = useRouter()

  const goToRates = e => {
    e.preventDefault()
    router.go('/search')
  }

  return (
    <div className="no-availability-sites text-center">
      <NoAvailabilityAlert alertMessage="There're no sites available" />
      <a href="#rates" onClick={goToRates} className="link-theme-primary p-2 w-50">Back to rates</a>
    </div>
  )
}

export default NoAvailabilitySites
