import cnames from 'classnames'
import HalfRow from './HalfRow'

const FlexTwoColums = ({ children, className }) => {
  const classes = cnames('d-flex justify-content-between align-items-center', className)

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

FlexTwoColums.HalfRow = HalfRow

export default FlexTwoColums
