import { useEffect } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import { useSelector } from 'react-redux'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import { formatToday } from 'Utils/Dates'
import validationsQuery from './ValidationsQuery'

const useGuestRigValidations = () => {
  const { dayUse, rate, rigLength, rigWidth, site, siteType } = useSelector(state => state.search)
  const campgroundSlug = useSelector(state => state.campground.slug)
  const { checkIn, checkOut, validStayDates } = useStayDates()
  const [getValidations, { data, loading, error }] = useLazyQueryNoCache(validationsQuery)

  const defaultValidations = {
    guests: {
      adults: { max: 20 },
      maxPeople: 20, children: null, pets: null,
      useGuests: false,
    },

    rig: {
      length: { min: 10, max: 100 },
      slideouts: { min: 0, max: 5 },
      type: ['Class A RV', 'Class B RV', 'Class C RV', 'SURV Motorhome', 'SURV Trailer', 'Travel Trailer',
             'Fifth Wheel', 'Toy Hauler', 'Truck Camper', 'Pop Up Camper'],
      year: {
        min: 1910, max: parseInt(formatToday('YYYY')),
      },
      width: { min: 5, max: 50 },
    },
  }

  useEffect(() => {
    if (validStayDates && campgroundSlug && !data && rate)
      getValidations({ variables: { filters: { dayUse, maxLength: rigLength, maxWidth: rigWidth }, from: checkIn,
                                    rateId: rate.id, siteId: site?.id, siteType: siteType.type,
                                    slug: campgroundSlug, to: checkOut } })
  }, [checkIn, checkOut, campgroundSlug, rate])

  if (!data || error || loading) return { validationsLoading: loading || true, validations: defaultValidations }

  return { validationsLoading: loading, validations: data.campground.validations }
}

export default useGuestRigValidations
