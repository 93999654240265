import { Row } from 'reactstrap'
import ProductCard from './ProductCard/ProductCard'

const ProductCards = () =>
  <Row className="product-cards g-0 overflow-auto h-100 w-100">
    <ProductCard />
    <ProductCard />
    <ProductCard />
  </Row>

export default ProductCards
