import { useState } from 'react'
import storeConnect from 'Checkout/Redux/Connect'
import Anchor from 'Shared/Anchor/Anchor'
import ContactModal from 'Checkout/Components/ContactModal'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import Header from 'Checkout/Components/Shared/Header/Header'
import MainColumn from 'Checkout/Components/Shared/MainColumn/MainColumn'
import Section from 'Checkout/Components/Shared/Section/Section'
import NearCampgrounds from 'Checkout/Components/NearCampgrounds/NearCampgrounds'

const NotAvailable = ({ email, nearbyCampgrounds, phone }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const areNearbyCampgrounds = nearbyCampgrounds?.length > 0

  const openModal = () => setModalIsOpen(prevState => !prevState)

  return (
    <Section name="not-available">
      <MainColumn>
        <Header className="pt-3 px-lg-5" hr={false} showLogo={false} showMobileButtons={false} />

        <div className="mt-5 px-lg-5 text-center">
          <FontAwesomeIcon className="mb-2" color="#20628F" icon={['far', 'face-sad-cry']} size="6x" />

          <h4 className="mb-3 text-center">
            This campground is currently not available
          </h4>

          <p className="mb-4 pb-2">Please contact the campground for more information.</p>

          {(email || phone) && (
            <Anchor className="text-facebook text-decoration-none" onClick={openModal}>
              <FontAwesomeIcon className="me-2" icon="phone-flip" />
              See Contact Information
            </Anchor>
          )}
        </div>

        {areNearbyCampgrounds && (
          <NearCampgrounds campgrounds={nearbyCampgrounds} />
        )}

        <div className="text-center mt-4 pb-2">
          {areNearbyCampgrounds && <p>Also, you can</p>}

          <a className="btn btn-outline-dark-blue px-4" href="/">
            Keep browsing the Marketplace
          </a>
        </div>
      </MainColumn>

      <ContactModal email={email} isOpen={modalIsOpen} toggle={openModal} phone={phone} />
    </Section>
  )
}

const mapStateToProps = state => ({
  email: state.campground.email,
  nearbyCampgrounds: state.campground?.nearbyCampgrounds,
  phone: state.campground.phone,
})

export default storeConnect({ mapStateToProps })(NotAvailable)
