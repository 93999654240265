import { useEffect, useState } from 'react'
import useUnload from 'Checkout/Hooks/useUnload'
import useCheckoutParams from 'Checkout/Hooks/useCheckoutParams'
import useMetaTags from 'Checkout/Hooks/useMetaTags'
import storeConnect from 'Checkout/Redux/Connect'
import { detailsSetData } from 'Checkout/Redux/Actions/Details'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { setCheckoutStepViewed } from 'Checkout/Redux/Actions/TrackSegment'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import InitialState from 'Checkout/Redux/InitialState'
import { totalGuests } from 'Utils/guestHelpers'
import { clearSessionStorage } from 'Utils/SessionStorage'
import AccomodationOptions from 'Checkout/Components/Home/AccomodationOptions'
import DateSelection from 'Checkout/Components/Home/DateSelection'
import BrandedHR from 'Shared/BrandedHR/BrandedHR'
import Footer from 'Checkout/Components/Shared/Footer/Footer'
import Header from 'Checkout/Components/Shared/Header/Header'
import LightboxImages from 'Checkout/Components/Shared/LightboxImages'
import MainColumn from 'Checkout/Components/Shared/MainColumn/MainColumn'
import Section from 'Checkout/Components/Shared/Section/Section'
import SideColumn from 'Checkout/Components/Shared/SideColumn/SideColumn'
import SignInLink from 'Checkout/Components/Shared/SignInLink/SignInLink'
import SiteTypesSelector from 'Checkout/Components/Home/SiteTypesSelector/SiteTypesSelector'
import noCampgroundImg from './sample_bg_campground.png'
import './Home.scss'

const Home = ({ accommodation, campgroundImg, campgroundImages, campgroundName, checkIn, checkOut, dayUse,
                imageAlt, guests, setCheckoutStepViewed, setResetDetails, setRigSize }) => {
  const { validateSearchParams } = useCheckoutParams()
  const [lightboxState, setLightboxState] = useState(false)
  const bgStyle = campgroundImg || noCampgroundImg
  const { validStayDates } = useStayDates()

  const { adults, children, pets } = guests
  const metatags = { title: `Book Your Next Camping Trip at ${campgroundName} | RoverPass` }
  const currentTotalGuests = totalGuests({ adults, children, pets })

  useMetaTags(metatags)
  useUnload()

  const openLightbox = () => setLightboxState(true)

  const closeLightbox = () => setLightboxState(false)

  const resetRigSize = () => {
    setRigSize({ filters: [], rigLength: null, rigWidth: null })
  }

  useEffect(() => {
    validateSearchParams()
    resetRigSize()
    if (sessionStorage.length > 0) clearSessionStorage()
    if (currentTotalGuests) setResetDetails({ guests: InitialState.details.guests })
    setCheckoutStepViewed()
  }, [])

  return (
    <Section name="home">
      <MainColumn className="home__booking position-relative" style={{ background: bgStyle }} lg="5">
        <Header addressClass="h5" campgroundClass="h4" className="px-3 px-lg-0 py-4" hideLogoMobile={false}
                showCheckoutInfo={false} showMobileButtons={false} showMobileTitle={false} showSignIn={false}
                signInClass="d-md-none text-white with-shadow" />

        <BrandedHR className="my-4 d-none d-md-block" opacity="0.5" width="2" />

        {accommodation ? (
          <DateSelection {...{ checkIn, checkOut, dayUse }} />
        ) : (
          <AccomodationOptions />
        )}

        {validStayDates && <SiteTypesSelector />}

        <Footer />
      </MainColumn>

      <SideColumn className="home__lightbox ps-0" lg="7">
        <a href="#gallery" onClick={openLightbox} aria-label="View gallery">
          <img className="d-block h-100 image-clickable w-100" src={bgStyle} alt={imageAlt} />
        </a>
      </SideColumn>

      <SignInLink className="p-3 text-white with-shadow absolute-right d-none d-md-flex" />

      {campgroundImages && lightboxState && (
        <LightboxImages images={campgroundImages} closeLightbox={closeLightbox} />
      )}
    </Section>
  )
}

const mapStateToProps = state => ({
  accommodation: state.search.accommodation,
  campgroundImg: state.campground.pictureUrl,
  campgroundImages: state.campground.pictureUrls,
  campgroundName: state.campground.name,
  checkIn: state.search.checkIn,
  checkOut: state.search.checkOut,
  dayUse: state.search.dayUse,
  guests: state.details.guests,
  imageAlt: state.campground.pictureAlt,
})

const mapDispatchToProps = dispatch => ({
  setCheckoutStepViewed: () => dispatch(setCheckoutStepViewed(1)),
  setResetDetails: data => dispatch(detailsSetData(data)),
  setRigSize: data => dispatch(searchFilterUpdate(data)),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(Home)
