import cnames from 'classnames'

const HalfRow = ({ children, className, withRightBorder }) => {
  const classes = cnames('w-50', className, { 'border-end': withRightBorder })

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default HalfRow
