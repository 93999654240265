import PropTypes from 'prop-types'
import storeConnect from 'Checkout/Redux/Connect'
import { fillArrayWithMinToMax } from 'Utils/Arrays'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import FormSelect from 'Shared/Formik/FormSelect'
import SelectOptions from 'Shared/SelectOptions/SelectOptions'
import './SizeFilterInput.scss'

const SizeFilterInput = ({ name, placeholder, rangeOptions, setFieldValue, setRigSize }) => {
  const handleSetSize = event => {
    const { name, value } = event.target
    const sizeNumber = parseInt(value.replace(/\D/g, '')) || null

    setFieldValue({ [name]: sizeNumber || 0 })
    setRigSize({ [name]: sizeNumber })
  }

  return (
    <FormSelect className="size-filter mb-0" defaultMessage={placeholder} defaultValue={0} id={name}
                inputClasses="form-control form-control-lg size-filter__input no-arrow" isNormalSelect name={name}
                onChange={handleSetSize} removeBrowserStyle>
      <SelectOptions data={fillArrayWithMinToMax(rangeOptions)} isPluralize={false} label="ft." />
    </FormSelect>
  )
}

SizeFilterInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  rangeOptions: PropTypes.shape({
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setRigSize: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  setRigSize: data => dispatch(searchFilterUpdate(data)),
})

export default storeConnect({ mapDispatchToProps })(SizeFilterInput)
