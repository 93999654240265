import store from 'Checkout/Redux/Store'

const reduxGetData = () => {
  const reduxStore = store.getState()
  const { urls } = reduxStore.options

  return { reduxStore, urls }
}

export { reduxGetData }
