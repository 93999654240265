export const skeletonSites = [
  {
    amenities: ['back_in', 'amps_30', 'amps_50', 'water_hookups'],
    description: 'viverra suspendisse',
    id: 1,
    maxLength: 10,
    maxPeople: 6,
    maxWidth: 8,
    name: 'Fake Site',
    pictureSizes: {
      listUrl: 'KEEP_LOADING',
      mapUrl: null,
    },
    picturesUrl: [],
  },
  {
    amenities: ['back_in', 'amps_30', 'amps_50', 'water_hookups', 'sewer_hookups'],
    description: 'viverra suspendisse potenti nullam ac tortor vitae purus',
    id: 2,
    maxLength: 10,
    maxPeople: 6,
    maxWidth: 8,
    name: 'Fake Site 2',
    pictureSizes: {
      listUrl: null,
      mapUrl: null,
    },
    picturesUrl: [],
  },
]
