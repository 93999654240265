import { useCallback, useEffect, useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { clearSessionStorage } from 'Utils/SessionStorage'
import { isEmpty } from 'Utils/objects'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import { validateForm } from './PaymentDataValidation'
import storeConnect from 'Checkout/Redux/Connect'
import useErrors from 'Checkout/Hooks/useErrors'
import useReservationCreate from 'Checkout/Hooks/useReservationCreate/useReservationCreate'
import useRouter from 'Checkout/Hooks/useRouter'
import AcceptTermsCheckbox from 'Checkout/Components/Payment/AcceptTermsCheckbox/AcceptTermsCheckbox'
import AlertErrors from 'Shared/Alert/AlertErrors'
import BillingAddress from 'Shared/BillingAddress'
import DiscountWrap from 'Checkout/Components/Payment/DiscountWrap'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import PoliciesWrap from 'Checkout/Components/Shared/PoliciesWrap'
import PaymentMethod from 'Checkout/Components/Payment/PaymentMethod/PaymentMethod'
import PaymentSection from 'Checkout/Components/Payment/PaymentSection/PaymentSection'
import PaymentViewSchema from './PaymentViewSchema'
import ReviewYourOrder from 'Checkout/Components/Shared/ReviewYourOrder'
import TripInsuranceWrapper from 'Checkout/Components/Payment/TripInsuranceWrapper/TripInsuranceWrapper'

const INITIAL_FORM_VALUES = { acknowledgement: false, address: '', address2: '', city: '', country: '',
                              discount: { id: '', numberRequired: false }, homeAddress: '', state: '',
                              selectedCard: null, tripInsurance: '' }

const PaymentForm = ({ acknowledgement, address, address2, card, city, dayUse, discount, firstName, homeAddress,
                       isNonRefundable, lastName, reservationPolicy, selectedCard, setCardData, setPaymentData,
                       state, isTripProtection }) => {
  const ERROR_SOLD_OUT = 'Sold out'
  const [errors, setErrors] = useState({})
  const [elementCard, setElementCard] = useState()
  const [newCard, setNewCard] = useState({ empty: true })
  const [tempNewCard, setTempNewCard] = useState({ empty: true })
  const [requestingCardToken, setRequestingCardToken] = useState(false)
  const [createReservation, setCreateReservation] = useState({ formikValues: {}, hasStarted: false })
  const [createReservationMethod, reservationData, reservationError, reservationLoading] = useReservationCreate()
  const { updateGlobalError } = useErrors()

  const router = useRouter()
  const stripe = useStripe()
  const elements = useElements()
  const refCallback = useCallback(element => setElementCard(element), [])

  const stripeElementStyle = { style: { base: { fontSize: '16px' } } }
  const cardholderName = [firstName, lastName].join(' ')
  const processingPayment = requestingCardToken || reservationLoading
  const savedValues = { acknowledgement, address, address2, city, country: '', discount, homeAddress, state,
                        selectedCard, tripInsurance: '' }

  const initToCreateReservation = () => {
    if (newCard.empty || selectedCard) return validateFormData()

    paymentMethodPromise(createReservation.formikValues)
      .then(data => {
        setRequestingCardToken(false)
        processNewCard(data)
      })
  }

  const isSoldOutError = error => error === ERROR_SOLD_OUT

  const goNext = uuid => router.next({ template: { uuid } })

  const goToSearch = () => {
    const search = { rate_id: '', rig_length: '', rig_width: '' }
    router.go({ pathname: '/search', search })
  }

  const validationSchema = () => PaymentViewSchema({ isTripProtection })

  useEffect(() => {
    if (Object.keys(errors).length && !selectedCard)
      elementCard.focus()
  }, [errors])

  useEffect(() => {
    if (selectedCard) {
      setErrors({})
      setNewCard({ empty: true })
    } else
      setNewCard(tempNewCard)
  }, [selectedCard])

  useEffect(() => {
    if (reservationData?.createReservation) {
      const { reservation } = reservationData.createReservation
      if (reservation.id) {
        clearSessionStorage()
        setPaymentData({ isPurchaseComplete: true })
        goNext(reservation.uuid)
      }
    }
  }, [reservationData])

  useEffect(() => {
    if (reservationError) {
      setErrors({ createReservation: reservationError })

      if (isSoldOutError(reservationError)) {
        updateGlobalError(reservationError)
        return goToSearch()
      }
    }
  }, [reservationError])

  useEffect(() => {
    if (createReservation.hasStarted) initToCreateReservation()
  }, [createReservation.hasStarted])

  async function createPaymentMethod(resolve, values) {
    const additionalData = { address_city: values?.city, address_country: values?.country,
                             address_line1: values?.address, address_line2: values?.address2,
                             address_state: values?.state, name: cardholderName }
    const { token, error } = await stripe.createToken(elements.getElement(CardElement), additionalData)

    resolve({ token, error })
  }

  const resetSelectedCard = data => {
    if (!isEmpty(errors)) setErrors({})
    setTempNewCard(data)
    if (!selectedCard) {
      setNewCard(data)
      if (Object.keys(card).length) setCardData({})
    }
  }

  function paymentMethodPromise(values) {
    setRequestingCardToken(true)
    return new Promise(resolve => {
      createPaymentMethod(resolve, values)
    })
  }

  const processFormData = values => {
    setPaymentData(values)
    setCreateReservation({ formikValues: values, hasStarted: true })
  }

  function processNewCard({ token, error }) {
    let cardData = null
    let errorData = {}

    if (!selectedCard) {
      if (token) cardData = storeNewCard(token)
      if (error) {
        errorData = { newCard: error.message }
        setErrors(errorData)
      }

      // NOTE: We pass card data here bc validate form is called before the re-render and
      //       the new card data is not available yet.
        validateFormData(cardData, errorData)
    }
  }

  function storeNewCard(token) {
    const { id: tokenId, card } = token
    const { exp_month: expMonth, exp_year: expYear, last4 } = card
    const data = { token: tokenId, lastFour: last4, expDate: `${expMonth}/${expYear}` }

    if (!selectedCard)
      setCardData(data)

    return data
  }

  function validateFormData(cardData = null, errorData = {}) {
    if (!cardData) cardData = card
    const { valid, errors: formErrors } = validateForm({ newCard, card: cardData })
    if (valid)
      // NOTE: We need to send cardData bc it is not in store yet
      createReservationMethod(!selectedCard ? cardData : card)


    setErrors({ ...errorData, ...formErrors })
    setCreateReservation({ formikValues: {}, hasStarted: false })
  }

  return (
    <FormWithValidations className="my-3" formId="payment-form" ignoreStorageItems={{ selectedCard: null }}
                         initialValues={INITIAL_FORM_VALUES} keySessionStorage="payment-form"
                         savedValues={savedValues} onSubmit={processFormData} saveSessionStorage scrollToError
                         validationSchema={validationSchema}>
      {({ setFieldValue, values }) => {
        useEffect(() => {
          setFieldValue('selectedCard', selectedCard)
        }, [selectedCard])

        return (
          <>
            {!isEmpty(errors) && <AlertErrors {...{ errors }} className="mt-4" withScroll />}

            <DiscountWrap formikValues={values} />

            <PaymentMethod errors={errors}>
              {!selectedCard && (
                <CardElement className="payment-stripe-input text-bigger-1" onChange={resetSelectedCard}
                             onReady={refCallback} options={stripeElementStyle} />
              )}
            </PaymentMethod>

            {!selectedCard && (
              <BillingAddress className="mb-4" isFloating />)}

            <hr />

            <div className="d-md-none d-block">
              <ReviewYourOrder classTitle="py-2 text-gray" showSite userDetails />
              <hr />
            </div>

            {!dayUse && <TripInsuranceWrapper />}

            <PoliciesWrap applyReservationPolicy={isNonRefundable} reservationPolicy={reservationPolicy}
                          showTitle>
              <AcceptTermsCheckbox />

              <PaymentSection processingPayment={processingPayment} />
            </PoliciesWrap>
          </>
        )
      }}
    </FormWithValidations>
  )
}

const mapStateToProps = state => ({
  acknowledgement: state.payment.acknowledgement,
  address: state.payment.address,
  address2: state.payment.address2,
  card: state.payment.card,
  city: state.payment.city,
  dayUse: state.search.dayUse,
  discount: state.payment.discount,
  firstName: state.details.firstName,
  homeAddress: state.payment.homeAddress,
  isNonRefundable: state.payment.isNonRefundable,
  lastName: state.details.lastName,
  reservationPolicy: state.payment.nonRefundableDisclaimer,
  selectedCard: state.payment.selectedCard,
  state: state.payment.state,
  isTripProtection: state.payment.isTripProtection,
})

const mapDispatchToProps = dispatch => ({
  setCardData: card => dispatch(paymentSetData({ card })),
  setPaymentData: data => dispatch(paymentSetData(data)),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(PaymentForm)
