// recommended by webpacker README
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Import dependencies
import 'react-popper'
import 'react-dates/initialize'
import 'Shared/timemachine'

import { BrowserRouter as Router } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { loadStripe } from '@stripe/stripe-js'
import { Provider } from 'react-redux'
import { reduxGetData } from 'Checkout/Utils/sentry'
import { sentrySetup } from 'Utils/errorNotifications'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import store from 'Checkout/Redux/Store'
import App from 'Checkout/Containers/App/App'
import ErrorBoundary from 'Shared/ErrorBoundary/ErrorBoundary'
import ErrorView from 'Checkout/Components/Shared/ErrorView'
import 'Checkout/Components/Shared/FontAwesomeLib'

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('react-data-container')
  const root = createRoot(container)
  const { campgroundSlug, referringDomain, referringSource, stripeKey, sentryDsn, userToken, url, utmCampaign,
          utmContent, utmMedium, utmSource, utmTerm } = container.dataset
  const utmParams = { utmCampaign, utmContent, utmMedium, utmSource, utmTerm, url }
  const stripePromise = loadStripe(stripeKey)
  const marketplace = { isMarketplace: referringSource === 'marketplace', referringDomain }

  sentrySetup(sentryDsn)

  window.roverData = { ...container.dataset, stripePromise }
  const basename = window.roverData.checkoutPath

  root.render(
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <Router basename={basename}>
          <ErrorBoundary Component={ErrorView} getExtraData={reduxGetData}>
            <App campgroundSlug={campgroundSlug} marketplace={marketplace} userToken={userToken}
                 utmParams={utmParams} />
          </ErrorBoundary>
        </Router>
      </Provider>
    </ApolloProvider>
  )
})
