import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { ValidationsPropTypes } from 'Checkout/PropTypes'
import { formatDate } from 'Utils/Dates'
import useRouter from 'Checkout/Hooks/useRouter'
import useSiteLock from 'Checkout/Hooks/useSiteLock'
import storeConnect from 'Checkout/Redux/Connect'
import { isRvAdequateForRig } from 'Checkout/Utils/rvHelpers'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import SizeFilter from '../SizeFilter/SizeFilter'

const RigSizeModal = ({ canChooseSite, campgroundSlug, canUseSiteLock, checkIn, checkOut,
                        handleRefetchRates, rate, rigLength, rigSizeModal, rigWidth, setRate,
                        setRigSizeModal, setSiteLockModal, sitesMapUrl, siteType, validations }) => {
  const router = useRouter()
  const { selectSiteLock } = useSiteLock({ goSites: true })
  const [checkSiteLock, setCheckSiteLock] = useState(false)
  const [isRvFitsRateSize, setIsRvFitsRateSize] = useState(false)

  const labelButton = isRvFitsRateSize ? 'Submit' : 'Check Availability'

  const goNext = () => {
    const search = { check_in: formatDate(checkIn), check_out: formatDate(checkOut), site_type: siteType.type,
                     rate_id: rate.id || '', rig_length: rigLength || '', rig_width: rigWidth || '' }
    router.next({ search, canChooseSite, canUseSiteLock })
  }

  const checkRatesAvailability = () => {
    const ratesQueryArgs = { checkIn: formatDate(checkIn), checkOut: formatDate(checkOut), siteType: siteType.type,
                             slug: campgroundSlug, rigLength: String(rigLength), rigWidth: String(rigWidth) }
    handleRefetchRates(ratesQueryArgs)
    setRate({ rate: null })
    setRigSizeModal(false)
  }

  const onCloseModal = () => {
    if (!checkSiteLock) return
    if (canUseSiteLock && sitesMapUrl) return selectSiteLock(true)

    if (canUseSiteLock) return setSiteLockModal(true)
    goNext()
  }

  const onSubmit = () => {
    setCheckSiteLock(true)
    setRigSizeModal(false)
  }

  const toggleModal = () => setRigSizeModal(!rigSizeModal)

  useEffect(() => {
    if (rigSizeModal) {
      const { maxLength, maxWidth } = validations
      setIsRvFitsRateSize(isRvAdequateForRig({ rigLength, rigWidth, maxLength, maxWidth }))
    } else setIsRvFitsRateSize(true)
  }, [rigLength, rigWidth, rigSizeModal])

  return (
    <Modal id="rig-size-modal" className="modal-dialog-centered modal-md" isOpen={rigSizeModal}
           onClosed={onCloseModal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} className="border-0 py-3" />
      <ModalBody className="m-0 mx-md-4 px-md-5 pt-0 text-center">
        <h5 className="mb-3 fw-bold">
          Enter Rig Size to Proceed
          <small className="d-block fs-6 fw-normal mt-2">
            Your rig dimensions are required to view available sites.
          </small>
        </h5>

        <SizeFilter className="mt-1 mx-auto py-0" classErrors="mb-0 mt-3" isRvFitsRateSize={isRvFitsRateSize}
                    checkRatesAvailability={checkRatesAvailability} onSubmit={onSubmit}
                    validations={validations}>
          <div className="text-center mb-3">
            <Button className="btn-theme-primary mt-4 px-5 py-1 fw-bold" type="submit">
              {labelButton}
            </Button>
          </div>
        </SizeFilter>
      </ModalBody>
    </Modal>
  )
}

RigSizeModal.defaultProps = {
  campgroundSlug: null,
  canChooseSite: null,
  canUseSiteLock: null,
  checkIn: null,
  checkOut: null,
  rate: null,
  rigLength: null,
  rigWidth: null,
  siteType: null,
}

RigSizeModal.propTypes = {
  campgroundSlug: PropTypes.string,
  canChooseSite: PropTypes.bool,
  canUseSiteLock: PropTypes.bool,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  handleRefetchRates: PropTypes.func.isRequired,
  rate: PropTypes.shape({
    id: PropTypes.string,
    maxLength: PropTypes.string,
    maxWidth: PropTypes.string,
  }),
  rigLength: PropTypes.number,
  rigSizeModal: PropTypes.bool.isRequired,
  rigWidth: PropTypes.number,
  setRate: PropTypes.func.isRequired,
  setRigSizeModal: PropTypes.func.isRequired,
  setSiteLockModal: PropTypes.func.isRequired,
  siteType: PropTypes.shape({ type: PropTypes.string }),
  validations: ValidationsPropTypes.isRequired,
}

const mapStateToProps = state => ({
  campgroundSlug: state.campground.slug,
  canChooseSite: state.campground.canChooseSite,
  canUseSiteLock: state.campground.canUseSiteLock,
  checkIn: state.search.checkIn,
  checkOut: state.search.checkOut,
  rate: state.search.rate,
  rigLength: state.search.rigLength,
  rigSizeModal: state.search.rigSizeModal,
  rigWidth: state.search.rigWidth,
  sitesMapUrl: state.campground.sitesMapUrl,
  siteType: state.search.siteType,
})

const mapDispatchToProps = dispatch => ({
  setRate: rate => dispatch(searchFilterUpdate(rate)),
  setRigSizeModal: rigSizeModal => dispatch(searchFilterUpdate({ rigSizeModal })),
  setSiteLockModal: siteLockModal => dispatch(searchFilterUpdate({ siteLockModal })),
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(RigSizeModal)
