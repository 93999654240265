import { Row } from 'reactstrap'
import classNames from 'classnames'
import Cards from 'Checkout/Components/Payment/Cards'
import CardSlider from 'Checkout/Components/Payment/CardSlider'

const SavedCards = ({ cards, errors }) => {
  const cardsClasses = classNames('g-0', { 'has-error': !!errors.paymentMethod })

  return (
    <>
      <Row className={cardsClasses}>
        <div className="m-0 w-100 d-md-flex d-none">
          <Cards cards={cards} />
        </div>

        <CardSlider cards={cards} />
      </Row>
    </>
  )
}

export default SavedCards
