import ReservationDetailItem from 'Shared/ReservationDetailItem'

const TotalRow = ({ balanceDue, balancePaid, balanceRefunded, balanceRemaining, balanceTotal, hasBalance,
                     pricing }) => {
  const { dueNow, camperTotal } = pricing

  const texRemaining = hasBalance ? 'Remaining Balance' : 'Due Upon Check In'

  const balanceInfo = () => {
    if (balancePaid?.amount > 0) return { text: 'Paid', value: balancePaid?.display }
    if (hasBalance) return { text: 'Due', value: balanceDue?.display }

    return { text: 'Due Now', value: balanceDue?.display }
  }

  const balanceData = balanceInfo()

  return (
    <div className="my-3 my-md-4">
      <ReservationDetailItem containerClass="mt-4" itemName="Reservation Total" itemNameClass="h4 text-salmon"
                             value={balanceTotal?.displayWithCode} valueClass="h4 text-salmon pe-0 text-end" />

      {(hasBalance || (dueNow !== camperTotal?.display)) && (
        <>
          <hr />

          <ReservationDetailItem containerClass="mt-4" itemName={balanceData.text} value={balanceData.value}
                                 valueClass="text-gray" />

          {balanceRefunded?.amount > 0 && (
            <ReservationDetailItem containerClass="mt-4" itemName="Refunded" value={balanceRefunded?.display}
                                   valueClass="text-gray" />
          )}

          <ReservationDetailItem containerClass="mt-4" itemName={texRemaining} itemNameClass="h4"
                                 value={balanceRemaining?.display} valueClass="h4 text-gray" />
        </>
      )}
  </div>
  )
}

export default TotalRow
