import pluralize from 'pluralize'
import { numberOfNights } from 'Utils/Dates'

export const buildAlerts = ({ checkIn, checkOut, daysBookingTogether, daysRequiredByNbt, ratesAvailableDuring,
                              unavailableRatesNbtRequiredCount, unavailableDueToHolidays,
                              unavailableRatesMinNightsCount }) => {
  const alerts = []
  let message = ''

  if (unavailableRatesNbtRequiredCount > 0 && ratesAvailableDuring.length > 0) {
    message = `${unavailableRatesNbtRequiredCount} additional
              ${pluralize('rate', unavailableRatesNbtRequiredCount)}
              ${pluralize('are', unavailableRatesNbtRequiredCount)} available if your stay includes
              ${daysRequiredByNbt.join(', ')} ${pluralize('night', daysRequiredByNbt.length)}.`

    alerts.push({ type: 'warning', message })
  } else if (unavailableRatesNbtRequiredCount > 0) {
    message = `${daysBookingTogether.join(', ')} days must be booked together, to proceed please add
              ${daysRequiredByNbt.join(', ')} to this reservation.`

    alerts.push({ type: 'danger', message })
  }

  if (unavailableRatesMinNightsCount > 0) {
    message = `${unavailableRatesMinNightsCount} additional rates are available if your stay is longer
               than ${pluralize('night', numberOfNights(checkIn, checkOut), true)}.`

    alerts.push({ type: 'warning', message })
  }

  if (unavailableDueToHolidays.total > 0) {
    const { total, from, to } = unavailableDueToHolidays
    message = `You can see ${total} additional ${pluralize('rate', total, false)}
               if you stay from ${from} to ${to}.`

    if (total && from && to) alerts.push({ type: 'warning', message })
  }

  return alerts
}
