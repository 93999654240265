import Confirmation from 'Checkout/Containers/Confirmation/Confirmation'
import Details from 'Checkout/Containers/Details/Details'
import Home from 'Checkout/Containers/Home/Home'
import Payment from 'Checkout/Containers/Payment/Payment'
import Search from 'Checkout/Containers/Search/Search'
import Sites from 'Checkout/Containers/Sites/Sites'
import NotFound from 'Checkout/Components/NotFound/NotFound'
import { routesHandler } from './routesHandler'

const routes = {}
const { checkout, confirmation, home, search, sites, payment } = routesHandler

routes.home = {
  component: Home,
  key: home.key,
  name: home.name,
  props: { exact: true, path: home.path },
  getNext: () => routes.search,
  getPrev: () => routes.home,
}

routes.search = {
  component: Search,
  key: search.key,
  name: search.name,
  props: { exact: true, path: search.path },
  getNext: () => routes.sites,
  getPrev: () => routes.home,
}

routes.sites = {
  component: Sites,
  key: sites.key,
  name: sites.name,
  props: { exact: true, path: sites.path },
  getNext: () => routes.checkout,
  getPrev: () => routes.search,
}

routes.checkout = {
  component: Details,
  key: checkout.key,
  name: checkout.name,
  props: { exact: true, path: checkout.path },
  getNext: () => routes.payment,
  getPrev: () => routes.sites,
}

routes.payment = {
  component: Payment,
  key: payment.key,
  name: payment.name,
  props: { exact: true, path: payment.path },
  getNext: () => routes.confirmation,
  getPrev: () => routes.checkout,
}

routes.confirmation = {
  component: Confirmation,
  key: confirmation.key,
  name: confirmation.name,
  props: { exact: true, path: confirmation.path },
  showAlways: true,
  getNext: () => routes.confirmation,
  getPrev: () => routes.home,
}

routes.notFound = {
  component: NotFound,
  key: 'not-found',
  props: { path: '/' },
  getNext: () => routes.home,
  getPrev: () => routes.home,
}

export default routes
