import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import PropTypes from 'prop-types'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'

const ContactModal = ({ email, isOpen, phone, toggle }) => (
  <Modal id="contact-modal" isOpen={isOpen} toggle={toggle} centered size="md">
    <ModalHeader toggle={toggle}>
      Campground Contact Info
    </ModalHeader>

    <ModalBody className="text-center">
      {email && (
        <div className="mb-5">
          <p className="mb-1"><FontAwesomeIcon icon={['far', 'envelope']} /></p>

          <u>{email}</u>
        </div>
      )}

      {phone && (
        <div className="mb-4">
          <p className="mb-1"><FontAwesomeIcon icon="phone" /></p>

          <u>{phone}</u>
        </div>
      )}
    </ModalBody>
  </Modal>
)

ContactModal.defaultProps = {
  email: '',
  phone: '',
}

ContactModal.propTypes = {
  email: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  phone: PropTypes.string,
  toggle: PropTypes.func.isRequired,
}

export default ContactModal
