import DailyAccommodation from 'Checkout/Components/Home/DailyAccommodation/DailyAccommodation'
import PageTitle from 'Checkout/Components/Shared/PageTitle/PageTitle'
import StayPicker from 'Checkout/Components/Shared/StayPicker/StayPicker'

const DateSelection = ({ checkIn, checkOut, dayUse }) => {
  if (dayUse) return <DailyAccommodation checkIn={checkIn} />

  return (
    <>
      <PageTitle className="d-none d-md-block text-bigger-5" text="Search for Availability" />

      <StayPicker checkInValue={checkIn} checkOutValue={checkOut} />
    </>
  )
}

export default DateSelection
