import { useEffect } from 'react'
import { Button } from 'reactstrap'
import { parsePhoneNumber } from 'react-phone-number-input'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import useBannedGuest from 'Checkout/Hooks/useBannedGuest'
import useRouter from 'Checkout/Hooks/useRouter'
import useCustomFields from 'Shared/Hooks/useCustomFields'
import storeConnect from 'Checkout/Redux/Connect'
import DetailsViewSchema from './DetailsViewSchema'
import marketingPreferenceMutation from './MarketingPreferenceMutation'
import { isVehicleSelected } from 'Checkout/Utils/rvHelpers'
import { detailsSetData } from 'Checkout/Redux/Actions/Details'
import { setIdentifyUser } from 'Checkout/Redux/Actions/TrackSegment'
import { skeletonCustomFields } from './skeletonCustomFields'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import ContactInformation from '../ContactInformation/ContactInformation'
import EditReservationButton from 'Checkout/Components/Shared/EditReservationButton/EditReservationButton'
import GuestInformation from '../GuestInformation/GuestInformation'
import Loading from 'Shared/Loading'
import MoreInformation from 'Shared/CustomFields/MoreInformation'
import Products from 'Checkout/Containers/Products/Products'
import SpecialInformation from '../SpecialInformation'
import VehicleInformation from '../VehicleInformation/VehicleInformation'

// TODO: add setPaymentData prop when we set AddressInformation again
const CamperForm = ({ allowMarketing, address, address2, camperId, city, customFields, dayUse, email, firstName,
                      guests, isRequest, lastName, rate, rigLength, rigWidth, setDetails, setIdentifyUser,
                      slug, specialRequests, state, phone, productsPurchased, useGuests, validations, vehicle,
                      zipCode }) => {
  const router = useRouter()
  const [getCustomFields, { customFieldsData, loadingFieldData }] = useCustomFields(customFields)
  const customFieldsDataToShow = loadingFieldData ? skeletonCustomFields : customFieldsData.data
  const [updateMarketingPreference] = useMutation(marketingPreferenceMutation)
  const [handleBannedGuest, { guestData, loading }] = useBannedGuest()
  const phoneObject = phone && parsePhoneNumber(phone)
  const { guests: guestValidations } = validations || {}
  const { checkIn, checkOut } = useStayDates()

  useEffect(() => {
    const customFieldsArgs = { variables: { isPos: false, slug } }
    getCustomFields(customFieldsArgs)
  }, [])

  const initialValues = { allowMarketing, address: address || '', address2: address2 || '', city: city || '',
                          customFields, email, firstName, guests, lastName, phone: phone || '',
                          productsPurchased: productsPurchased || [], rigRequired: rate?.rigRequired || false,
                          specialRequests, state: state || '', useGuests, vehicle, zipCode: zipCode || '' }

  const completeForm = values => {
    delete values.rigRequired
    // const { address, address2, city, state, zipCode } = values
    setDetails(values)
    // setPaymentData({ address, address2, city, state, zipCode })
    // Note: we need to validate the guest email before to continue
    handleBannedGuest({ email: values.email, from: checkIn, to: checkOut })
  }

  const goNext = () => {
    const search = { rig_length: rigLength, rig_width: rigWidth }
    router.next({ search })
  }

  const sendMarketingPreference = ({ allowMarketing, email }) => {
    const operation = allowMarketing ? 'subscribe' : 'unsubscribe'
    const marketingPreferenceArgs = { variables: { email, operation, slug } }

    updateMarketingPreference(marketingPreferenceArgs)
  }

  const validationSchema = () => DetailsViewSchema({ canAddVehicle: rate?.canAddVehicle || false, isRequest,
                                                     rigLength, rigWidth, validations })

  useEffect(() => {
    if (guestData !== undefined && !loading)
      if (!guestData?.bannedGuestDetection?.isBanned) {
        if (!camperId || !isVehicleSelected(vehicle))
          sendMarketingPreference({ allowMarketing, email })
        if (!camperId)
          setIdentifyUser({ createdAt: '', email, firstName, id: '', lastName })

        goNext()
      }
  }, [guestData, loading])

  return (
    <div className="camper-form">
      <FormWithValidations enableReinitialize={false} initialValues={initialValues}
                           keySessionStorage="details-form" onSubmit={completeForm} saveSessionStorage
                           saveSessionStorageExtraData={{ productsPurchased }} scrollToError
                           validationSchema={validationSchema}>
        {({ setFieldValue, values }) => {
          useEffect(() => {
            if (customFieldsData.isCompleted)
              setFieldValue('customFields', customFieldsData.data)
          }, [customFieldsData.isCompleted])

          return (
            <>
              {!dayUse && (
                <>
                  <GuestInformation formikValues={values} guestValidations={validations}
                                    setFieldValue={setFieldValue} />
                  <hr />
                </>
              )}

              <ContactInformation defaultPhoneCountry={phoneObject?.country} formikValues={values}
                                  sendMarketingPreference={sendMarketingPreference} />
              <hr />

              {(rate?.canAddVehicle || guestValidations?.extraVehicles?.max) && (
                <>
                  <VehicleInformation setFieldValue={setFieldValue} validations={validations} />

                  <hr />
                </>
              )}

              <Products setFieldValue={setFieldValue} showProductsInDetails />

              <MoreInformation customFieldsData={customFieldsDataToShow} loading={loadingFieldData}
                               setFieldValue={setFieldValue} showDivider />

              {!dayUse && (
                <>
                  <SpecialInformation />

                  <hr />
                </>
              )}

              <Button type="submit" disabled={loading} className="btn btn-theme-primary btn-lg mb-5">
                {loading && <Loading className="d-inline me-2" iconClass="text-white" size="1x" />}
                Continue to Payment
              </Button>
            </>
            )
          }}
      </FormWithValidations>

      <EditReservationButton
        editBtnClass="align-items-center d-flex d-lg-none flex-row-reverse justify-content-center w-100" />
    </div>
  )
}

const mapStateToProps = state => ({
  address: state.details.address,
  address2: state.details.address2,
  allowMarketing: state.details.allowMarketing,
  camperId: state.camper.id,
  city: state.details.city,
  customFields: state.details.customFields,
  dayUse: state.search.dayUse,
  email: state.details.email,
  firstName: state.details.firstName,
  guests: state.details.guests,
  isRequest: state.campground.isRequest,
  lastName: state.details.lastName,
  phone: state.details.phone?.display,
  productsPurchased: state.details.productsPurchased,
  rate: state.search.rate,
  rigLength: state.search.rigLength,
  rigWidth: state.search.rigWidth,
  slug: state.campground.slug,
  specialRequests: state.details.specialRequests,
  state: state.details.state,
  useGuests: state.details.useGuests,
  vehicle: state.details.vehicle,
  zipCode: state.details.zipCode,
})

const mapDispatchToProps = dispatch => ({
  setIdentifyUser: data => dispatch(setIdentifyUser(data)),
  setDetails: data => dispatch(detailsSetData(data)),
  // setPaymentData: (data) => dispatch(paymentSetData(data))
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(CamperForm)
