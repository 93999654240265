import classNames from 'classnames'
import EditReservationButton from 'Checkout/Components/Shared/EditReservationButton/EditReservationButton'
import ReservationInfo from 'Checkout/Components/ReservationDetails/ReservationInfo'
import ReservationTotal from 'Checkout/Components/ReservationDetails/ReservationTotal/ReservationTotal'
import UserDetails from 'Checkout/Components/ReservationDetails/UserDetails/UserDetails'

const ReviewYourOrder = ({ classTitle, isDesktopView, showCompleteDetails, showSite, userDetails }) => {
  const titleClasses = classNames('fw-bold m-0', classTitle)

  return (
    <>
      <div className="d-lg-flex align-items-center justify-content-between mb-3">
        <h4 className={titleClasses}>Your Reservation</h4>
        {(showCompleteDetails && isDesktopView) && <EditReservationButton />}
      </div>

      {userDetails && <UserDetails />}
      <ReservationInfo showSite={showSite} />
      {showCompleteDetails && <ReservationTotal />}
    </>
  )
}

ReviewYourOrder.defaultProps = {
  classTitle: 'py-4',
  userDetails: false,
}

export default ReviewYourOrder
