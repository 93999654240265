import cnames from 'classnames'
import storeConnect from 'Checkout/Redux/Connect'

const InfoTripInsurance = ({ className, importantDisclosuresUrl, showFirstParagraph,
                             termsAndConditionsClass }) => {
  const classes = { infoTripInsurance: cnames('info-trip-insurance', { 'text-smaller-2': !className }, className),
                    termsAndConditions: cnames(termsAndConditionsClass) }

  return (
    <div className={classes.infoTripInsurance}>
      {showFirstParagraph && (
        <p className="lh-sm text-gray-700 mb-0">
          The plan cost includes the travel insurance premium and assistance services fee. Travel insurance
          coverages are underwritten by: Generali U.S. Branch, New York, NY; NAIC # 11231, for the operating name
          used in certain states, and other important information about the Travel Insurance & Assistance Services
          Plan, please see

          <a className="ms-1 text-decoration-none" href={importantDisclosuresUrl} rel="noreferrer" target="_blank">
            Important Disclosures.
          </a>
        </p>
      )}
    </div>
  )
}

InfoTripInsurance.defaultProps = {
  showFirstParagraph: true,
  travelProtectionUrl: true,
}

const mapStateToProps = state => ({
  importantDisclosuresUrl: state.options.externalLinks.tripInsuranceDisclosures,
})

export default storeConnect({ mapStateToProps })(InfoTripInsurance)
