import { Col, Row } from 'reactstrap'
import FloatingLabelInput from 'Shared/FloatingLabelInput/FloatingLabelInput'
import PageSubtitle from 'Shared/PageSubtitle'
import StateDropDown from 'Shared/StateDropDown/StateDropDown'
import 'Styles/Text.scss'
import './AddressInformation.scss'

const AddressInformation = () =>
  <div className="address-information mb-3">
    <PageSubtitle text="Home Address" />
    <Row>
      <Col md="6">
        <FloatingLabelInput label="Home Address" name="homeAddress.address" />
      </Col>

      <Col md="6">
        <FloatingLabelInput label="Address 2" name="homeAddress.address2" />
      </Col>

      <Col md="6">
        <FloatingLabelInput label="City" name="homeAddress.city" />
      </Col>

      <Col md="6">
        <StateDropDown name="homeAddress.state" isFloating />
      </Col>

      <Col md="6">
        <FloatingLabelInput className="mb-0" label="Zip Code" name="homeAddress.zipCode" showErrors />
      </Col>
    </Row>
  </div>

export default AddressInformation
