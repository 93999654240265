import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'reactstrap'
import GuestInput from '../GuestInput'

const GuestFields = ({ currentTotalGuests, guestValidations }) => {
  const [allowMore, setAllowMore] = useState(true)

  const { areGuestsRequired, guests: { adults, children, pets, maxPeople, useGuests } } = guestValidations

  const adultsLabel = useGuests ? 'Guests' : 'Adults'

  useEffect(() => {
    setAllowMore(currentTotalGuests < maxPeople)
  }, [currentTotalGuests])

  return (
    <Row className="guest-fields g-0">
      <GuestInput age={adults.ageRange} category={adultsLabel} enabled={allowMore} isEmpty={areGuestsRequired}
                  max={adults.max} name="guests.adults" />

      {children && !useGuests && (
        <GuestInput age={children.ageRange} category="Children" enabled={allowMore} max={children.max}
                    name="guests.children" />)}

      {pets && <GuestInput category="Pets" enabled max={pets.max} name="guests.pets" />}
    </Row>
  )
}

GuestFields.propTypes = {
  currentTotalGuests: PropTypes.number.isRequired,
  guestValidations: PropTypes.shape({
    adults: PropTypes.shape({
      ageRange: PropTypes.string,
      max: PropTypes.number,
    }),

    children: PropTypes.shape({
      ageRange: PropTypes.string,
      max: PropTypes.number,
    }),

    pets: PropTypes.shape({
      max: PropTypes.number,
    }),

    maxPeople: PropTypes.number,
  }).isRequired,
}

export default GuestFields
